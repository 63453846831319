define("cropster-app/transforms/enum-session-state", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;
  var allowedValues = ['DRAFT', 'OPEN', 'CLOSED'];

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized === 'CREATED') {
        serialized = 'DRAFT';
      }

      return allowedValues.includes(serialized) ? serialized : allowedValues[0];
    },
    serialize: function serialize(deserialized) {
      if (!allowedValues.includes(deserialized)) {
        return 'CREATED';
      }

      if (deserialized === 'DRAFT') {
        deserialized = 'CREATED';
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});