define("cropster-app/services/keen", ["exports", "ember-keen/services/keen", "cropster-app/config/environment"], function (_exports, _keen, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _keen.default.extend({
    userSession: Ember.inject.service(),
    platform: Ember.inject.service('device/platform'),
    raven: Ember.inject.service(),

    /**
     * The project ID is taken from KEEN_PROJECT_ID in your config/environment.js
     *
     * @property projectId
     * @type {String}
     * @private
     * @readOnly
     */
    projectId: Ember.computed(function () {
      return Ember.get(_environment.default, 'KEEN_PROJECT_ID');
    }),

    /**
     * The write key is taken from KEEN_WRITE_KEY in your config/environment.js
     *
     * @property writeKey
     * @type {String}
     * @private
     * @readOnly
     */
    writeKey: Ember.computed(function () {
      /* istanbul ignore next */
      return Ember.get(_environment.default, 'KEEN_WRITE_KEY');
    }),

    /**
     * The read key is taken from KEEN_READ_KEY in your config/environment.js
     *
     * @property readKey
     * @type {String}
     * @private
     * @readOnly
     */
    readKey: Ember.computed(function () {
      /* istanbul ignore next */
      return Ember.get(_environment.default, 'KEEN_READ_KEY');
    }),
    mergeData: Ember.computed('userSession.{currentUser,activeGroup.code}', 'platform.screenSize', function () {
      var version = Ember.get(_environment.default, 'APP.version');

      if (version.indexOf('+') > -1) {
        version = version.substr(0, version.indexOf('+'));
      }

      var versionParts = version.split('.');
      var versionNumber = versionParts.map(function (part) {
        return part.length === 2 ? part : "0".concat(part);
      }).join('');
      var screenCategory = 'unkown';
      var width = Ember.get(this, 'platform.screenSize.width');
      var height = Ember.get(this, 'platform.screenSize.height');
      var biggerDimension = Math.max(width, height);
      var smallerDimenstion = Math.min(width, height);

      if (biggerDimension > 1920) {
        screenCategory = 'xlarge';
      } else if (biggerDimension >= 1300) {
        screenCategory = 'large';
      } else if (biggerDimension >= 800) {
        screenCategory = 'medium';
      } else if (biggerDimension >= 380) {
        screenCategory = 'small';
      } else if (biggerDimension > 0) {
        screenCategory = 'xsmall';
      }

      var calculateRatio = function calculateRatio(width, height) {
        var w = width / height;
        var h = 1;
        var ratio = w;

        while (w % 1 || h % 1) {
          h++;
          w = h * ratio;
        }

        return [w, h];
      };

      var ratio = calculateRatio(biggerDimension, smallerDimenstion);
      /* eslint-disable camelcase */

      return {
        current_user: {
          user_id: null,
          // we don't know the ID
          user_name: Ember.get(this, 'userSession.username'),
          evaluator_name: Ember.get(this, 'userSession.name'),
          timezone: Ember.get(this, 'userSession.timezone'),
          locale: Ember.get(this, 'userSession.locale'),
          group_code: Ember.get(this, 'userSession.activeGroup.code'),
          ip_address: '${keen.ip}',
          user_agent: '${keen.user_agent}'
        },
        device: {
          device_model: Ember.get(this, 'platform.deviceModel'),
          device_uuid: Ember.get(this, 'platform.deviceUuid'),
          screen: {
            width: width,
            height: height,
            bigger_dimension: biggerDimension,
            smaller_dimension: smallerDimenstion,
            is_landscape: width > height,
            category: screenCategory,
            ratio: "".concat(ratio[0], ":").concat(ratio[1])
          }
        },
        general: {
          page_url: window.location.href,
          version: version,
          version_number: versionNumber * 1
        },
        keen: {
          addons: [{
            name: 'keen:ua_parser',
            input: {
              ua_string: 'current_user.user_agent'
            },
            output: 'current_user.parsed_user_agent'
          }, {
            name: 'keen:ip_to_geo',
            input: {
              ip: 'current_user.ip_address'
            },
            output: 'current_user.ip_geo_info'
          }, {
            name: 'keen:url_parser',
            input: {
              url: 'general.page_url'
            },
            output: 'general.parsed_page_url'
          }, {
            name: 'keen:date_time_parser',
            input: {
              date_time: 'keen.timestamp'
            },
            output: 'general.timestamp_info'
          }]
        }
      };
      /* eslint-enable camelcase */
    }),
    _post: function _post() {
      var raven = Ember.get(this, 'raven');

      var promise = this._super.apply(this, arguments);

      promise.catch(function (reason) {
        raven.captureException(new Error('Could not post to keen'), {
          extra: {
            error: reason
          }
        });
      });
      return promise;
    }
  });

  _exports.default = _default;
});