define("cropster-app/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qs6ORlQ6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-left\"]],{\"statements\":[[4,\"link-to\",[\"dashboard\"],[[\"class\",\"data-test-link-back-to-dashboard\"],[\"button button--plain button--icon\",true]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",[\"arrow-right-thin\"],[[\"class\"],[\"icon icon--white transform-rotate-180\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Dashboard\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-center\"]],{\"statements\":[[0,\"  \"],[1,[23,[\"model\",\"session\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-right\"]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"session-review\",null,[[\"session\",\"analysisResults\",\"cuppingResults\",\"descriptors\",\"class\"],[[23,[\"model\",\"session\"]],[23,[\"model\",\"analysisResults\"]],[23,[\"model\",\"cuppingResults\"]],[23,[\"model\",\"descriptors\"]],\"margin-top-small\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/review/template.hbs"
    }
  });

  _exports.default = _default;
});