define("cropster-app/components/offline-ui/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iC7jY8OI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"offline-ui__content\"],[11,\"data-test-offline-message\",\"\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isOnline\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"You are online\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"You are offline\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/offline-ui/template.hbs"
    }
  });

  _exports.default = _default;
});