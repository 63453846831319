define("cropster-app/services/indexed-db", ["exports", "ember-indexeddb/services/indexed-db"], function (_exports, _indexedDb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _indexedDb.default.extend({
    databaseName: 'cropster-cup-db',

    /**
     * Get the available storage quota.
     * All values are in bytes - to get the MB value, do e.g. quota / (1024 * 1024)
     *
     * @method getStorageQuota
     * @return {{quota: <Number>, usage: <Number>, available: <Number> }}
     * @public
     */
    getStorageQuota: function getStorageQuota() {
      return new Ember.RSVP.Promise(function (resolve) {
        if (window.navigator.storage && window.navigator.storage.estimate) {
          window.navigator.storage.estimate().then(function (_ref) {
            var quota = _ref.quota,
                usage = _ref.usage;
            var available = quota - usage;
            resolve({
              quota: quota,
              usage: usage,
              available: available
            });
          }).catch(function () {
            return resolve({
              quota: null,
              usage: null,
              available: null
            });
          });
        } else {
          resolve({
            quota: null,
            usage: null,
            available: null
          });
        }
      });
    }
  });

  _exports.default = _default;
});