define("cropster-app/services/device/platform", ["exports", "ember-cordova-platform/services/ember-cordova/platform", "cropster-app/utils/guid"], function (_exports, _platform, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember-cordova PlatformService implementation.
   *
   * Gives us information about the device we're running on
   *
   * see https://github.com/isleofcode/ember-cordova/blob/master/addon/services/device/platform.js
   *
   * @class Service.Device.PlatformService
   */
  var _default = _platform.default.extend({
    screen: Ember.inject.service(),
    writePlatformClasses: function writePlatformClasses() {
      var classNames = Ember.get(this, 'platforms').map(function (platform) {
        return "platform-".concat(platform);
      });
      classNames.forEach(function (className) {
        document.querySelector('html').classList.add(className);
      });
    },
    isAndroid: Ember.computed('platforms.[]', function () {
      return Ember.get(this, 'platforms').includes('android');
    }),
    isIos: Ember.computed('platforms.[]', function () {
      return Ember.get(this, 'platforms').includes('ios');
    }),
    isOther: Ember.computed('isIos', 'isAndroid', function () {
      return !Ember.get(this, 'isIos') && !Ember.get(this, 'isAndroid');
    }),
    deviceModel: Ember.computed(function () {
      return Ember.get(this, 'device.model') || null;
    }),
    deviceUuid: Ember.computed(function () {
      // First, try to take the device UUID from cordova
      var uuid = Ember.get(this, 'device.uuid');

      if (uuid) {
        return uuid;
      } // Else, try to use an existing uuid from LocalStorage


      try {
        uuid = window.localStorage.getItem('device-uuid');
      } catch (e) {// do nothing
      }

      if (uuid) {
        return uuid;
      } // Else, generate one and save it to LocalStorage


      uuid = "web-".concat((0, _guid.default)());

      try {
        window.localStorage.setItem('device-uuid', uuid);
      } catch (e) {
        /* eslint-disable no-console */
        console.warn('Could not save device uuid', e);
        /* eslint-enable no-console */
      }

      return uuid;
    }),
    screenSize: Ember.computed('height', 'width', function () {
      return {
        width: Ember.get(this, 'width'),
        height: Ember.get(this, 'height')
      };
    }),
    width: Ember.computed.alias('screen.width'),
    height: Ember.computed.alias('screen.height')
  });

  _exports.default = _default;
});