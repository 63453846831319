define("cropster-app/components/sessions-list/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A list of sessions to select from.
   *
   * @namespace Component
   * @class SessionsList
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    l10n: Ember.inject.service(),

    /**
     * The sessions to display.
     *
     * @attribute sessions
     * @type {Model.CuppingSession[]}
     * @public
     */
    sessions: null,

    /**
     * If the grouping by date should be disabled.
     *
     * @attribute disableDateGrouping
     * @type {Boolean}
     * @default false
     * @public
     */
    disableDateGrouping: false,

    /**
     * The sessions, sorted by their date.
     *
     * @property sortedSessions
     * @type {Model.CuppingSession[]}
     * @readOnly
     * @protected
     */
    _sessionsSort: ['dateValue:asc'],
    // eslint-disable-line
    sortedSessions: Ember.computed.sort('sessions', '_sessionsSort'),

    /**
     * The sessions, grouped by their date.
     * Each group has a `title` and a `contents` field, where `conents` is an array of sessions.
     *
     * @property groupedSessions
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    groupedSessions: Ember.computed('sortedSessions.@each.date', 'disableDateGrouping', function () {
      // If date grouping is disabled, just return one dummy group with all sessions
      if (this.disableDateGrouping) {
        return [{
          contents: this.sessions
        }];
      }

      var sessions = Ember.get(this, 'sortedSessions') || [];
      var l10n = Ember.get(this, 'l10n');
      var today = (0, _moment.default)().startOf('day'); // today at 00:00 in user's timezone

      var tomorrow = (0, _moment.default)().startOf('day').add(1, 'days'); // a list of objects of the format
      // { title: human readable date representation
      //   contents: [] array of session models on this date
      // }

      var groupedSessions = []; // group by date

      sessions.forEach(function (session) {
        var day = (0, _moment.default)(Ember.get(session, 'date')).startOf('day'); // Today and tomorrow get special labels

        if (today.isSame(day)) {
          day = l10n.t('Today').toString();
        } else if (tomorrow.isSame(day)) {
          day = l10n.t('Tomorrow').toString();
        } else {
          // otherwise format the date
          day = day.format('dddd, MMMM Do YYYY');
        }

        var existingDay = groupedSessions.findBy('title', day);

        if (!existingDay) {
          existingDay = Ember.Object.create({
            title: day,
            contents: []
          });
          groupedSessions.pushObject(existingDay);
        }

        existingDay.get('contents').pushObject(session);
      });
      return groupedSessions;
    })
  });

  _exports.default = _default;
});