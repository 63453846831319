define("cropster-app/descriptor/serializer", ["exports", "cropster-app/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    normalizeCSAR: function normalizeCSAR(model, hash, prop) {
      hash = this._super(model, hash, prop);
      hash.descriptorId = "".concat(hash.id); // Rename isDirty to dirty for serialization

      hash.isDirty = hash.dirty;
      delete hash.dirty; // The ones from the API are never local

      hash.isLocal = false;
      return this._normalizeBasic(model, hash);
    }
  });

  _exports.default = _default;
});