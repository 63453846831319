define("cropster-app/components/descriptors-list/descriptor-item/options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The options for a descriptor item.
   *
   * @namespace Component.DescriptorsList.DescriptorItem
   * @class Options
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    classNames: ['descriptor-options'],
    classNameBindings: ['isAtRightSide:at-right-side', 'isAtRightScreenEdge:at-right-screen-edge'],

    /**
     * The assigned sensorial descriptor to edit.
     *
     * @attribute assignedSensorialDescriptor
     * @type {Model.AssignedSensorialDescriptor}
     * @public
     */
    assignedSensorialDescriptor: null,

    /**
     * If this should be aligned at the right side instead of centered.
     *
     * @attribute isAtRightSide
     * @type {Boolean}
     * @default false
     * @public
     */
    isAtRightSide: false,

    /**
     * The action to call to update the intensity of the descriptor.
     *
     * @event updateIntensity
     * @param {Number} Value
     * @public
     */
    updateIntensity: null,

    /**
     * The action to call to update the positivity of the descriptor.
     *
     * @event updatePositive
     * @param {Boolean} isPositive
     * @public
     */
    updatePositive: null,

    /**
     * If the descriptor is negative.
     *
     * @property isNegative
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isNegative: Ember.computed.not('assignedSensorialDescriptor.positive'),
    actions: {
      updateIsNegative: function updateIsNegative(isNegative) {
        var action = Ember.get(this, 'updatePositive');
        return action(!isNegative);
      }
    }
  });

  _exports.default = _default;
});