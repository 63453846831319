define("cropster-app/components/evaluation-form/inputs/base-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The base input that all other inputs extend on.
   * Note that the "special" inputs don't necessarily extend from that, they may have custom functionality.
   *
   * @namespace Component.EvaluationForm.Inputs
   * @class BaseInput
   * @abstract
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    classNames: ['evaluation-input'],

    /**
     * The cupping result item to display.
     * Only use this if no other possible attribute can be used.
     *
     * @attribute cuppingResultItem
     * @type {Model.QualityCuppingItem}
     * @public
     */
    cuppingResultItem: null,

    /**
     * The title to display.
     *
     * @attribute title
     * @type {String}
     * @public
     */
    title: null,

    /**
     * An optional class to add to the title.
     *
     * @attribute titleClass
     * @type {String}
     * @public
     * @optional
     */
    titleClass: null,

    /**
     * The actual current value. Might be null.
     *
     * @attribute value
     * @type {Number}
     * @public
     */
    value: null,

    /**
     * The default value to use, if no other value is set.
     *
     * @attribute defaultValue
     * @type {Number}
     * @public
     */
    defaultValue: null,

    /**
     * The range that can be selected from.
     * This is an array of options.
     *
     * @attribute range
     * @type {Number[]}
     * @public
     */
    range: null,

    /**
     * The step of values that can be selected, e.g. 0.25.
     *
     * @attribute step
     * @type {Number}
     * @public
     * @default 1
     */
    step: 1,

    /**
     * The action to call to update the value.
     *
     * @event update
     * @param {Number} value
     * @public
     */
    update: null,

    /**
     * The min. possible value.
     *
     * @property min
     * @type {Number}
     * @readOnly
     * @protected
     */
    min: Ember.computed.alias('range.firstObject'),

    /**
     * The max. possible value.
     *
     * @property max
     * @type {Number}
     * @readOnly
     * @protected
     */
    max: Ember.computed.alias('range.lastObject')
  });

  _exports.default = _default;
});