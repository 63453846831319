define("cropster-app/components/evaluation-form/inputs/popup-slider-small/component", ["exports", "cropster-app/components/evaluation-form/inputs/base-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An input which shows the selected value, and pops up a slider when clicked on.
   *
   * @namespace Component.EvaluationForm.Inputs
   * @class PopupSliderSmall
   * @extends Component.EvaluationForm.Inputs.BaseInput
   * @public
   */
  var _default = _component.default.extend({
    /**
     * If the value has changed from the default value.
     *
     * @property hasChanged
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasChanged: Ember.computed('value', 'defaultValue', function () {
      return Ember.get(this, 'value') !== Ember.get(this, 'defaultValue');
    })
  });

  _exports.default = _default;
});