define("cropster-app/services/keen-fetch", ["exports", "ember-keen/utils/merge-deep", "fetch"], function (_exports, _mergeDeep, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * Primitive method for performing ajax POST requests.
     *
     * @method makeRequest
     * @param {String} url The URL to send POST to.
     * @param {Object} [data={}] Custom request data.
     * @param {String} apiKey The API key to use for authentication
     * @param {Object} [extraOptions={}] Custom request options.
     * @returns {Ember.RSVP.Promise}
     * @private
     */
    makeRequest: function makeRequest(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var apiKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var extraOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      var options = this._getFetchOptions(url, data, apiKey, extraOptions);

      var fullUrl = this._getFetchUrl(url, data, apiKey, extraOptions);

      return (0, _fetch.default)(fullUrl, options).then(function (response) {
        return response.json();
      });
    },
    _getFetchOptions: function _getFetchOptions(url, data, apiKey) {
      var extraOptions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      // Note: The Authentication header does not work with CORS, as keen.io sends a wildcard Accept-Origin header
      // This is not allowed by fetch, so we need to add the api key to the URL (in _getFetchUrl)
      return (0, _mergeDeep.default)({
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }, extraOptions);
    },
    _getFetchUrl: function _getFetchUrl(url, data, apiKey) {
      return "".concat(url, "?api_key=").concat(apiKey);
    }
  });

  _exports.default = _default;
});