define("cropster-app/quality-cupping-item/serializer", ["exports", "cropster-app/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    attrs: {
      assignedSensorialDescriptors: {
        serialize: true
      }
    },
    deserializeFromCuppingResult: function deserializeFromCuppingResult(cuppingResultId, itemPayload) {
      var data = {
        type: 'quality-cupping-item',
        id: "".concat(itemPayload.id)
      };
      var quality = itemPayload.quality,
          intensity = itemPayload.intensity,
          calculatedQuality = itemPayload.calculatedQuality,
          comment = itemPayload.comment;
      data.attributes = {
        quality: quality,
        intensity: intensity,
        calculatedQuality: calculatedQuality,
        comment: comment
      };
      var assignedSensorialDescriptors = [];

      if (itemPayload.assignedSensorialDescriptors) {
        itemPayload.assignedSensorialDescriptors.forEach(function (assdescPayload) {
          var assDescData = {
            type: 'assigned-sensorial-descriptor',
            id: assdescPayload.id,
            attributes: {
              intensity: assdescPayload.intensity || 1,
              positive: assdescPayload.positive || true
            },
            relationships: {
              sensorialDescriptor: {
                data: {
                  type: 'descriptor',
                  id: assdescPayload.sensorialDescriptor
                }
              },
              qualityCuppingItem: {
                data: {
                  type: 'quality-cupping-item',
                  id: itemPayload.id
                }
              }
            }
          };
          assignedSensorialDescriptors.push(assDescData);
        });
      }

      data.relationships = {
        qualityCupping: {
          data: {
            type: 'cupping-result',
            id: cuppingResultId
          }
        },
        cuppingSheetItem: {
          data: {
            type: 'cupping-sheet-item',
            id: itemPayload.cuppingSkinItem.id
          }
        },
        assignedSensorialDescriptors: {
          data: assignedSensorialDescriptors.map(function (ad) {
            return {
              type: ad.type,
              id: ad.id
            };
          })
        }
      };
      return {
        assignedSensorialDescriptors: assignedSensorialDescriptors,
        cuppingItemData: data
      };
    }
  });

  _exports.default = _default;
});