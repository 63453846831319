define("cropster-app/review/route", ["exports", "cropster-app/mixins/reset-scroll", "ember-data"], function (_exports, _resetScroll, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;

  var _default = Ember.Route.extend(_resetScroll.default, {
    userSession: Ember.inject.service(),
    store: Ember.inject.service(),
    keenTracking: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(_this, function () {
          return resolve();
        }, 100);
      });
    },
    model: function model(params) {
      var _this2 = this;

      var model = {};
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('store').findRecord('cupping-session', params.id).then(function (session) {
          model.session = session;
          return session.get('analysisResults');
        }).then(function (analysisResults) {
          model.analysisResults = analysisResults;
          resolve(model);
        }).catch(function () {
          reject(new Error('route/review/model: LS query failed'));
        });
      });
    },
    afterModel: function afterModel(hash) {
      var keen = Ember.get(this, 'keenTracking');
      var store = Ember.get(this, 'store');
      var cupper = Ember.get(this, 'userSession.name');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var cuppingPromises = hash.analysisResults.map(function (analysisResult) {
          return store.queryRecord('cupping-result', {
            analysisResult: analysisResult.get('id'),
            cupper: cupper
          });
        });
        var promise = PromiseArray.create({
          promise: Ember.RSVP.all(cuppingPromises)
        });
        Ember.set(hash, 'cuppingResults', promise);
        hash.cuppingResults.then(function () {
          Ember.set(hash, 'cuppingResults', hash.cuppingResults.compact());
          keen.trackReviewSession(hash.session);
          resolve(hash);
        }, reject);
      });
    }
  });

  _exports.default = _default;
});