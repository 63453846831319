define("cropster-app/initializers/cupping-session-loader", ["exports", "cropster-app/objects/cupping-session-loader"], function (_exports, _cuppingSessionLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('object:cupping-session-loader', _cuppingSessionLoader.default, {
      singleton: false
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});