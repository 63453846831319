define("cropster-app/group-membership/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A group membership describes a user & its permissions for a group.
   * It is basically a proxy object between user & group.
   *
   * @namespace Model
   * @class GroupMembership
   * @extends DS.Model
   * @public
   */
  var _default = _model.default.extend({
    /**
     * The date when the user was added to the group.
     *
     * @attribute createdDate
     * @type {Date}
     * @public
     */
    createdDate: (0, _attr.default)('timestamp'),

    /**
     * The group this membership belongs to.
     *
     * @attribute group
     * @type {Model.Group}
     * @public
     */
    group: (0, _relationships.belongsTo)('group'),

    /**
     * The user this membership describes.
     *
     * @attribute user
     * @type {Model.User}
     * @public
     */
    user: (0, _relationships.belongsTo)('user'),

    /**
     * The permissions for this membership.
     *
     * @attribute permissions
     * @type {Model.Permission[]}
     * @public
     */
    permissions: (0, _relationships.hasMany)('permissions')
  });

  _exports.default = _default;
});