define("cropster-app/components/evaluation-form/inputs/special/defects/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is a special input type, which can only be used if quality & intensity are set.
   * It assumes that the item has a formula that multiplies Q & I.
   *
   * @namespace Component.EvaluationForm.Inputs.Special
   * @class Defects
   * @extends Ember.Component
   * @Public
   */
  var _default = Ember.Component.extend({
    classNames: ['evaluation-input'],

    /**
     * The title to display.
     *
     * @attribute title
     * @type {String}
     * @public
     */
    title: null,

    /**
     * An optional class to add to the title.
     *
     * @attribute titleClass
     * @type {String}
     * @public
     * @optional
     */
    titleClass: null,

    /**
     * The cupping result item to enter data for.
     *
     * @attribute cuppingResultItem
     * @type {Model.QualityCuppingItem}
     * @public
     */
    cuppingResultItem: null,

    /**
     * The cupping sheet item.
     *
     * @attribute cuppingSheetItem
     * @type {Model.CuppingSheetItem}
     * @public
     */
    cuppingSheetItem: null,

    /**
     * The action to call to update the quality part.
     *
     * @event updateQuality
     * @param {Number} value
     * @public
     */
    updateQuality: null,

    /**
     * The action to call to update the intensity part.
     *
     * @event updateIntensity
     * @param {Number} value
     * @public
     */
    updateIntensity: null,

    /**
     * The calculated quality to display.
     *
     * @property calculatedQuality
     * @type {Number}
     * @readOnly
     * @protected
     */
    calculatedQuality: Ember.computed.alias('cuppingResultItem.calculatedQuality'),

    /**
     * The current quality value.
     *
     * @property quality
     * @type {Number}
     * @readOnly
     * @protected
     */
    quality: Ember.computed.alias('cuppingResultItem.quality'),

    /**
     * The current intensity value.
     *
     * @property intensity
     * @type {Number}
     * @readOnly
     * @protected
     */
    intensity: Ember.computed.alias('cuppingResultItem.intensity')
  });

  _exports.default = _default;
});