define("cropster-app/cupping-sheet-item/serializer", ["exports", "cropster-app/application/serializer", "cropster-app/utils/calculate-step"], function (_exports, _serializer, _calculateStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    deserializeFromSheet: function deserializeFromSheet(sheetId, itemPayload) {
      var modelClass = this.store.modelFor('cupping-sheet-item'); // Map the quality/intensity range to min, max and step

      var qualityRange = Ember.get(itemPayload, 'qualityRange');

      if (qualityRange) {
        var qualityMin = Ember.get(qualityRange, 'firstObject');
        var qualityMax = Ember.get(qualityRange, 'lastObject');
        var qualityStep = (0, _calculateStep.default)(qualityRange);
        Ember.setProperties(itemPayload, {
          qualityMin: qualityMin,
          qualityMax: qualityMax,
          qualityStep: qualityStep
        });
        delete itemPayload.qualityRange;
      }

      var intensityRange = Ember.get(itemPayload, 'intensityRange');

      if (intensityRange) {
        var intensityMin = Ember.get(intensityRange, 'firstObject');
        var intensityMax = Ember.get(intensityRange, 'lastObject');
        var intensityStep = (0, _calculateStep.default)(intensityRange);
        Ember.setProperties(itemPayload, {
          intensityMin: intensityMin,
          intensityMax: intensityMax,
          intensityStep: intensityStep
        });
        delete itemPayload.intensityRange;
      }

      var hash = this._normalizeBasic(modelClass, itemPayload);

      hash.relationships = {};
      hash.relationships.cuppingSheet = {
        data: {
          type: 'cupping-sheet',
          id: sheetId
        }
      };
      return hash;
    }
  });

  _exports.default = _default;
});