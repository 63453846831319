define("cropster-app/services/screen", ["exports", "ember-screen"], function (_exports, _emberScreen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberScreen.default.extend({
    // ---------------------------------------------------------------------------------------------------------
    // Breakpoints
    isTabletOrUp: (0, _emberScreen.breakpoint)('(min-width: 1024px)'),
    isSmall: (0, _emberScreen.breakpoint)('(max-width: 700px)'),
    isXSmall: (0, _emberScreen.breakpoint)('(max-width: 375px)'),
    // ---------------------------------------------------------------------------------------------------------
    // Properties
    rootElement: Ember.computed(function () {
      return document.querySelector('.scroll-container');
    })
  });

  _exports.default = _default;
});