define("cropster-app/services/indexed-db-configuration", ["exports", "cropster-app/utils/calculate-step", "ember-indexeddb/services/indexed-db-configuration"], function (_exports, _calculateStep, _indexedDbConfiguration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _indexedDbConfiguration.default.extend({
    currentVersion: 6,
    version1: {
      stores: {
        'cupping-result': '&id,json,*cuppingSession,*cupper,*analysisResult,[cupper+analysisResult]',
        descriptor: '&id,json,*groupCode,*name',
        'quality-cupping-item': '&id,json,*cuppingSheetItem,*qualityCupping,[cuppingSheetItem+qualityCupping]',
        'assigned-sensorial-descriptor': '&id,json,*sensorialDescriptor,*qualityCuppingItem,[sensorialDescriptor+qualityCuppingItem]',
        group: '&id,json',
        'analysis-result': '&id,json,*session,*cuppingDate',
        'cupping-session': '&id,json,*groupCode,*sessionState,*date,*isSyncing,*isSynced,*isCupperSubmitted,*isStarted,[groupCode+sessionState],[isSyncing+isSynced+isCupperSubmitted]',
        'cupping-sheet': '&id,json,*groupCode',
        'cupping-sheet-item': '&id,json,*cuppingSheet'
      }
    },
    version2: {
      stores: {
        log: '++id,message,*timestamp,*groupCode,*cupper,json'
      }
    },
    version3: {
      stores: {
        'cupping-result': '&id,json,*cuppingSession,*cupper,*analysisResult,[cupper+analysisResult],[cuppingSession+cupper]'
      }
    },
    version4: {
      stores: {
        descriptor: '&id,json,*groupCode,*name,*normalizedName,[groupCode+normalizedName],*isDirty,*isLocal,[groupCode+isDirty],[groupCode+isLocal]'
      },
      upgrade: function upgrade(transaction) {
        transaction.descriptor.each(function (descriptor, cursor) {
          descriptor.isLocal = 1;
          descriptor.isDirty = 1;
          descriptor.normalizedName = descriptor.name.toLowerCase().trim();
          cursor.update(descriptor);
        });
      }
    },
    version5: {
      stores: {
        log: '++id,title,message,*timestamp,*groupCode,*cupper,*type,[type+cupper],[title+cupper],json'
      }
    },
    version6: {
      upgrade: function upgrade(transaction) {
        transaction['cupping-sheet-item'].each(function (cuppingSheetItem, cursor) {
          var json = cuppingSheetItem.json;
          var itemPayload = json.attributes; // Map the quality/intensity range to min, max and step

          var qualityRange = Ember.get(itemPayload, 'qualityRange');

          if (qualityRange) {
            var qualityMin = Ember.get(qualityRange, 'firstObject');
            var qualityMax = Ember.get(qualityRange, 'lastObject');
            var qualityStep = (0, _calculateStep.default)(qualityRange);
            Ember.setProperties(itemPayload, {
              qualityMin: qualityMin,
              qualityMax: qualityMax,
              qualityStep: qualityStep
            });
            delete itemPayload.qualityRange;
          }

          var intensityRange = Ember.get(itemPayload, 'intensityRange');

          if (intensityRange) {
            var intensityMin = Ember.get(intensityRange, 'firstObject');
            var intensityMax = Ember.get(intensityRange, 'lastObject');
            var intensityStep = (0, _calculateStep.default)(intensityRange);
            Ember.setProperties(itemPayload, {
              intensityMin: intensityMin,
              intensityMax: intensityMax,
              intensityStep: intensityStep
            });
            delete itemPayload.intensityRange;
          }

          cursor.update(cuppingSheetItem);
        });
      }
    },
    mapTable: Ember.computed(function () {
      var _this = this;

      return {
        'cupping-result': function cuppingResult(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item),
            cuppingSession: _this._toString(Ember.get(item, 'relationships.cuppingSession.data.id')),
            cupper: Ember.get(item, 'attributes.cupper'),
            analysisResult: _this._toString(Ember.get(item, 'relationships.analysisResult.data.id'))
          };
        },
        descriptor: function descriptor(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item),
            groupCode: Ember.get(item, 'attributes.groupCode'),
            name: Ember.get(item, 'attributes.name'),
            isLocal: _this._toZeroOne(Ember.get(item, 'attributes.isLocal')),
            isDirty: _this._toZeroOne(Ember.get(item, 'attributes.isDirty')),
            normalizedName: Ember.get(item, 'attributes.name').toLowerCase().trim()
          };
        },
        'quality-cupping-item': function qualityCuppingItem(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item),
            cuppingSheetItem: _this._toString(Ember.get(item, 'relationships.cuppingSheetItem.data.id')),
            qualityCupping: _this._toString(Ember.get(item, 'relationships.qualityCupping.data.id'))
          };
        },
        'assigned-sensorial-descriptor': function assignedSensorialDescriptor(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item),
            sensorialDescriptor: _this._toString(Ember.get(item, 'relationships.sensorialDescriptor.data.id')),
            qualityCuppingItem: _this._toString(Ember.get(item, 'relationships.qualityCuppingItem.data.id'))
          };
        },
        group: function group(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item)
          };
        },
        'analysis-result': function analysisResult(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item),
            session: _this._toString(Ember.get(item, 'relationships.cupping-session.data.id')),
            cuppingDate: Ember.get(item, 'attributes.cuppingDate')
          };
        },
        'cupping-session': function cuppingSession(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item),
            groupCode: Ember.get(item, 'attributes.groupCode'),
            sessionState: Ember.get(item, 'attributes.sessionState'),
            date: Ember.get(item, 'attributes.date'),
            isSyncing: _this._toZeroOne(Ember.get(item, 'attributes.isSyncing')),
            isSynced: _this._toZeroOne(Ember.get(item, 'attributes.isSynced')),
            isCupperSubmitted: _this._toZeroOne(Ember.get(item, 'attributes.isCupperSubmitted')),
            isStarted: _this._toZeroOne(Ember.get(item, 'attributes.isStarted'))
          };
        },
        'cupping-sheet': function cuppingSheet(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item),
            groupCode: Ember.get(item, 'attributes.groupCode')
          };
        },
        'cupping-sheet-item': function cuppingSheetItem(item) {
          return {
            id: _this._toString(Ember.get(item, 'id')),
            json: _this._cleanObject(item),
            cuppingSheet: _this._toString(Ember.get(item, 'relationships.cuppingSheet.data.id'))
          };
        }
      };
    })
  });

  _exports.default = _default;
});