define("cropster-app/components/evaluation-form/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ijS598US",
    "block": "{\"symbols\":[\"@gotoPage\",\"@submitSession\"],\"statements\":[[7,\"div\"],[11,\"class\",\"footer-pagination\"],[9],[0,\"\\n  \"],[1,[27,\"evaluation-form/sample-pagination\",null,[[\"paginatedItems\",\"currentPage\",\"gotoPage\",\"isCompact\"],[[23,[\"paginatedItems\"]],[23,[\"currentPage\"]],[22,1,[]],[27,\"not\",[[23,[\"session\",\"isBlind\"]]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"button button--grey footer-submit-button\"],[11,\"data-test-footer-submit-session\",\"\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Submit\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[22,2,[]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/footer/template.hbs"
    }
  });

  _exports.default = _default;
});