define("cropster-app/evaluation/index/route", ["exports", "cropster-app/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    beforeModel: function beforeModel() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(_this, function () {
          return resolve();
        }, 100);
      });
    },
    actions: {
      reviewSession: function reviewSession() {
        var _this2 = this;

        var controller = this.controllerFor('application');
        controller.showLoadingSpinner();
        Ember.run.later(this, function () {
          controller.hideLoadingSpinner();

          _this2.transitionTo('evaluation.overview');
        }, 1000);
      }
    }
  });

  _exports.default = _default;
});