define("cropster-app/cupping-session/model", ["exports", "ember-data", "moment", "ember-indexeddb/mixins/model-bulk-save"], function (_exports, _emberData, _moment, _modelBulkSave) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  /**
   * A sensorial cupping session.
   *
   * @class Model.Session
   * @extends DS.Model
   */

  var _default = Model.extend(_modelBulkSave.default, {
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * The group code this session belongs to
     * @attribute groupCode
     * @type {String}
     */
    groupCode: attr('string'),

    /**
     * The name of the session.
     *
     * @attribute name
     * @type {String}
     */
    name: attr('string'),

    /**
     * The state of the session. One of 'CREATED', 'OPEN', 'CLOSED'
     *
     * @attribute sessionState
     * @type {String}
     */
    sessionState: attr('string'),

    /**
     * DateTime when the session is scheduled.
     *
     * @attribute date
     * @type {Date}
     */
    date: attr('timestamp'),

    /**
     * A more readable ID of the session, e.g. "QS-123".
     *
     * @attribute idTag
     * @type {String}
     */
    idTag: attr('string'),

    /**
     * If the session is blind.
     * If not, show the Lot's ID-Tags instead of the Sample Codes.
     *
     * @attribute isBlind
     * @type {Boolean}
     * @default true
     * @public
     */
    isBlind: attr('boolean', {
      defaultValue: true
    }),

    /**
     * Id of the cupping sheet which is used for this session.
     *
     * @attribute cuppingSkinId
     * @type {String|Number}
     */
    cuppingSkinId: attr('string'),

    /**
     * The cupping sheet which is used for this session.
     *
     * @attribute cuppingSheet
     * @type {Model.CuppingSheet}
     */
    cuppingSheet: belongsTo('cupping-sheet'),

    /**
     * List of AnalysisResults. For each sample there is one AnalysisResult.
     *
     * @attribute analysisResults
     * @type {Model.QualityAnalysis[]}
     */
    analysisResults: hasMany('analysis-result'),
    // ---------------------------------------------------------------------------------------------------------
    // OTHERS

    /**
     * isStarted: the cupper has opened the session and edited a value and "saved" it
     */
    isStarted: attr('boolean', {
      defaultValue: false
    }),

    /**
     * isCupperSubmitted: the cupper has saved & submitted the session on the app
     */
    isCupperSubmitted: attr('boolean', {
      defaultValue: false
    }),

    /**
     * isSyncing: the cupper has submitted, and the session is in the process of being synced
     */
    isSyncing: attr('boolean', {
      defaultValue: false
    }),

    /**
     * isSynced: the cupper has submitted, and the session was successfully saved to the server
     */
    isSynced: attr('boolean', {
      defaultValue: false
    }),

    /**
     * When the session was down synced the last time.
     *
     * @attribute lastSyncDate
     * @type {Date}
     * @public
     */
    lastSyncDate: attr('timestamp'),

    /**
     * When the session was first started
     * we base this on when the session is open, and the first value entered
     */
    sessionStartedTime: attr('timestamp'),

    /**
     * When the session is successfully synced.
     */
    sessionFinishedTime: attr('timestamp'),

    /**
     * When the session is submitted.
     */
    sessionSubmittedTime: attr('timestamp'),
    // ---------------------------------------------------------------------------------------------------------
    // Properties
    dateValue: Ember.computed('date', function () {
      var date = Ember.get(this, 'date');
      return date ? date.valueOf() : null;
    }),

    /**
     * Session duration
     *
     * @property sessionDuration
     * @see http://momentjs.com/docs/#/durations/
     * @type {Moment.Duration} moment duration object
     * @readOnly
     */
    sessionDuration: Ember.computed('isCupperSubmitted', 'sessionFinishedTime', 'sessionStartedTime', function () {
      if (!this.get('isCupperSubmitted')) {
        return _moment.default.duration();
      }

      var start = this.get('sessionStartedTime');
      var end = this.get('sessionFinishedTime');
      var diffMs = (0, _moment.default)(end).diff(start);

      var duration = _moment.default.duration(diffMs);

      return duration;
    }),

    /**
     * If the session's state is CREATED.
     *
     * @property isStateCreated
     * @type {Boolean}
     * @readOnly
     */
    isStateCreated: Ember.computed.equal('sessionState', 'CREATED'),

    /**
     * If the session's state is OPEN.
     *
     * @property isStateOpen
     * @type {Boolean}
     * @readOnly
     */
    isStateOpen: Ember.computed.equal('sessionState', 'OPEN'),

    /**
     * If the session's state is CLOSED.
     *
     * @property isStateClosed
     * @type {Boolean}
     * @readOnly
     */
    isStateClosed: Ember.computed.equal('sessionState', 'CLOSED'),
    needsSyncing: Ember.computed('isSynced', 'isCupperSubmitted', function () {
      return this.get('isCupperSubmitted') && !this.get('isSynced');
    }),

    /**
     * Compact sessions are ones that use a small concise cupping sheet
     * We render these differently
     */
    isCompact: Ember.computed('cuppingSheet', function () {
      var compact = this.get('cuppingSheet').get('sheetItems').get('length') <= 4;
      return compact;
    }),
    displayName: Ember.computed('name', 'idTag', function () {
      return Ember.isEmpty(this.get('name')) ? this.get('idTag') : this.get('name');
    }),

    /**
     * The total amount of cuppings over all analysis of this session.
     *
     * @property totalCuppingCount
     * @returns {Ember.RSVP.Promise} that resolves to the total cupping count
     * @readOnly
     */
    totalCuppingCount: Ember.computed('analysisResults.@each.cupperCount', function () {
      var analysisResults = this.get('analysisResults');
      var count = 0;
      analysisResults.forEach(function (analysis) {
        count += analysis.get('cupperCount');
      });
      return count;
    }),

    /**
     * Whether or not the session has submitted results.
     * @property hasCuppingResults
     * @type {Ember}
     * @readOnly
     */
    hasCuppingResults: Ember.computed.gt('totalCuppingCount', 0),

    /**
     * A CuppingSessionLoader instance for this session.
     * Can be used to load analysis results & cupping results from this session.
     * Make sure to call `setupTask.perform(session)` before using it the first time
     *
     * @property loader
     * @type {Object.CuppingSessionLoader}
     * @readOnly
     * @public
     */
    loader: Ember.computed(function () {
      return Ember.getOwner(this).lookup('object:cupping-session-loader');
    })
  });

  _exports.default = _default;
});