define("cropster-app/utils/formula-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parse = parse;
  _exports.calculateScore = calculateScore;
  _exports.default = void 0;

  /**
   * Parses and evaluates c-sar cupping formulas.
   *
   * @namespace Util
   * @class Formula
   * @public
   */
  var Formula = Ember.Object.extend({
    string: null,
    defaults: null,
    variables: null,
    allowedVars: null,
    expr: null,
    parsedString: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'defaults', {});
      Ember.set(this, 'variables', []);
      Ember.set(this, 'allowedVars', ['Q', 'I']);
      Ember.set(this, 'parsedString', this.string);
      this.parse();
    },
    parse: function parse() {
      var _this = this;

      var string = this.string;

      if (Ember.isEmpty(string)) {
        return null;
      }

      var allowedVars = this.allowedVars,
          variables = this.variables;
      allowedVars.forEach(function (variable) {
        if (_this.hasVariable(variable)) {
          _this.parseVariable(variable);

          variables.pushObject(variable);
        }
      });
      return this.parsedString;
    },
    addDefault: function addDefault(variable, defaultValue) {
      var defaults = this.defaults;
      Ember.set(defaults, variable, defaultValue);
    },
    hasVariable: function hasVariable(variable) {
      var string = this.string;
      return string.match(new RegExp("".concat(variable)));
    },
    parseVariable: function parseVariable(variable) {
      var _this2 = this;

      var re = new RegExp("[".concat(variable, "]\\[(.+?)\\]"), 'g');
      var parsedString = this.parsedString;
      parsedString.replace(re, function ($0, $1) {
        parsedString = parsedString.replace($0, variable);
        Ember.set(_this2, 'parsedString', parsedString);

        _this2.addDefault(variable, $1);
      });
    },
    evaluate: function evaluate() {
      var variables = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var defaults = this.defaults,
          allVariables = this.variables;
      allVariables.forEach(function (variable) {
        var defaultValue = Ember.get(defaults, variable);

        if (defaultValue !== undefined && Ember.isBlank(variables[variable])) {
          variables[variable] = defaultValue;
        }
      });
      this.validate(variables);
      var parsedString = this.parsedString;
      return this.replacePlaceholders(parsedString, variables);
    },
    validate: function validate(variables) {
      var allVariables = this.variables;
      allVariables.forEach(function (variable) {
        var value = variables[variable];

        if (Ember.isBlank(value) || isNaN(value)) {
          throw new Error("Missing variable ".concat(variable, " ").concat(variables[variable], " "));
        }
      });
    },
    replacePlaceholders: function replacePlaceholders(str, variables) {
      var originalStr = str; // First replace the variables

      Object.keys(variables).forEach(function (variable) {
        var regex = new RegExp(variable, 'g');
        str = str.replace(regex, variables[variable]);
      }); // Now validate it agains invalid symbols

      var invalidSymbols = /[^-()\d\s/*+.]/g;

      if (invalidSymbols.test(str)) {
        throw new Error("Invalid symbol found in formula ".concat(originalStr, " - only numbers, -, +, *, / and () are allowed"));
      } // Now actually evaluate the formula


      var value = eval(str);
      return value.toFixed(5) * 1;
    }
  });
  /*
   * Parse a string like 'Q*I[5]*-1) into a formula object.
   * @param formula string
   * @returns {Formula|null}
   */

  function parse(formula) {
    if (Ember.isBlank(formula)) {
      return null;
    }

    return Formula.create({
      string: formula
    });
  }
  /*
   * Given a formula and two Q and I values, evaluate the formula.
   * @param formulaStr
   * @param Q
   * @param I
   * @returns {Number|null}
   */


  function calculateScore(formula, Q, I) {
    if (Ember.isBlank(formula)) {
      return Q;
    }

    if (typeof formula === 'string') {
      formula = Formula.create({
        string: formula
      });
    }

    var result = formula.evaluate({
      Q: Q,
      I: I
    });

    if (result === Infinity || isNaN(result)) {
      return null;
    }

    return result;
  }

  var _default = {
    parse: parse,
    calculateScore: calculateScore,
    Formula: Formula
  };
  _exports.default = _default;
});