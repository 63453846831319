define("cropster-app/components/descriptors-list/descriptor-item/component", ["exports", "cropster-app/mixins/outside-click-listener"], function (_exports, _outsideClickListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var openDescriptorItems = [];
  /**
   * A single (assigned) sensorial descriptor.
   * If `allowActions` is not false, this also allows to edit the positivity/intensity.
   *
   * @namespace Component.DescriptorsList
   * @class DescriptorItem
   * @extends Ember.Component
   * @public
   */

  var _default = Ember.Component.extend(_outsideClickListener.default, {
    tagName: 'span',
    keenTracking: Ember.inject.service(),

    /**
     * The actual assigned sensorial descriptor to display.
     *
     * @attribute assignedSensorialDescriptor
     * @type {Model.AssignedSensorialDescriptor}
     * @public
     */
    assignedSensorialDescriptor: null,

    /**
     * If actions on the descriptor should be allowed.
     * If this is true, the user can remove the descriptor, and edit it.
     *
     * @attribute allowActions
     * @type {Boolean}
     * @default true
     * @public
     */
    allowActions: true,

    /**
     * The action to call when the descriptor has been changed.
     *
     * @event updateAssignedSensorialDescriptor
     * @param {Model.AssignedSensorialDescriptor} assignedSensorialDescriptor
     * @public
     */
    updateAssignedSensorialDescriptor: null,

    /**
     * The action to call when the descriptor is removed.
     *
     * @event removeAssignedSensorialDescriptor
     * @param {Model.AssignedSensorialDescriptor} assignedSensorialDescriptor
     * @public
     */
    removeAssignedSensorialDescriptor: null,

    /**
     * If the edit view is open for this descriptor.
     *
     * @property isOpen
     * @type {Boolean}
     * @default false
     * @protected
     */
    isOpen: false,

    /**
     * If the edit view should be right aligned instead of left aligned.
     * This is calculated in `_checkPosition`.
     *
     * @property isAtRightSide
     * @type {Boolean}
     * @default false
     * @protected
     */
    isAtRightSide: false,

    /**
     * The width of the descriptor options screen.
     * This is used for calculation the position of the options.
     *
     * @property descriptorOptionsWidth
     * @type {Number}
     * @protected
     */
    descriptorOptionsWidth: 200,
    willDestroyElement: function willDestroyElement() {
      openDescriptorItems.removeObject(this);

      this._super.apply(this, arguments);
    },
    actions: {
      toggleOpen: function toggleOpen() {
        if (!Ember.get(this, 'allowActions')) {
          return;
        }

        if (Ember.get(this, 'isOpen')) {
          this._closeOptions();
        } else {
          this._openOptions();
        }
      },
      updatePositive: function updatePositive(isPositive) {
        var assignedSensorialDescriptor = Ember.get(this, 'assignedSensorialDescriptor');
        Ember.set(assignedSensorialDescriptor, 'positive', isPositive);

        this._sendAction();
      },
      updateIntensity: function updateIntensity(val) {
        var assignedSensorialDescriptor = Ember.get(this, 'assignedSensorialDescriptor');
        Ember.set(assignedSensorialDescriptor, 'intensity', val * 1);

        this._sendAction();
      }
    },

    /**
     * Send the update action.
     *
     * @method _sendAction
     * @private
     */
    _sendAction: function _sendAction() {
      var action = Ember.get(this, 'updateAssignedSensorialDescriptor');
      var assignedSensorialDescriptor = Ember.get(this, 'assignedSensorialDescriptor');
      action(assignedSensorialDescriptor);
    },

    /**
     * Ensure that the position of the modal does not go outside of the screen.
     *
     * @method _checkPosition
     * @private
     */
    _checkPosition: function _checkPosition() {
      var el = this.element;
      var windowWidth = window.innerWidth;
      var position = el.offsetLeft;
      var modalWidth = Ember.get(this, 'descriptorOptionsWidth');
      /*
        If the modal would run out on the left side, we align it on the right side of the button instead
        However, it can be that the modal fits neither left nor right aligned - if the screen width is to small
        In that case, we want to instead align it on the right screen edge
       */

      var shouldAlignRight = position + modalWidth > windowWidth;
      var isAtRightScreenEdge = shouldAlignRight && position < modalWidth;
      var isAtRightSide = shouldAlignRight && !isAtRightScreenEdge;
      Ember.set(this, 'isAtRightSide', isAtRightSide);
      Ember.set(this, 'isAtRightScreenEdge', isAtRightScreenEdge);
    },

    /**
     * Open the options.
     *
     * @method _openOptions
     * @private
     */
    _openOptions: function _openOptions() {
      var _this = this;

      this._checkPosition();

      Ember.set(this, 'isOpen', true);
      openDescriptorItems.forEach(function (descriptorItem) {
        descriptorItem._closeOptions();
      });
      openDescriptorItems.pushObject(this);
      Ember.run.next(function () {
        _this._addEventListeners();
      }); // Track it

      var session = Ember.get(this, 'assignedSensorialDescriptor.qualityCuppingItem.qualityCupping.cuppingSession');
      var descriptor = Ember.get(this, 'assignedSensorialDescriptor.sensorialDescriptor');
      Ember.get(this, 'keenTracking').trackDescriptorModal(session, descriptor);
    },

    /**
     * Close the options.
     *
     * @method _closeOptions
     * @private
     */
    _closeOptions: function _closeOptions() {
      openDescriptorItems.removeObject(this);

      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      Ember.set(this, 'isOpen', false);
      this.removeOutsideClickListener();
    },

    /**
     * Add the event listener for clicking outside of the modal.
     *
     * @method _addEventListeners
     * @private
     */
    _addEventListeners: function _addEventListeners() {
      var _this2 = this;

      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      this.addOutsideClickListener(function (_ref) {
        var target = _ref.target;
        var parent = target.closest('.descriptor-options');

        if (!parent) {
          _this2._closeOptions();
        }
      }, {
        removeOnClick: false
      });
    }
  });

  _exports.default = _default;
});