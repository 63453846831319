define("cropster-app/components/create-sensorial-session/session-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userSession: Ember.inject.service(),
    createSession: Ember.inject.service(),
    keen: Ember.inject.service(),
    l10n: Ember.inject.service(),
    sensorialSession: null,
    sensorialSheet: Ember.computed.alias('sensorialSession.sensorialSheet'),
    allSensorialSheets: Ember.computed.alias('createSession.allSensorialSheets'),
    allLabs: Ember.computed.alias('createSession.allLabs'),
    allCategories: Ember.computed.alias('createSession.allSensorialQcCategories'),
    canViewExtendedSessions: Ember.computed.alias('createSession.canViewExtendedSessions'),
    actions: {
      updateName: function updateName(name) {
        var sensorialSession = Ember.get(this, 'sensorialSession');
        Ember.set(sensorialSession, 'name', name);
      },
      updateSensorialSheet: function updateSensorialSheet(sensorialSheet) {
        var sensorialSession = Ember.get(this, 'sensorialSession');
        Ember.set(sensorialSession, 'sensorialSheet', sensorialSheet);
        var keen = Ember.get(this, 'keen');
        /* eslint-disable camelcase */

        keen.sendEvent('session-form-update-sensorial-sheet', {
          sensorial_sheet: Ember.get(sensorialSheet, 'id'),
          sensorial_sheet_name: Ember.get(sensorialSheet, 'name')
        });
        /* eslint-enable camelcase */
      },
      updateLab: function updateLab(lab) {
        var sensorialSession = Ember.get(this, 'sensorialSession');
        Ember.set(sensorialSession, 'lab', lab);
        var keen = Ember.get(this, 'keen');
        /* eslint-disable camelcase */

        keen.sendEvent('session-form-update-lab', {
          lab: lab
        });
        /* eslint-enable camelcase */
      },
      toggleIsBlind: function toggleIsBlind(isNotBlind) {
        var sensorialSession = Ember.get(this, 'sensorialSession');
        Ember.set(sensorialSession, 'isBlind', !isNotBlind);
        var keen = Ember.get(this, 'keen');
        /* eslint-disable camelcase */

        keen.sendEvent('session-form-toggle-is-blind', {
          is_blind: !isNotBlind
        });
        /* eslint-enable camelcase */
      },
      updateCodeType: function updateCodeType(codeType) {
        var sensorialSession = Ember.get(this, 'sensorialSession');
        Ember.set(sensorialSession, 'codeType', codeType);
        sensorialSession.initQcCodes();
        var keen = Ember.get(this, 'keen');
        /* eslint-disable camelcase */

        keen.sendEvent('session-form-update-code-type', {
          code_type: codeType
        });
        /* eslint-enable camelcase */
      },
      updateSensorialQcCategory: function updateSensorialQcCategory(category) {
        var sensorialSession = Ember.get(this, 'sensorialSession');
        Ember.set(sensorialSession, '_sensorialQcCategory', category);
        var keen = Ember.get(this, 'keen');
        /* eslint-disable camelcase */

        keen.sendEvent('session-form-update-category', {
          category: category
        });
        /* eslint-enable camelcase */

        Ember.get(sensorialSession, 'sensorialQcs').forEach(function (sensorialQc) {
          Ember.set(sensorialQc, 'category', category);
        });
      }
    }
  });

  _exports.default = _default;
});