define("cropster-app/components/evaluation-form/inputs/special/descriptor-select/select-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * The actual select box for the descriptor select.
   *
   * @namespace Component.EvaluationForm.Inputs.Special.DescriptorSelect
   * @class SelectBox
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    /**
     * If the select box should be initially opened.
     *
     * @attribute initiallyOpened
     * @type {Boolean}
     * @default false
     * @public
     */
    initiallyOpened: false,

    /**
     * The action to use to determine if the create option should be shown.
     *
     * @event showCreateWhen
     * @param {string} searchTerm
     * @public
     */
    showCreateWhen: null,

    /**
     * The action to call when the selection changes.
     *
     * @event onchange
     * @param {Model.SensorialDescriptor} sensorialDescriptorToAdd
     * @public
     */
    onchange: null,

    /**
     * The action to call to create a new descriptor.
     *
     * @event oncreate
     * @param {String} sensorialDescriptorName
     * @public
     */
    oncreate: null,

    /**
     * The action to call whenever a key is pressed.
     * This is used for special actions, like splitting on commas etc.
     *
     * @event onkeydown
     * @param {Object} dropdown
     * @param {Event} event
     * @public
     */
    onkeydown: null,

    /**
     * The action to call to determine the search options.
     *
     * @event search
     * @param {String} searchTerm
     * @param {Object} dropdown
     * @public
     */
    search: null,

    /**
     * The action to call to close the input.
     *
     * @event close
     * @public
     */
    close: null,

    /**
     * If there are currently any search results or not.
     *
     * @property hasSearchResults
     * @type {Boolean}
     * @protected
     */
    hasSearchResults: false,
    actions: {
      onchange: function onchange(_ref, select) {
        var _ref2 = _slicedToArray(_ref, 1),
            sensorialDescriptor = _ref2[0];

        // If the select is still loading, it means that something wrong might be pre-selected
        // So instead of selecting whatever is highlighted, we call `oncreate` with the given search text
        // This will take care of add-or-create for the given search text
        if (select.loading && select.searchText) {
          return this.oncreate(select.searchText);
        }

        return this.onchange(sensorialDescriptor, select);
      },
      closeSelect: function closeSelect(select) {
        // When the select is closed while it is loading, nothing is highlighted and there is some search text,
        // We want to try to add this search text. E.g. if you have a slow connection, enter something and hit ENTER
        // right away, otherwise it would just drop the search text
        // The 'oncreate' action will do a add-or-create action anyhow, so we'll use this
        var searchText = select.searchText;

        if (select.loading && !select.highlighted && searchText) {
          return this.oncreate(searchText);
        }
      }
    }
  });

  _exports.default = _default;
});