define("cropster-app/services/raven", ["exports", "ember-cli-sentry/services/raven", "cropster-app/config/environment", "ember-cropster-common/errors/ajax", "cropster-app/utils/is-testing"], function (_exports, _raven, _environment, _ajax, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-console */
  var _default = _raven.default.extend({
    userSession: Ember.inject.service(),
    platform: Ember.inject.service('device/platform'),
    setupUserContext: function setupUserContext() {
      var userSession = Ember.get(this, 'userSession');
      var platformService = Ember.get(this, 'platform');
      this.callRaven('setUserContext', {
        id: Ember.get(userSession, 'username'),
        name: Ember.get(userSession, 'name'),
        username: Ember.get(userSession, 'name'),
        email: Ember.get(userSession, 'username'),
        groupCode: Ember.get(userSession, 'activeGroup.code')
      });
      var version = Ember.get(_environment.default, 'APP.version');
      var device = Ember.get(platformService, 'platform');
      var deviceVersion = Ember.get(platformService, 'version');
      var deviceManufacturer = Ember.get(platformService, 'device.manufacturer');

      if (version.indexOf('+') > -1) {
        version = version.substr(0, version.indexOf('+'));
      }

      this.callRaven('setTagsContext', {
        locale: Ember.get(userSession, 'locale'),
        timezone: Ember.get(userSession, 'timezone'),
        version: version,
        device: device,
        deviceVersion: deviceVersion,
        deviceManufacturer: deviceManufacturer
      });
    },
    ignoreError: function ignoreError(error) {
      // These errors are adapter codes, e.g. from being unauthenticated
      if (error && Ember.get(error, 'status') === 0) {
        return true;
      } // Ember 2.X seems to not catch `TransitionAborted` errors caused by
      // regular redirects. We don't want these errors to show up in Sentry
      // so we have to filter them ourselfs.
      //
      // Once this issue https://github.com/emberjs/ember.js/issues/12505 is
      // resolved we can remove this code.


      if (error && Ember.get(error, 'name') === 'TransitionAborted') {
        return true;
      }

      if (error instanceof _ajax.AjaxNetworkError) {
        return true;
      }

      return false;
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (_environment.default.environment === 'development' && !(0, _isTesting.isTesting)()) {
        Ember.RSVP.on('error', function (reason, label) {
          if (_this.ignoreError(reason)) {
            return;
          }

          console.warn('Unhandled promise error', reason, label);
        });
      }
    }
  });

  _exports.default = _default;
});