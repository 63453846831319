define("cropster-app/services/log", ["exports", "moment", "cropster-app/utils/is-testing"], function (_exports, _moment, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * Service responsible for all types of logging:
   *   * Analytics
   *   * Crash reporting
   *   * Debug logs
   * @class Service.Log
   */
  var _default = Ember.Service.extend({
    indexedDb: Ember.inject.service(),
    userSession: Ember.inject.service(),
    platform: Ember.inject.service('device/platform'),
    _shouldLog: Ember.computed(function () {
      return !(0, _isTesting.isTesting)();
    }),
    // ---------------------------------------------------------------------------------------------------------
    // LOGGING methods - that which is related to logging (console.log/error/warn/info)

    /**
     * Write to a local storage log.
     * @param message
     */
    writeLog: function writeLog(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var json = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (_typeof(title) === 'object') {
        json = title;
        title = null;
      }

      var db = Ember.get(this, 'indexedDb.db'); // If the DB is not available anymore, e.g. in test, abort

      if (!db || !Ember.get(this, '_shouldLog')) {
        return;
      } // Since iOS 10.3 is really buggy regarding this, we don't save the log for iOS :(
      // TODO: Remove this once iOS is fixed


      if (!Ember.get(this, 'platform.isIos')) {
        db.log.put({
          message: message,
          title: title,
          json: json,
          type: null,
          timestamp: new Date(),
          cupper: Ember.get(this, 'userSession.username'),
          groupCode: Ember.get(this, 'userSession.activeGroup.code')
        });
      }
      /* eslint-disable no-console */

      /* istanbul ignore next */


      if (!(0, _isTesting.isTesting)()) {
        console.info(message);
      }
      /* eslint-enable no-console */

    },
    getLog: function getLog(from) {
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;

      if (!from) {
        from = (0, _moment.default)().subtract(14, 'days');
      }

      var db = Ember.get(this, 'indexedDb.db'); // If the DB is not available anymore, e.g. in test, abort

      if (!db) {
        return Ember.RSVP.Promise.resolve([]);
      }

      return db.log.where('timestamp').aboveOrEqual(from.toDate()).reverse().limit(limit).toArray();
    },
    clearOldLog: function clearOldLog(until) {
      if (!until) {
        until = (0, _moment.default)().subtract(15, 'days');
      }

      var db = Ember.get(this, 'indexedDb.db');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        // If the DB is not available anymore, e.g. in test, abort
        if (!db) {
          return resolve([]);
        }

        db.log.where('timestamp').below(until.toDate()).toArray().then(function (logItems) {
          var promises = logItems.map(function (item) {
            return db.log.delete(Ember.get(item, 'id'));
          });
          Ember.RSVP.all(promises).then(resolve, reject);
        });
      });
    }
  });

  _exports.default = _default;
});