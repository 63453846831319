define("cropster-app/services/user-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service allows to set and load user settings.
   *
   * @namespace Service
   * @class UserSettings
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    userSession: Ember.inject.service(),

    /**
     * If a locale was overridden in the settings.
     * If this is not set, use the default locale of the device instead, or else English.
     *
     * @property locale
     */
    locale: Ember.computed(function () {
      return this.getItem('cup-locale');
    }),

    /**
     * The page size to use for evaluation.
     * Defaults to 3.
     *
     * Note that this is not respected for compact sheets (e.g. production sheets).
     *
     * @property pageSize
     * @type {Number}
     * @default 3
     * @public
     */
    pageSize: Ember.computed(function () {
      return this.getItem('page-size') || 3;
    }),

    /**
     * The local storage namespace where to save the settings.
     *
     * @property _localStorageNamespace
     * @type {String}
     * @default 'user-settings'
     * @protected
     */
    _localStorageNamespace: Ember.computed('userSession.currentUser.username', function () {
      var userId = Ember.get(this, 'userSession.currentUser.username');
      return "cup-user-settings-".concat(userId);
    }),

    /**
     * The setting data, loaded from local storage & deserialized into an object.
     *
     * @property _data
     * type {Object}
     * @protected
     */
    _data: Ember.computed('_localStorageNamespace', function () {
      var localStorageNamespace = Ember.get(this, '_localStorageNamespace');
      var data = {};

      try {
        data = window.localStorage.getItem(localStorageNamespace);
        data = data ? JSON.parse(data) : {};
      } catch (e) {
        /* eslint-disable no-console */
        console.error('Could not load from local storage', e);
        /* eslint-enable no-console */
      }

      return data;
    }),

    /**
     * Save the data to the local storage.
     *
     * @method saveData
     * @private
     */
    saveData: function saveData() {
      var data = Ember.get(this, '_data');
      var localStorageNamespace = Ember.get(this, '_localStorageNamespace');
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var isTesting = config && Ember.get(config, 'environment') === 'test';

      try {
        if (!isTesting) {
          window.localStorage.setItem(localStorageNamespace, JSON.stringify(data));
        }
      } catch (e) {
        /* eslint-disable no-console */
        console.error('Could not save to local storage', e);
        /* eslint-enable no-console */
      }
    },
    _getData: function _getData() {
      var forGroup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var data = Ember.get(this, '_data');

      if (forGroup) {
        var group = Ember.get(this, 'userSession.activeGroup.id');
        var groupData = data[group];

        if (!groupData) {
          groupData = {};
          data[group] = groupData;
        }

        data = groupData;
      }

      return data;
    },

    /**
     * Get a single item.
     *
     * @method getItem
     * @param {String} item
     * @return {Mixed}
     * @public
     */
    getItem: function getItem(item) {
      var forGroup = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var data = this._getData(forGroup);

      return Ember.get(data, item);
    },

    /**
     * Set a single item.
     *
     * @method setItem
     * @param {String} item
     * @param {Mixed} value
     * @public
     */
    setItem: function setItem(item, value) {
      var forGroup = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var data = this._getData(forGroup);

      Ember.set(data, item, value);
      Ember.run.debounce(this, this.saveData, 250);
    }
  });

  _exports.default = _default;
});