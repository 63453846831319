define("cropster-app/utils/calculate-cupping-result-item-score", ["exports", "cropster-app/utils/formula-parser"], function (_exports, _formulaParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateCuppingResultItemScore = calculateCuppingResultItemScore;

  function calculateCuppingResultItemScore(cuppingSheetItem, cuppingResultItem) {
    var intensity = Ember.get(cuppingResultItem, 'intensity');
    var quality = Ember.get(cuppingResultItem, 'quality');
    var hasFormula = Ember.get(cuppingSheetItem, 'hasFormula');
    var formula = Ember.get(cuppingSheetItem, 'parsedFormula');
    var score = 0;

    if (hasFormula) {
      try {
        score = (0, _formulaParser.calculateScore)(formula, quality, intensity);
      } catch (e) {
        score = 0;
      }
    } else {
      if (quality) {
        score += +quality;
      }

      if (Ember.isNone(quality)) {
        score = null;
      } // when there is no formula, intensity does not affect score

    }

    return score;
  }
});