define("cropster-app/components/evaluation-form/inputs/special/defects/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "77k+Wns5",
    "block": "{\"symbols\":[\"currentValue\",\"val\",\"currentValue\",\"val\",\"@updateQuality\",\"@updateIntensity\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"title title--with-item-score-right \",[21,\"titleClass\"]]]],[11,\"data-test-evaluation-input-title\",\"\"],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"item-score-right\"],[11,\"data-test-evaluation-input-score\",\"\"],[9],[0,\"\\n  \"],[1,[27,\"format-number\",[[23,[\"calculatedQuality\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"c-select\",null,[[\"value\",\"update\",\"data-test-evaluation-input-defects-quality\"],[[23,[\"quality\"]],[22,5,[]],true]],{\"statements\":[[4,\"each\",[[23,[\"cuppingSheetItem\",\"qualityRange\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,4,[]]],[12,\"selected\",[27,\"eq\",[[22,4,[]],[22,3,[]]],null]],[9],[0,\"\\n      \"],[1,[22,4,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[4]},null]],\"parameters\":[3]},null],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"defects-separator\"],[9],[0,\"\\n\"],[0,\"  x\\n\"],[10],[0,\"\\n\\n\"],[4,\"c-select\",null,[[\"value\",\"update\",\"data-test-evaluation-input-defects-intensity\"],[[23,[\"intensity\"]],[22,6,[]],true]],{\"statements\":[[4,\"each\",[[23,[\"cuppingSheetItem\",\"intensityRange\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,2,[]]],[12,\"selected\",[27,\"eq\",[[22,2,[]],[22,1,[]]],null]],[9],[0,\"\\n      \"],[1,[22,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/special/defects/template.hbs"
    }
  });

  _exports.default = _default;
});