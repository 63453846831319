define("cropster-app/adapters/indexed-db-adapter", ["exports", "ember-indexeddb/adapters/indexed-db", "cropster-app/utils/is-testing"], function (_exports, _indexedDb, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _indexedDb.default.extend({
    _shouldLogDurations: Ember.computed(function () {
      return !(0, _isTesting.isTesting)();
    })
  });

  _exports.default = _default;
});