define("cropster-app/components/evaluation-form/footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The footer for the evaluation form.
   *
   * @namespace Component.EvaluationForm
   * @class Footer
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['footer--actions'],

    /**
     * The paginated items for the current evaluation.
     * This is an array of arrays, where each item contains all the analysis results of a given page.
     *
     * E.g.: [ [1,2,3], [4,5,6], [7] ]
     *
     * @attribute paginatedItems
     * @type {Array[]}
     * @public
     */
    paginatedItems: null,

    /**
     * The session that is currently being evaluated.
     *
     * @attribute session
     * @type {Model.CuppingSession}
     * @public
     */
    session: null,

    /**
     * The currently selected page.
     *
     * @attribute currentPage
     * @type {Number}
     * @public
     */
    currentPage: null,

    /**
     * The action to call to goto a specific page.
     *
     * @event gotoPage
     * @param {Number} page
     * @public
     */
    gotoPage: null,

    /**
     * The action to call to submit the session.
     *
     * @event submitSession
     * @public
     */
    submitSession: null
  });

  _exports.default = _default;
});