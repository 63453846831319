define("cropster-app/components/evaluation-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The evaluation form for a given session.
   *
   * @namespace Component
   * @class EvaluationForm
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    classNameBindings: ['isCompact:evaluation-form--is-compact'],
    screen: Ember.inject.service(),
    userSettings: Ember.inject.service(),

    /**
     * The session to evaluate.
     *
     * @attribute session
     * @type {Model.CuppingSession}
     * @public
     */
    session: null,

    /**
     * The action to call to submit the session.
     *
     * @event submitSession
     * @public
     */
    submitSession: null,

    /**
     * The currently selected page.
     *
     * @property currentPage
     * @type {Number}
     * @protected
     */
    currentPage: 1,

    /**
     * The analysis results of the session.
     *
     * @property analysisResults
     * @type {Model.AnalysisResult[]}
     * @readOnly
     * @protecteds
     */
    analysisResults: Ember.computed.alias('session.analysisResults'),

    /**
     * If the session is compact.
     *
     * @property isCompact
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isCompact: Ember.computed.alias('session.isCompact'),

    /**
     * The page size to use.
     * This might be overwritten, if the rendering is compact.
     *
     * @property settingsPageSize
     * @type {Number}
     * @readOnly
     * @protected
     */
    settingsPageSize: Ember.computed.alias('userSettings.pageSize'),

    /**
     * The actual page size to use.
     * For a compact rendering, this is set to 999.
     *
     * @property pageSize
     * @type {Number}
     * @readOnly
     * @protected
     */
    pageSize: Ember.computed('settingsPageSize', 'isCompact', function () {
      if (Ember.get(this, 'session.isCompact')) {
        return 999;
      }

      return Ember.get(this, 'settingsPageSize');
    }),

    /**
     * The number of pages there are.
     *
     * @property pageCount
     * @type {Number}
     * @readOnly
     * @protected
     */
    pageCount: Ember.computed('analysisResults.length', 'pageSize', function () {
      var allItemsLength = Ember.get(this, 'analysisResults.length');
      var pageSize = Ember.get(this, 'pageSize');
      return Math.ceil(allItemsLength / pageSize);
    }),

    /**
     * The paginated items.
     * This is an array of arrays, where each sub-array contains the analysis results of that page.
     * E.g. [ [A,B], [C,D], [E] ]
     *
     * @property paginatedItems
     * @type {Array[]}
     * @readOnly
     * @protected
     */
    paginatedItems: Ember.computed('analysisResults.[]', 'pageSize', 'pageCount', function () {
      var pageSize = Ember.get(this, 'pageSize');
      var pageCount = Ember.get(this, 'pageCount');
      var allItems = Ember.get(this, 'analysisResults');
      var grouped = [];

      for (var i = 0; i < pageCount; i++) {
        var offset = i * pageSize;
        var offsetEnd = (i + 1) * pageSize;
        grouped[i] = allItems.slice(offset, offsetEnd);
      }

      return grouped;
    }),

    /**
     * The current page items.
     * These are the analysis results that are actually visible right now.
     *
     * @property currentPageItems
     * @type {Model.AnalysisResult[]}
     * @readOnly
     * @protected
     */
    currentPageItems: Ember.computed('paginatedItems.[]', 'currentPage', function () {
      var page = Ember.get(this, 'currentPage') - 1; // 0-based!

      var grouped = Ember.get(this, 'paginatedItems');
      return grouped[page];
    }),
    didInsertElement: function didInsertElement() {
      this._showIsInView();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._teardownIntersectionObserver();
    },
    actions: {
      setPage: function setPage(page) {
        Ember.set(this, 'currentPage', page); // Scroll up...

        var el = Ember.get(this, 'screen.rootElement');
        el.scrollTop = 0;
      }
    },

    /**
     * Check if a given sample element (DOMNode) is visible, and add classes accordingly.
     *
     * @method _checkSampleIntersection
     * @param {DOMElement} el
     * @param {Number} intersectionRatio
     * @private
     */
    _checkSampleIntersection: function _checkSampleIntersection(el, intersectionRatio) {
      var threshold = 0.3; // How much percent of the sample has to be in view to be considered active

      var isIntersecting = intersectionRatio >= threshold;

      if (isIntersecting) {
        el.classList.remove('evaluation-form__sample--unfocused');
      } else {
        el.classList.add('evaluation-form__sample--unfocused');
      }
    },

    /**
     * Create an intersection observer to check if a given sample is in the view.
     * This is not supported everywhere - in browsers that do not support this, this will be ignored.
     *
     * @method _showIsInView
     * @private
     */
    _showIsInView: function _showIsInView() {
      var _this = this;

      if (!('IntersectionObserver' in window)) {
        return;
      }

      var options = {
        root: Ember.get(this, 'screen.rootElement'),
        rootMargin: '-60px 0px',
        threshold: [0.3, 0.4]
      };
      var observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (options) {
          var intersectionRatio = options.intersectionRatio,
              target = options.target;

          _this._checkSampleIntersection(target, intersectionRatio);
        });
      }, options);
      Ember.set(this, 'intersectionObserver', observer);
    },

    /**
     * Teardown the intersection observer (if one was created).
     *
     * @method _teardownIntersectionObserver
     * @private
     */
    _teardownIntersectionObserver: function _teardownIntersectionObserver() {
      var intersectionObserver = Ember.get(this, 'intersectionObserver');

      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    }
  });

  _exports.default = _default;
});