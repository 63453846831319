define("cropster-app/errors/offline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var OfflineError = function OfflineError(errors) {
    var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'It seems like you are offline.';
    Ember.Error.call(this, message);
    this.status = 0;
    this.name = 'Offline Error';
    this.errors = errors || [{
      title: 'You are offline',
      detail: message
    }];
  };

  OfflineError.prototype = Object.create(Ember.Error.prototype);
  var _default = OfflineError;
  _exports.default = _default;
});