define("cropster-app/utils/is-on-cordova", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isOnCordova = isOnCordova;
  _exports.default = void 0;

  function isOnCordova() {
    return !!window.cordova;
  }

  var _default = isOnCordova;
  _exports.default = _default;
});