define("cropster-app/lot/model", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A lot object.
   *
   * @namespace Model
   * @class Lot
   * @extends DS.Model
   * @public
   */
  var _default = _model.default.extend({
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * The name of the lot.
     *
     * @attribute name
     * @type {String}
     * @public
     */
    name: (0, _attr.default)('string', {
      defaultValue: ''
    }),

    /**
     * A more human readable ID, e.g. "PR-123".
     *
     * @attribute idTag
     * @type {String}
     * @public
     */
    idTag: (0, _attr.default)('string'),

    /**
     * When this lot was created.
     *
     * @attribute creationDate
     * @type {Date}
     * @public
     */
    creationDate: (0, _attr.default)('timestamp'),

    /**
     * The initial weight that was bought.
     *
     * @attribute initialWeight
     * @type {Object.Measure}
     * @public
     */
    initialWeight: (0, _attr.default)(),
    // eslint-disable-line ember/no-empty-attrs

    /**
     * The actual weight that is currently available.
     *
     * @attribute actualWeight
     * @type {Object.Measure}
     * @public
     */
    actualWeight: (0, _attr.default)(),
    // eslint-disable-line ember/no-empty-attrs

    /**
     * The expectedWeight for a sample.
     * This describes how much the user is planning on buying.
     *
     * @attribute expectedWeight
     * @type {Object.Measure}
     * @public
     */
    expectedWeight: (0, _attr.default)(),
    // eslint-disable-line ember/no-empty-attrs

    /**
     * The sample type of this lot.
     *
     * @attribute sampleType
     * @type {String}
     * @public
     */
    sampleType: (0, _attr.default)('string'),

    /**
     * The processing step of this lot, e.g. coffee.roasting or coffee.green.
     *
     * @attribute processingStep
     * @type {String}
     * @public
     */
    processingStep: (0, _attr.default)('string'),

    /**
     * The grade if the lot.
     *
     * @attribute grade
     * @type {String}
     * @public
     */
    grade: (0, _attr.default)('string'),

    /**
     * The tracking number of the lot, e.g. a postal service's number.
     *
     * @attribute trackingNumber
     * @type {String}
     * @public
     */
    trackingNumber: (0, _attr.default)('string'),

    /**
     * The sales number of the lot.
     *
     * @attribute salesNumber
     * @type {String}
     * @public
     */
    salesNumber: (0, _attr.default)('string'),

    /**
     * The PO number of the lot.
     *
     * @attribute purchaseOrderNumber
     * @type {String}
     * @public
     */
    purchaseOrderNumber: (0, _attr.default)('string'),
    // ---------------------------------------------------------------------------------------------------------
    // Properties

    /**
     * The complete name of this lot.
     * This is built from its name and idTag.
     *
     * @property completeName
     * @type {String}
     * @readOnly
     * @public
     */
    completeName: Ember.computed('name', 'idTag', function () {
      var idTag = Ember.get(this, 'idTag');
      var name = Ember.get(this, 'name');
      var str = "[".concat(idTag || '', "]");

      if (name) {
        str += " ".concat(name);
      }

      return str;
    })
  });

  _exports.default = _default;
});