define("cropster-app/dashboard/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    createSession: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    openSessionTask: Ember.computed.alias('dashboard.openSessionTask'),
    canCreateSessions: Ember.computed.alias('createSession.canCreateSessions'),
    createSessionIsLoading: Ember.computed.alias('createSession.isLoading')
  });

  _exports.default = _default;
});