define("cropster-app/components/evaluation-form/inputs/slider-blind/component", ["exports", "cropster-app/components/evaluation-form/inputs/slider/component", "cropster-app/components/evaluation-form/inputs/slider/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A variation of the slider, this one doesn't show any values.
   * This has been created specially for COOC, who need that.
   *
   * @namespace Component.EvaluationForm.Inputs
   * @class SliderBlind
   * @extends Component.EvaluationForm.Inputs.Slider
   * @public
   */
  var _default = _component.default.extend({
    layout: _template.default
  });

  _exports.default = _default;
});