define("cropster-app/components/create-sensorial-session/session-lots/table-lots/columns/sample-type/component", ["exports", "ember-cropster-models-table/components/c-table/column/component", "cropster-app/components/create-sensorial-session/session-lots/table-lots/columns/sample-type/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This extends from the base column of c-table for custom displays.
   * The following fields are available:
   *
   * record
   * column
   * visibleColumns
   * selectedItems
   * actionHash
   *
   * For your convenience, there are also these computed properties to use:
   *
   * propertyName (of the column)
   * value (based on record * propertyName)
   *
   * @namespace Component.SessionLots.TableLots.Columns
   * @class SampleType
   * @extends EmberCropsterModelsTable.Component.CTable.Column
   * @public
   */
  var _default = _component.default.extend({
    layout: _template.default,
    attributeBindings: ['columnTitle:data-table-column-title'],
    l10n: Ember.inject.service(),
    columnTitle: Ember.computed(function () {
      return Ember.get(this, 'l10n').t('Sample type');
    })
  });

  _exports.default = _default;
});