define("cropster-app/components/evaluation-form/inputs/buttons-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bWRI6XVX",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"title title--with-item-score-right \",[21,\"titleClass\"]]]],[11,\"data-test-evaluation-input-title\",\"\"],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"item-score-right\"],[11,\"data-test-evaluation-input-score\",\"\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"is-none\",[[23,[\"calculatedQuality\"]]],null]],null,{\"statements\":[[0,\"    -\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"format-number\",[[23,[\"calculatedQuality\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"button-container\"],[9],[4,\"each\",[[23,[\"parsedSteps\"]]],[[\"key\"],[\"position\"]],{\"statements\":[[7,\"button\"],[11,\"data-test-evaluation-input-toggle-button\",\"\"],[12,\"class\",[28,[\"button \",[22,1,[\"classes\"]]]]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"isSelected\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--grey\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"svg-icon\",[\"check\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[22,0,[]],\"toggleButton\",[22,1,[]]]],[10]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/buttons-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});