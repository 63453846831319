define("cropster-app/cupping-result/serializer", ["exports", "cropster-app/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _serializer.default.extend({
    attrs: {
      resultItems: {
        serialize: true
      }
    },
    normalizeCSAR: function normalizeCSAR(model, hash, prop) {
      hash = this._super(model, hash, prop);
      var _hash = hash,
          sampleCode = _hash.sampleCode,
          cupper = _hash.cupper,
          cuppingDate = _hash.cuppingDate,
          cupperScore = _hash.cupperScore,
          notes = _hash.notes,
          roastLevel = _hash.roastLevel,
          score = _hash.score,
          takenIntoAccount = _hash.takenIntoAccount;
      var attributes = {
        sampleCode: sampleCode,
        cupper: cupper,
        cuppingDate: cuppingDate,
        cupperScore: cupperScore,
        notes: notes,
        roastLevel: roastLevel,
        score: score,
        takenIntoAccount: takenIntoAccount
      };
      var cuppingItems = hash.resultItems;
      var included = [];
      var cuppingItemSerializer = this.store.serializerFor('quality-cupping-item');
      var relationshipsOfCuppingItems = cuppingItems.map(function (result) {
        var _cuppingItemSerialize = cuppingItemSerializer.deserializeFromCuppingResult(hash.id, result),
            assignedSensorialDescriptors = _cuppingItemSerialize.assignedSensorialDescriptors,
            cuppingItemData = _cuppingItemSerialize.cuppingItemData;

        included.push(cuppingItemData);

        if (assignedSensorialDescriptors.length > 0) {
          included.push.apply(included, _toConsumableArray(assignedSensorialDescriptors));
        }

        return {
          type: cuppingItemData.type,
          id: cuppingItemData.id
        };
      });
      var newHash = {
        included: included,
        data: {
          attributes: attributes,
          id: hash.id,
          type: 'cupping-result',
          relationships: {
            analysisResult: {
              data: {
                type: 'analysis-result',
                id: hash.analysisResult
              }
            },
            cuppingSession: {
              data: {
                type: 'cupping-session',
                id: hash.analysisSession
              }
            },
            cuppingSheet: {
              data: {
                type: 'cupping-sheet',
                id: hash.cuppingSkin.id
              }
            },
            resultItems: {
              data: relationshipsOfCuppingItems
            }
          }
        }
      };
      return newHash;
    }
  });

  _exports.default = _default;
});