define("cropster-app/services/create-session", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseObject = _emberData.default.PromiseObject;

  var _default = Ember.Service.extend({
    userSession: Ember.inject.service(),
    store: Ember.inject.service(),
    groupCode: Ember.computed.alias('userSession.groupCode'),
    userName: Ember.computed.alias('userSession.username'),
    currentGroupMembership: Ember.computed('userName', 'groupCode', 'userSession.activeGroup', function () {
      var store = Ember.get(this, 'store');
      var userName = Ember.get(this, 'userName');
      var groupCode = Ember.get(this, 'groupCode');

      if (!groupCode || !userName) {
        return PromiseObject.create({
          promise: Ember.RSVP.Promise.resolve(null)
        });
      }

      return store.queryRecord('group-membership', {
        userName: userName,
        group: groupCode,
        include: ['permissions', 'group.sensorialSheets', 'group.properties']
      });
    }),
    isLoading: Ember.computed.or('currentGroupMembership.isLoading', 'currentGroupMembership.isPending'),
    permissions: Ember.computed('currentGroupMembership.id', function () {
      var store = Ember.get(this, 'store');
      return store.peekAll('permission');
    }),
    allPermissions: Ember.computed.mapBy('permissions', 'name'),
    allProperties: Ember.computed('currentGroupMembership.id', function () {
      var store = Ember.get(this, 'store');
      return store.peekAll('property');
    }),

    /**
     * The property object for the labs list.
     *
     * @property allLabsProperty
     * @type {Model.Property}
     * @readOnly
     * @public
     */
    allLabsProperty: Ember.computed('allProperties.@each.name', function () {
      return Ember.get(this, 'allProperties').findBy('name', 'qm.labs');
    }),

    /**
     * All labs for the current group.
     *
     * @property allLabs
     * @type {String[]}
     * @readOnly
     * @public
     */
    allLabs: Ember.computed('allLabsProperty.value', function () {
      var prop = Ember.get(this, 'allLabsProperty');
      return prop && Ember.get(prop, 'value') ? Ember.get(prop, 'value').sort(Ember.compare) : [];
    }),

    /**
     * The property object for the QC categories list.
     *
     * @property allSensorialQcCategoriesProperty
     * @type {Model.Property}
     * @readOnly
     * @public
     */
    allSensorialQcCategoriesProperty: Ember.computed('allProperties.@each.name', function () {
      return Ember.get(this, 'allProperties').findBy('name', 'qm.categories');
    }),

    /**
     * All QC categories for the current group.
     *
     * @property allSensorialQcCategories
     * @type {String[]}
     * @readOnly
     * @public
     */
    allSensorialQcCategories: Ember.computed('allSensorialQcCategoriesProperty.value', function () {
      var prop = Ember.get(this, 'allSensorialQcCategoriesProperty');
      return prop && Ember.get(prop, 'value') ? Ember.get(prop, 'value') : [];
    }),

    /**
     * All cupping sheets for the current group code.
     *
     * @property allSensorialSheetsUnsorted
     * @type {Model.SensorialSheet[]}
     * @readOnly
     * @public
     */
    allSensorialSheetsUnsorted: Ember.computed(function () {
      var store = Ember.get(this, 'store');
      return store.peekAll('sensorial-sheet');
    }),

    /**
     * All cupping sheets for the current group code.
     * This is sorted by name.
     *
     * @property allSensorialSheets
     * @type {Model.SensorialSheet[]}
     * @readOnly
     * @public
     */
    allSensorialSheets: Ember.computed('allSensorialSheetsUnsorted.[]', function () {
      var sensorialSheets = Ember.get(this, 'allSensorialSheetsUnsorted') || [];
      return sensorialSheets.toArray().sort(function (a, b) {
        if (Ember.get(a, 'isDefaultSheet')) {
          return -1;
        }

        if (Ember.get(b, 'isDefaultSheet')) {
          return 1;
        }

        return Ember.compare(Ember.get(a, 'name'), Ember.get(b, 'name'));
      });
    }),
    canViewExtendedSessions: Ember.computed('allPermissions.[]', function () {
      return Ember.get(this, 'allPermissions').includes('labExtendedSession');
    }),
    canCreateSessions: Ember.computed('allPermissions.[]', function () {
      return Ember.get(this, 'allPermissions').includes('groupQualityEdit');
    }),
    setup: function setup() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // Force reloading if it wasn't set yet
        if (!Ember.get(_this, 'currentGroupMembership.id')) {
          _this.notifyPropertyChange('currentGroupMembership');
        }

        Ember.get(_this, 'currentGroupMembership').then(function (currentGroupMembership) {
          _this.notifyPropertyChange('permissions');

          _this.notifyPropertyChange('allProperties');

          _this.notifyPropertyChange('allSensorialSheetsUnsorted');

          resolve({
            currentGroupMembership: currentGroupMembership,
            permissions: Ember.get(_this, 'permissions'),
            allProperties: Ember.get(_this, 'allProperties')
          });
        }).catch(reject);
      }, 'createSession/setup');
    }
  });

  _exports.default = _default;
});