define("cropster-app/groups-reload/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.transitionTo('groups', {
        queryParams: {
          reload: true,
          redirectIfActiveGroup: true
        }
      });
    }
  });

  _exports.default = _default;
});