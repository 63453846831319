define("cropster-app/components/touch-slider/slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rhOC1Zlv",
    "block": "{\"symbols\":[\"@onChange\"],\"statements\":[[1,[27,\"range-slider\",null,[[\"onRender\",\"behaviour\",\"start\",\"range\",\"step\",\"formatFrom\",\"pips\",\"tooltips\",\"on-set\"],[[23,[\"onRender\"]],\"none\",[23,[\"actualStart\"]],[23,[\"range\"]],[23,[\"step\"]],[23,[\"formatFrom\"]],[23,[\"pips\"]],[23,[\"tooltips\"]],[27,\"action\",[[22,0,[]],[22,1,[]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/touch-slider/slider/template.hbs"
    }
  });

  _exports.default = _default;
});