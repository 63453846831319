define("cropster-app/groups/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hrXI4td4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-center\"]],{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Groups\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-right\"]],{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"data-test-reload-groups\",\"true\"],[11,\"class\",\"button button--plain button--icon\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"refresh\"],[[\"class\"],[\"icon--white icon--refresh\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Refresh\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"refreshGroups\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"offline-ui\"],false],[0,\"\\n\\n\"],[1,[27,\"groups-list\",null,[[\"groups\",\"enterGroup\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"onEnterGroup\"],null]]]],false],[0,\"\\n\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"app-footer\"]],{\"statements\":[[0,\"  \"],[1,[21,\"app-footer\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/groups/template.hbs"
    }
  });

  _exports.default = _default;
});