define("cropster-app/create-session/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    createSession: Ember.inject.service(),
    userSession: Ember.inject.service(),
    store: Ember.inject.service(),
    networkState: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.get(_this, 'networkState').check().then(function () {
          Ember.get(_this, 'createSession').setup().then(resolve, reject);
        }).catch(reject);
      });
    },
    model: function model() {
      var store = Ember.get(this, 'store');
      var group = Ember.get(this, 'userSession.activeGroup');
      var roundTo = 5 * 60 * 1000; // 5 minutes

      var scheduleDate = (0, _moment.default)(Math.ceil((0, _moment.default)().valueOf() / roundTo) * roundTo);
      var defaultSensorialSheet = Ember.get(this, 'createSession.allSensorialSheets.firstObject');
      var defaultLab = Ember.get(this, 'createSession.allLabs.firstObject');
      return store.createRecord('sensorial-session', {
        isBlind: false,
        evaluators: [],
        state: 'DRAFT',
        scheduleDate: scheduleDate,
        codeType: 'ALPHABETICAL',
        sensorialSheet: defaultSensorialSheet,
        lab: defaultLab,
        group: group,
        sensorialQcs: []
      });
    },
    afterModel: function afterModel() {
      var createSession = Ember.get(this, 'createSession');

      if (!Ember.get(createSession, 'canCreateSessions')) {
        this.transitionTo('create-session-error');
      }
    },
    deactivate: function deactivate() {
      var model = this.currentModel;

      if (model) {
        model.rollbackAttributes();
      }
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('create-session-error');
      }
    }
  });

  _exports.default = _default;
});