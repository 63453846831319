define("cropster-app/session-sync-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O2FIK7bb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-left\"]],{\"statements\":[[4,\"link-to\",[\"dashboard\"],[[\"class\"],[\"button button--plain button--icon\"]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",[\"arrow-right-thin\"],[[\"class\"],[\"icon icon--white transform-rotate-180\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Sessions\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-center\"]],{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Synchronisation status\"],null],false],[0,\"\\n  |\\n  [\"],[1,[23,[\"model\",\"idTag\"]],false],[0,\"]\\n  \"],[1,[23,[\"model\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"session-sync-status\",null,[[\"session\",\"goToDashboard\",\"class\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"goToDashboard\"],null],\"margin-top-medium\"]]],false],[0,\"\\n\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"app-footer\"]],{\"statements\":[[0,\"  \"],[1,[21,\"app-footer\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/session-sync-details/template.hbs"
    }
  });

  _exports.default = _default;
});