define("cropster-app/components/evaluation-form/inputs/special/descriptor-select-compact/buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aDIZIBDg",
    "block": "{\"symbols\":[\"item\",\"@addOrCreateSensorialDescriptor\"],\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"data-test-evaluation-input-descriptor-input-compact-button\",\"\"],[11,\"class\",\"button button--light margin-bottom-xsmall\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[22,1,[]],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[22,2,[]],[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/special/descriptor-select-compact/buttons/template.hbs"
    }
  });

  _exports.default = _default;
});