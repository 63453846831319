define("cropster-app/analysis-result/model", ["exports", "ember-data", "ember-indexeddb/mixins/model-bulk-save"], function (_exports, _emberData, _modelBulkSave) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  /**
   * A quality analysis result.
   *
   * @class Model.AnalysisResult
   * @extends DS.Model
   */

  var _default = Model.extend(_modelBulkSave.default, {
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * The id tag of the analysis, e.g. QC-123.
     *
     * @attribute sampleCode
     * @type {String}
     */
    idTag: attr('string'),

    /**
     * The sample to analyse will be referred to by a code like "QXR".
     *
     * @attribute sampleCode
     * @type {String}
     */
    sampleCode: attr('string'),

    /**
     * Sums up the individual results of all cuppers.
     *
     * @attribute resultSummary
     * @type {String}
     */
    resultSummary: attr('string'),

    /**
     * The name of the lot to which this analysis belongs to.
     *
     * @attribute lotName
     * @type {String}
     */
    lotName: attr('string'),

    /**
     * The id-tag of the lot to which this analysis belongs to.
     *
     * @attribute lotIdTag
     * @type {String}
     */
    lotIdTag: attr('string'),

    /**
     * The date this QC was scheduled/created.
     *
     * @attribute cuppingDate
     * @type {Date}
     */
    cuppingDate: attr('timestamp'),

    /**
     * All cuppings belonging to this analysis.
     *
     * @attribute cuppings
     * @type {Model.CuppingResult[]}
     */
    cuppings: hasMany('cupping-result'),

    /**
     * The cupping session this analysis belongs to.
     *
     * @attribute cuppingSession
     * @type {Model.QualitySession}
     */
    session: belongsTo('cupping-session'),
    // ---------------------------------------------------------------------------------------------------------
    // Properties
    label: Ember.computed('session.isBlind', 'sampleCode', 'lotIdTag', function () {
      if (Ember.get(this, 'session.isBlind') === false) {
        return Ember.get(this, 'lotIdTag');
      }

      return Ember.get(this, 'sampleCode');
    }),

    /**
     * The cuppings which should actually be taken into account.
     *
     * @property acceptedCuppings
     * @type {Model.QualityCupping[]}
     * @readOnly
     */
    acceptedCuppings: Ember.computed.filterBy('cuppings', 'takenIntoAccount'),

    /**
     * The number of cuppers which are taken into account.
     *
     * @attribute cupperCount
     * @type {Number}
     * @readOnly
     */
    cupperCount: Ember.computed.alias('acceptedCuppings.length')
  });

  _exports.default = _default;
});