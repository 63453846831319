define("cropster-app/helpers/eq-array-content", ["exports", "ember-cropster-common/helpers/eq-array-content"], function (_exports, _eqArrayContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _eqArrayContent.default;
    }
  });
  Object.defineProperty(_exports, "eqArrayContent", {
    enumerable: true,
    get: function get() {
      return _eqArrayContent.eqArrayContent;
    }
  });
});