define("cropster-app/components/evaluation-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s/T0/tHk",
    "block": "{\"symbols\":[\"analysisResult\",\"i\",\"cuppingResult\",\"@submitSession\"],\"statements\":[[4,\"each\",[[23,[\"currentPageItems\"]]],null,{\"statements\":[[4,\"evaluation-form/sample-delayed\",null,[[\"analysisResult\",\"delay\",\"data-test-sample\"],[[22,1,[]],[22,2,[]],[22,2,[]]]],{\"statements\":[[0,\"    \"],[1,[27,\"evaluation-form/sample\",null,[[\"analysisResult\",\"cuppingResult\",\"isCompact\",\"intersectionObserver\"],[[22,1,[]],[22,3,[]],[23,[\"isCompact\"]],[23,[\"intersectionObserver\"]]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"app-footer\"]],{\"statements\":[[0,\"  \"],[1,[27,\"evaluation-form/footer\",null,[[\"paginatedItems\",\"session\",\"currentPage\",\"gotoPage\",\"submitSession\"],[[23,[\"paginatedItems\"]],[23,[\"session\"]],[23,[\"currentPage\"]],[27,\"action\",[[22,0,[]],\"setPage\"],null],[22,4,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/template.hbs"
    }
  });

  _exports.default = _default;
});