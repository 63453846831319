define("cropster-app/sensorial-session/serializer", ["exports", "cropster-app/serializers/jsonapi-serializer"], function (_exports, _jsonapiSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonapiSerializer.default.extend({
    normalize: function normalize(typeClass, hash) {
      var sensorialQcOrder = Ember.get(hash, 'attributes.sensorialQcOrder');
      var sensorialQcs = Ember.get(hash, 'relationships.sensorialQcs');

      if (sensorialQcOrder && Ember.get(sensorialQcOrder, 'length')) {
        var sensorialQcData = sensorialQcOrder.map(function (id) {
          return {
            id: id,
            type: 'sensorialQcs'
          };
        });
        Ember.set(sensorialQcs, 'data', sensorialQcData);
      } else {
        Ember.set(sensorialQcs, 'data', []);
      }

      return this._super(typeClass, hash);
    }
  });

  _exports.default = _default;
});