define("cropster-app/components/create-sensorial-session/session-lots/table-lots/row/component", ["exports", "ember-cropster-models-table/components/c-table/row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extend the base row to toggle a record whenever the row is clicked.
   *
   * @namespace Component.SessionLots.TableLots
   * @class Row
   * @extends EmberCropsterModelsTable.Component.CTable.Row
   * @public
   */
  var _default = _component.default.extend({
    /**
     * Whenever the row is clicked, toggle the record.
     * Return false to ensure no other events are fired after that (e.g. on the checkbox).
     *
     * @event click
     * @private
     */
    click: function click() {
      var toggleRecord = Ember.get(this, 'actionHash.toggleRecord');
      var lot = Ember.get(this, 'record');
      toggleRecord(lot);
      return false;
    }
  });

  _exports.default = _default;
});