define("cropster-app/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    isLoading: false,
    showLoadingSpinner: function showLoadingSpinner() {
      this.set('isLoading', true);
    },
    hideLoadingSpinner: function hideLoadingSpinner() {
      this.set('isLoading', false);
    }
  });

  _exports.default = _default;
});