define("cropster-app/settings/route", ["exports", "cropster-app/mixins/reset-scroll", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _resetScroll, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    userSession: Ember.inject.service(),
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      return Ember.RSVP.all([this._super.apply(this, arguments), new Ember.RSVP.Promise(function (resolve) {
        return Ember.run.later(_this, function () {
          return resolve();
        }, 100);
      })]);
    }
  });

  _exports.default = _default;
});