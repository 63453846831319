define("cropster-app/mixins/reset-scroll", ["exports", "cropster-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ResetScrollMixin = void 0;
  var ResetScrollMixin = Ember.Mixin.create({
    screen: Ember.inject.service(),
    activate: function activate() {
      this._super.apply(this, arguments);

      window.scrollTo(0, 0);
      var rootElement = Ember.get(_environment.default, 'APP.rootElement');

      if (rootElement) {
        document.querySelector(rootElement).scrollTop = 0;
      }

      var scrollContainer = Ember.get(this, 'screen.rootElement');

      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }
  });
  _exports.ResetScrollMixin = ResetScrollMixin;
  var _default = ResetScrollMixin;
  _exports.default = _default;
});