define("cropster-app/helpers/format-number", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumber = formatNumber;
  _exports.default = void 0;

  function formatNumber(params) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var num = Number(params[0]);

    if (isNaN(num)) {
      num = 0;
    }

    if (Ember.isBlank(hash) || Object.keys(hash).length === 0) {
      hash = {
        maximumFractionDigits: 2,
        useGrouping: false
      };
    } else {
      if (Ember.get(hash, 'forceInt')) {
        num = num | 0;
      }
    }

    var _hash = hash,
        locale = _hash.locale;

    if (Ember.isBlank(locale)) {
      locale = _moment.default.locale();
    } // TODO: toLocalString is broken on safari
    // return num.toLocaleString(locale, hash);


    return "".concat(round(num, hash.maximumFractionDigits));
  }

  function round(value, exp) {
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math.round(value);
    }

    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    } // Shift


    value = value.toString().split('e');
    value = Math.round(+"".concat(value[0], "e").concat(value[1] ? +value[1] + exp : exp)); // Shift back

    value = value.toString().split('e');
    return +"".concat(value[0], "e").concat(value[1] ? +value[1] - exp : -exp);
  }

  var _default = Ember.Helper.helper(formatNumber);

  _exports.default = _default;
});