define("cropster-app/components/groups-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a single group item in the list.
   *
   * @namespace Component.GroupsList
   * @class Item
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['button', 'button--plain', 'item-list__item'],
    classNameBindings: ['isActive:item-list__item--active'],

    /**
     * The group to display.
     *
     * @attribute group
     * @type {Model.Group}
     * @public
     */
    group: null,

    /**
     * The action to call to enter the group.
     *
     * @event enterGroup
     * @param {Model.Group} group
     * @public
     */
    enterGroup: null,

    /**
     * If the group is the active group.
     *
     * @property isActive
     * @type {Boolean}
     * @readonly
     * @protected
     */
    isActive: Ember.computed.alias('group.active'),
    click: function click() {
      var action = Ember.get(this, 'enterGroup');
      var group = Ember.get(this, 'group');
      action(group);
    }
  });

  _exports.default = _default;
});