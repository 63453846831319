define("cropster-app/descriptor/model", ["exports", "ember-data", "ember-indexeddb/mixins/model-bulk-save"], function (_exports, _emberData, _modelBulkSave) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  /**
   * Represents a single sensorial descriptor
   *
   * @class Model.SensorialDescriptor
   * @extends DS.Model
   */

  var _default = Model.extend(_modelBulkSave.default, {
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * The name of the descriptor (e.g., Berry)
     *
     * @attribute name
     * @type {String}
     */
    name: attr('string'),

    /**
     * Group code if the descriptor only exists within a certain group.
     *
     * @attribute groupCode
     * @type {String}
     */
    groupCode: attr('string'),

    /**
     * If the descriptor is positive (default) or negative (=false).
     *
     * @attribute positive
     * @type {Boolean}
     * @default true
     */
    positive: attr('boolean', {
      defaultValue: true
    }),

    /**
     * If the descriptor is dirty, it means that it hasn't been reviewed yet.
     *
     * @attribute isDirty
     * @type {Boolean}
     * @default true
     * @public
     */
    isDirty: attr('boolean', {
      defaultValue: true
    }),

    /**
     * If this is true, this descriptor has been created locally and shouldn't be used anymore.
     *
     * @attribute isLocal
     * @type {Boolean}
     * @default false
     * @public
     */
    isLocal: attr('boolean', {
      defaultValue: true
    }),

    /**
     * How often the descriptor has been used so far.
     * This is used to determine the most used descriptors and put them at the top of the list.
     *
     * @attribute usageCount
     * @type {Number}
     * @public
     */
    usageCount: attr('number', {
      defaultValue: 0
    }),

    /**
     * The assigned sensorial descriptors belonging to this descriptor.
     *
     * @attribute assignedSensorialDescriptors
     * @type {Model.AssignedSensorialDescriptors[]}
     */
    assignedSensorialDescriptors: hasMany('assigned-sensorial-descriptor'),
    // ---------------------------------------------------------------------------------------------------------
    // Properties

    /**
     * the id of  the descriptor
     */
    descriptorId: Ember.computed.alias('id'),
    nameNormalized: Ember.computed('name', function () {
      return Ember.get(this, 'name').toLowerCase().trim();
    })
  });

  _exports.default = _default;
});