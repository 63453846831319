define("cropster-app/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0V5ooIFH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-center\"]],{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Preferences\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"settings-form\",null,[[\"class\",\"resetApplication\",\"reloadGroup\"],[\"margin-top-medium\",[27,\"perform\",[[23,[\"resetApplicationTask\"]]],null],[27,\"perform\",[[23,[\"reloadGroupTask\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"reloadGroupTask\",\"isRunning\"]],[23,[\"resetApplicationTask\",\"isRunning\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"app-footer\"]],{\"statements\":[[0,\"  \"],[1,[21,\"app-footer\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/settings/template.hbs"
    }
  });

  _exports.default = _default;
});