define("cropster-app/serializers/csar", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  var _default = RESTSerializer.extend({
    platform: Ember.inject.service('device/platform'),
    raven: Ember.inject.service(),
    attrs: {
      qualityCupping: {
        serialize: false
      },
      cupsSelected: {
        serialize: false
      },
      sampleCode: {
        serialize: false
      },
      resultItems: {
        serialize: true
      },
      cuppingSession: {
        serialize: false
      },
      takenIntoAccount: {
        serialize: false
      },
      cuppingSheetItem: {
        serialize: true
      },
      assignedSensorialDescriptors: {
        serialize: true
      }
    },
    serialize: function serialize(snapshot) {
      var modelName = snapshot.modelName;

      var json = this._super.apply(this, arguments);

      if (modelName === 'cupping-result') {
        // Add the device UUID to identify who sent a cupping result
        var deviceUuid = Ember.get(this, 'platform.deviceUuid');
        json.mobileDeviceId = deviceUuid;
      }

      return json;
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      if (this.shouldSerializeHasMany(snapshot, key, relationship)) {
        var hasMany = snapshot.hasMany(key);

        if (hasMany !== undefined) {
          // if provided, use the mapping provided by `attrs` in
          // the serializer
          var payloadKey = this._getMappedKey(key, snapshot.type);

          if (payloadKey === key && this.keyForRelationship) {
            payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
          }

          json[payloadKey] = new Array(hasMany.length);

          for (var i = 0; i < hasMany.length; i++) {
            var item = hasMany[i];

            if (key.includes('assignedSensorialDescriptors')) {
              json[payloadKey][i] = this.serializeDescriptor(item);
            } else {
              json[payloadKey][i] = this.serialize(item);
            }
          }
        }
      }
    },
    serializeDescriptor: function serializeDescriptor(item) {
      // HACKY workaround because we're bastardizing ember-data
      var json = item.attributes();
      var sensorialDescriptor = item.belongsTo('sensorialDescriptor');

      if (isNaN(+sensorialDescriptor.id)) {
        // created locally, do not include the local ID
        json.id = null;
      } else {
        // preexisting descriptor, use the descriptor's id
        json.id = sensorialDescriptor.id;
      }

      var sensorialDescriptorJson = sensorialDescriptor.attributes();
      sensorialDescriptorJson.id = sensorialDescriptor.id; // always send the name along, to account for deleted descriptors or similar

      json.name = sensorialDescriptorJson.name; // This _should not_ be possible, but it seems to happen somtimes - this should help us track this down

      if (!json.name) {
        try {
          this.raven.captureMessage('serialized descriptor has no name', {
            extra: {
              assignedSensorialDescriptor: json,
              sensorialDescriptor: sensorialDescriptorJson
            }
          });
        } catch (e) {// this re-throws in dev, so we catch it here (hacky)
        }
      }

      return json;
    },
    keyForRelationship: function keyForRelationship(key) {
      switch (key) {
        case 'cupping-session':
          return 'analysisSession';

        case 'cuppingSheet':
          return 'cuppingSkin';

        case 'cuppingSheetItem':
          return 'cuppingSkinItem';

        default:
          return key;
      }
    }
  });

  _exports.default = _default;
});