define("cropster-app/components/descriptors-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a list of (assigned) sensorial descriptors.
   *
   * @namespace Component
   * @class DescriptorsList
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'ul',

    /**
     * If actions on the descriptor items should be allowed.
     *
     * @attribute allowActions
     * @type {Boolean}
     * @default true
     * @public
     */
    allowActions: true,

    /**
     * The assigned sensorial descriptors to display.
     *
     * @attribute assignedSensorialDescriptors
     * @type {Model.AssignedSensorialDescriptors[]}
     * @public
     */
    assignedSensorialDescriptors: null,

    /**
     * The action to call when an assigned descriptor was updated.
     * Note that it is already updated at this point, this action just informs that an update happend.
     *
     * @event updateAssignedSensorialDescriptor
     * @param {Model.AssignedSensorialDescriptor} assignedSensorialDescriptor
     * @public
     */
    updateAssignedSensorialDescriptor: null,

    /**
     * The action to call to remove an assigned descriptor.
     *
     * @event removeAssignedSensorialDescriptor
     * @param {Model.AssignedSensorialDescriptor} assignedSensorialDescriptor
     * @public
     */
    removeAssignedSensorialDescriptor: null,
    actions: {
      updateAssignedSensorialDescriptor: function updateAssignedSensorialDescriptor() {
        var action = Ember.get(this, 'updateAssignedSensorialDescriptor');
        return action.apply(void 0, arguments);
      },
      removeAssignedSensorialDescriptor: function removeAssignedSensorialDescriptor() {
        var action = Ember.get(this, 'removeAssignedSensorialDescriptor');
        return action.apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});