define("cropster-app/evaluation/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    evaluation: Ember.inject.service(),
    submitSessionTask: Ember.computed.alias('evaluation.submitSessionTask')
  });

  _exports.default = _default;
});