define("cropster-app/router", ["exports", "cropster-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('groups');
    this.route('dashboard', function () {
      this.route('completed');
    });
    this.route('evaluation', {
      path: 'evaluation/:id'
    }, function () {
      this.route('overview');
    });
    this.route('settings');
    this.route('review', {
      path: 'review/:id'
    });
    this.route('error-no-indexed-db');
    this.route('session-sync-details', {
      path: 'session-sync-details/:id'
    });
    this.route('groups-reload');
    this.route('groups-error');
    this.route('update-required', {
      path: 'update-required/:id'
    });
    this.route('create-session', function () {
      this.route('setup-table');
    });
    this.route('create-session-error');
  });
  var _default = Router;
  _exports.default = _default;
});