define("cropster-app/error-no-indexed-db/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DpQy3KOn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"margin-top-medium align-center padding-left-small padding-right-small\"],[9],[0,\"\\n\\n  \"],[7,\"img\"],[11,\"src\",\"images/cropster-cup-image.png\"],[12,\"alt\",[27,\"t\",[\"Cropster Cup\"],null]],[11,\"class\",\"content-image\"],[9],[10],[0,\"\\n\\n  \"],[7,\"h3\"],[11,\"class\",\"content-title\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Sorry, but it appears the browser you are using is not compatible with Cropster Cup.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"wrapper width-xxlarge\"],[9],[0,\"\\n\"],[4,\"info-message\",null,[[\"type\",\"icon\"],[\"error\",\"error\"]],{\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"platform\",\"isIos\"]],[27,\"lte\",[[23,[\"platform\",\"version\"]],9],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[0,\"\\n          Due to issues with iOS 9, the app from the App Store is not supported anymore.\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[9],[0,\"\\n          Please visit\\n          \"],[7,\"a\"],[11,\"href\",\"https://cup.cropster.com\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[11,\"class\",\"link--secondary color-error\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n            cup.cropster.com\\n          \"],[3,\"action\",[[22,0,[]],\"openExternalUrl\",\"https://cup.cropster.com\"]],[10],[0,\"\\n          in your browser, where you can continue to use all the features of Cropster Cup you're used to.\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"Please try to use Chrome or Safari, without Private Browsing/Incognito Mode enabled.\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/error-no-indexed-db/template.hbs"
    }
  });

  _exports.default = _default;
});