define("cropster-app/services/enums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service is mainly used so that ember-l10n can parse the strings for enums correctly.
   *
   * @namespace Service
   * @class Enums
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    l10n: Ember.inject.service(),
    sheetItemNames: Ember.computed(function () {
      var l10n = Ember.get(this, 'l10n');
      return [l10n.t('Aroma'), l10n.t('Flavor'), l10n.t('Dry'), l10n.t('Break'), l10n.t('Aftertaste'), l10n.t('Acidity'), l10n.t('Intensity'), l10n.t('Body'), l10n.t('Balance'), l10n.t('Uniformity'), l10n.t('Clean Cup'), l10n.t('Sweetness'), l10n.t('Overall'), l10n.t('Defects'), l10n.t('Roast'), l10n.t('Fragrance / Aroma'), l10n.t('Taste'), l10n.t('General descriptors')];
    }),
    sampleTypes: Ember.computed(function () {
      var l10n = Ember.get(this, 'l10n');
      return [l10n.t('Arrival'), l10n.t('Type sample'), l10n.t('Offer'), l10n.t('Spot offer'), l10n.t('Pre-shipment'), l10n.t('Evaluation')];
    })
  });

  _exports.default = _default;
});