define("cropster-app/services/ember-cordova/events", ["exports", "ember-cordova-events/services/ember-cordova/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _events.default;
    }
  });
});