define("cropster-app/cupping-sheet-item/model", ["exports", "ember-data", "cropster-app/utils/range", "cropster-app/utils/formula-parser"], function (_exports, _emberData, _range, _formulaParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDefaultInputType = getDefaultInputType;
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  function isNumeric(val) {
    return !isNaN(parseFloat(val)) && isFinite(val);
  }

  function getDefaultInputType(stepCount) {
    if (stepCount > 50) {
      return 'TEXT';
    }

    if (stepCount > 8 && stepCount < 25) {
      return 'POPUP_SLIDER';
    }

    if (stepCount <= 3) {
      return 'BUTTONS';
    }

    return 'POPUP_SLIDER_SMALL';
  }
  /**
   * A section of a cupping sheet.
   *
   * @class Model.CuppingSheetItem
   * @extends DS.Model
   */


  var _default = Model.extend({
    l10n: Ember.inject.service(),

    /**
     * The name of the sheet item / category.
     *
     * @attribute name
     * @type {String}
     */
    name: attr('string'),

    /**
     * If this sheetItem belongs to a group, this contains the group's name.
     *
     * @attribute grouping
     * @type {String}
     */
    grouping: attr('string'),

    /**
     * Formula that specifies how quality and intensity values contribute to the calculated score.
     * If formula is null then the quality value will be the calculated result.
     *
     * @attribute formula
     * @type {String}
     */
    formula: attr('string'),

    /**
     * The step in which the quality can be entered.
     *
     * @attribute qualityStep
     * @type {Number}
     * @public
     */
    qualityStep: attr('number'),

    /**
     * The minimum quality value.
     *
     * @attribute qualityMin
     * @type {Number}
     * @public
     */
    qualityMin: attr('number'),

    /**
     * The maximum quality value.
     *
     * @attribute qualityMax
     * @type {Number}
     * @public
     */
    qualityMax: attr('number'),

    /**
     * The step in which the intensity can be entered.
     *
     * @attribute intensityStep
     * @type {Number}
     * @public
     */
    intensityStep: attr('number'),

    /**
     * The minimum intensity value.
     *
     * @attribute intensityMin
     * @type {Number}
     * @public
     */
    intensityMin: attr('number'),

    /**
     * The maximum intensity value.
     *
     * @attribute intensityMax
     * @type {Number}
     * @public
     */
    intensityMax: attr('number'),

    /**
     * Note should help the cupper understand the sheet item, e.g. how the score is calculated.
     *
     * @attribute note
     * @type {String}
     */
    note: attr('string'),

    /**
     * The input type to use for this sheet item.
     * If this is not set, use the default input type for the given number of steps.
     *
     * @attribute inputType
     * @type {String}
     * @public
     */
    inputType: attr('string'),

    /**
     * The flavors that are allowed to choose for this category.
     * This is just an array of strings (or undefined).
     *
     * @attribute allowedFlavors
     * @type {String[]}
     * @public
     */
    allowedFlavors: attr('array'),

    /**
     * False if no descriptors shall be assigned to the sheet item.
     *
     * @attribute allowDescriptors
     * @type {Boolean}
     */
    allowDescriptors: attr('boolean', {
      defaultValue: true
    }),

    /**
     * The cupping sheet this item belongs to.
     *
     * @attribute cuppingSheet
     * @type {Model.CuppingSheet}
     */
    cuppingSheet: belongsTo('cupping-sheet'),

    /**
     * The cupping items belonging to this cupping sheet item.
     *
     * @attribute qualityCuppingItems
     * @type {Model.QualityCuppingItems}
     */
    qualityCuppingItems: hasMany('quality-cupping-item'),
    // ---------------------------------------------------------------------------------------------------------
    // Properties

    /**
     * If this cupping sheet item is about defects.
     *
     * @property isDefectCategory
     * @type {Boolean}
     * @readOnly
     */
    normalizedName: Ember.computed('name', function () {
      var name = this.get('name');

      if (Ember.isEmpty(name)) {
        return '';
      }

      return name.trim().toLowerCase();
    }),

    /**
     * The intensity label to display on the form.
     * If this item is grouped, it will be the name of the item,
     * otherwise it will be "Intensity"
     */
    displayIntensityLabel: Ember.computed('name', 'grouping', function () {
      var grouping = this.get('grouping');

      if (Ember.isBlank(grouping)) {
        return this.get('l10n').t('Intensity');
      }

      return this.get('name');
    }),
    isDefectCategory: Ember.computed.equal('inputType', 'DEFECTS'),
    isCheckboxCategory: Ember.computed.equal('inputType', 'TOGGLE_BUTTONS'),
    isQualityZero: Ember.computed('formula', function () {
      var formula = this.get('formula');

      if (Ember.isEmpty(formula)) {
        return false;
      }

      return formula.replace(/\s/g, '').indexOf('Q*0') > -1;
    }),
    qualityInputType: Ember.computed('qualityRange.[]', 'inputType', function () {
      var stepCount = Ember.get(this, 'qualityRange.length');
      var forcedInputType = Ember.get(this, 'inputType');

      if (forcedInputType) {
        return forcedInputType;
      }

      return getDefaultInputType(stepCount);
    }),
    intensityInputType: Ember.computed('hasQuality', 'intensityRange.[]', 'inputType', function () {
      // If the category also has a quality, always use the small slider
      if (Ember.get(this, 'hasQuality')) {
        return 'POPUP_SLIDER_SMALL';
      } // Else, if it is intensity only, use the given inputType, if set


      var stepCount = Ember.get(this, 'intensityRange.length');
      var forcedInputType = Ember.get(this, 'inputType');

      if (forcedInputType) {
        return forcedInputType;
      }

      return getDefaultInputType(stepCount);
    }),
    restrictedSensorialDescriptors: Ember.computed('allowedFlavors.[]', function () {
      var allowedFlavors = Ember.get(this, 'allowedFlavors');
      return !Ember.isEmpty(allowedFlavors) ? allowedFlavors : null;
    }),

    /**
     * If the sheet item has quality that counts to the final score.
     */
    hasCountingQuality: Ember.computed('hasQuality', 'isQualityZero', function () {
      return Ember.get(this, 'hasQuality') && !Ember.get(this, 'isQualityZero');
    }),

    /**
     * Some sheets use a single CuppingItem for general descriptors not attached
     * to any specific category. This property is true if this CuppingItem is one of those.
     */
    isSheetMainDescriptors: Ember.computed('normalizedName', function () {
      var name = this.get('normalizedName');
      return name === 'descriptors' || name === 'general descriptors' || name === 'descriptor';
    }),

    /**
     * Does this cupping item use intensity?
     *
     * @property hasIntensity
     * @type {Boolean}
     * @readOnly
     */
    hasIntensity: Ember.computed.notEmpty('intensityRange'),

    /**
     * Does this cupping item use quality?
     *
     * @property hasQuality
     * @type {Boolean}
     * @readOnly
     */
    hasQuality: Ember.computed.notEmpty('qualityRange'),

    /**
     * Does this cupping item use a formula?
     *
     * @property hasFormula
     * @type {Boolean}
     * @readOnly
     */
    hasFormula: Ember.computed.notEmpty('formula'),
    parsedFormula: Ember.computed('formula', function () {
      if (this.get('hasFormula')) {
        return _formulaParser.default.parse(this.get('formula'));
      }

      return null;
    }),
    defaultQualityValue: Ember.computed('qualityRange.[]', 'parsedFormula', 'isCheckboxCategory', 'isDefectCategory', 'hasFormula', function () {
      var qualityRange = this.qualityRange,
          isDefectCategory = this.isDefectCategory,
          isCheckboxCategory = this.isCheckboxCategory,
          hasFormula = this.hasFormula,
          parsedFormula = this.parsedFormula; // If there is a formula, check if there is a default specified

      if (hasFormula && parsedFormula.defaults.hasOwnProperty('Q') && isNumeric(+parsedFormula.defaults.Q)) {
        return +parsedFormula.defaults.Q;
      } // For defects, default to the min. value


      if (isDefectCategory && !Ember.isEmpty(qualityRange)) {
        return (0, _range.rangeMinMax)(qualityRange).min;
      } // For checkboxes, default to the max. value


      if (isCheckboxCategory && !Ember.isEmpty(qualityRange)) {
        return (0, _range.rangeMinMax)(qualityRange).max;
      } // By default, use null


      return null;
    }),
    defaultIntensityValue: Ember.computed('intensityRange', 'parsedFormula', 'hasFormula', 'isDefectCategory', 'cuppingSheet.isOlive', function () {
      var hasFormula = this.hasFormula,
          parsedFormula = this.parsedFormula,
          isDefectCategory = this.isDefectCategory,
          intensityRange = this.intensityRange;
      var isOlive = Ember.get(this, 'cuppingSheet.isOlive'); // If there is no intensity, return null

      if (Ember.isEmpty(intensityRange)) {
        return null;
      } // If there is a formula, check if there is a default specified


      if (hasFormula && parsedFormula.defaults.hasOwnProperty('I') && isNumeric(+parsedFormula.defaults.I)) {
        return +parsedFormula.defaults.I;
      } // Special case olive oil: Start at null


      if (isOlive) {
        return null;
      } // For defects, default to the min. value


      if (isDefectCategory) {
        return (0, _range.rangeMinMax)(intensityRange).min;
      } // By default, use the middle value of the range


      return (0, _range.rangeMiddle)(intensityRange);
    }),
    qualityMinMax: Ember.computed('qualityRange.{lastObject,firstObject}', 'formula', function () {
      return this._minMax(this.get('qualityRange'), /(Q)/g);
    }),
    intensityMinMax: Ember.computed('intensityRange.{lastObject,firstObject}', 'formula', function () {
      return this._minMax(this.get('intensityRange'), /(I)/g);
    }),
    _minMax: function _minMax(array, itemRe) {
      if (Ember.isEmpty(array)) {
        return null;
      }

      return (0, _range.rangeMinMax)(array, this.get('formula'), itemRe);
    },

    /**
     * Range of allowed values for quality. Empty means quality cannot be set.
     *
     * @property qualityRange
     * @type {Number[]}
     * @readOnly
     * @public
     */
    qualityRange: Ember.computed('qualityMin', 'qualityMax', 'qualityStep', function () {
      var min = Ember.get(this, 'qualityMin');
      var max = Ember.get(this, 'qualityMax');
      var step = Ember.get(this, 'qualityStep');

      if (!step) {
        return null;
      }

      var lastVal = min;
      var range = [min];

      while (lastVal < max) {
        lastVal = (lastVal + step).toFixed(2) * 1;
        range.push(lastVal);
      }

      return range;
    }),

    /**
     * Range of allowed values for intensity. Empty means intensity cannot be set.
     *
     * @property intensityRange
     * @type {Number[]}
     * @readOnly
     * @public
     */
    intensityRange: Ember.computed('intensityMin', 'intensityMax', 'intensityStep', function () {
      var min = Ember.get(this, 'intensityMin');
      var max = Ember.get(this, 'intensityMax');
      var step = Ember.get(this, 'intensityStep');

      if (!step) {
        return null;
      }

      var lastVal = min;
      var range = [min];

      while (lastVal < max) {
        lastVal = (lastVal + step).toFixed(2) * 1;
        range.push(lastVal);
      }

      return range;
    })
  });

  _exports.default = _default;
});