define("cropster-app/utils/is-testing", ["exports", "cropster-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isTesting = isTesting;
  _exports.default = void 0;

  function isTesting() {
    return _environment.default.environment === 'test';
  }

  var _default = isTesting;
  _exports.default = _default;
});