define("cropster-app/services/ember-cordova/splash", ["exports", "ember-cordova-splash/services/ember-cordova/splash"], function (_exports, _splash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _splash.default;
    }
  });
});