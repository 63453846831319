define("cropster-app/components/app-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7FeTzlF/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"footer-bar__inner\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showGroups\"]]],null,{\"statements\":[[4,\"link-to\",[\"groups\"],[[\"class\",\"data-test-footer-link-groups\"],[\"footer-bar__link\",true]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",[\"groups-tab\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"footer-bar__link__label\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Groups\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showSessions\"]]],null,{\"statements\":[[4,\"link-to\",[\"dashboard\"],[[\"class\",\"data-test-footer-link-sessions\"],[\"footer-bar__link\",true]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",[\"sessions-tab\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"footer-bar__link__label\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Sessions\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showPreferences\"]]],null,{\"statements\":[[4,\"link-to\",[\"settings\"],[[\"class\",\"data-test-footer-link-preferences\"],[\"footer-bar__link\",true]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",[\"settings-tab\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"footer-bar__link__label\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"Preferences\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/app-footer/template.hbs"
    }
  });

  _exports.default = _default;
});