define("cropster-app/services/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service can be used to show dialog messages.
   *
   * The provided functions return a promise that resolves (if confirmed) or reject (if canceled).
   *
   * @namespace Service
   * @class Dialog
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    l10n: Ember.inject.service(),

    /**
     * The default confirmation text.
     * Replace this e.g. with a localized version if required.
     * This can be overwritten with the 'confirmButtonText' option.
     *
     * @attribute defaultConfirmButtonText
     * @type {String}
     * @default 'OK'
     * @public
     */
    defaultConfirmButtonText: Ember.computed(function () {
      var l10n = Ember.get(this, 'l10n');
      return l10n.t('OK');
    }),

    /**
     * The default cancel text.
     * Replace this e.g. with a localized version if required.
     * This can be overwritten with the 'cancelButtonText' option.
     * If this is overwritten as null, the button will not be displayed.
     *
     * @attribute defaultCancelButtonText
     * @type {String}
     * @default 'Cancel'
     * @public
     */
    defaultCancelButtonText: Ember.computed(function () {
      var l10n = Ember.get(this, 'l10n');
      return l10n.t('Cancel');
    }),

    /**
     * Show an info message.
     *
     * @method info
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {Object} options Additional options for the dialog.
     * @returns {*|RSVP.Promise}
     */
    info: function info(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.show('alert', message, title, options);
    },

    /**
     * Show a confirm dialog.
     * This returns a promise that resolves if confirmed, else rejects.
     *
     * @method confirm
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {Object} options Additional options for the dialog.
     * @return {RSVP.Promise}
     * @public
     */
    confirm: function confirm(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.show('confirm', message, title, options);
    },

    /**
     * Shows an alert message, with just a confirm button.
     * This returns a promise that resolves if confirmed, else rejects.
     *
     * @method alert
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {{ modalClass: undefined, confirmButtonText: undefined}} options Additional options for the dialog.
     * @return {RSVP.Promise}
     * @public
     */
    alert: function alert(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.show('alert', message, title, options);
    },

    /**
     * Show a confirm dialog, with some additional 'warning' styling.
     * This returns a promise that resolves if confirmed, else rejects.
     *
     * @method confirmWarn
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {{confirmButtonText: undefined, cancelButtonText: undefined }} options Additional options for the dialog.
     * @return {RSVP.Promise}
     * @public
     */
    confirmWarn: function confirmWarn(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.show('confirm', message, title, options);
    },

    /**
     * This is an alias for confirmWarn.
     *
     * @method confirmDelete
     * @return {RSVP.Promise}
     * @public
     */
    confirmDelete: function confirmDelete() {
      return this.confirmWarn.apply(this, arguments);
    },

    /**
     * Show a dialog.
     * This is used under the hood by e.g. confirm, alert, ...
     *
     * @method show
     * @param {String} type The type of message. 'alert' or 'confirm'.
     * @param {String} text The message body.
     * @param {String} title An optional title for the dialog.
     * @param {{ confirmButtonText: undefined, cancelButtonText: undefined }} options Additional options for the dialog.
     * @return {RSVP.Promise}
     * @public
     */
    show: function show(type, text, title) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var confirmButtonText = options.confirmButtonText,
          cancelButtonText = options.cancelButtonText;

      if (!confirmButtonText) {
        confirmButtonText = Ember.get(this, 'defaultConfirmButtonText');
      }

      if (!cancelButtonText) {
        cancelButtonText = Ember.get(this, 'defaultCancelButtonText');
      }

      if (type === 'alert') {
        return this._alert(title, text, confirmButtonText);
      }

      return this._confirm(title, text, confirmButtonText, cancelButtonText);
    },
    _confirm: function _confirm(title, text, confirmButton, cancelButton) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this._canUseNativeNotifications()) {
          // fallback for browser
          return window.confirm(text) ? resolve() : reject();
        }

        var internalCallback = function internalCallback(btnIndex) {
          // callback function to handle the return value from cordova-plugin-dialogs
          if (btnIndex === 2) {
            resolve();
          } else {
            reject();
          }
        };

        window.navigator.notification.confirm(text, internalCallback, title, [cancelButton, confirmButton]);
      });
    },
    _alert: function _alert(title, text, confirmButton) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        if (!_this2._canUseNativeNotifications()) {
          // fallback for browser
          window.alert(text);
          return resolve();
        }

        window.navigator.notification.alert(text, function () {
          return resolve();
        }, title, confirmButton);
      });
    },
    _canUseNativeNotifications: function _canUseNativeNotifications() {
      // cordova dialogs plugin
      // see: https://github.com/apache/cordova-plugin-dialogs
      return window.navigator.notification;
    }
  });

  _exports.default = _default;
});