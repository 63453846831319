define("cropster-app/components/evaluation-form/sample/group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a group of cupping result items.
   *
   * @namespace Component.EvaluationForm.Sample
   * @class Group
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    /**
     * The cupping result item that the group belongs to.
     *
     * @attribute cuppingResult
     * @type {Model.CuppingResult}
     * @public
     */
    cuppingResult: null,

    /**
     * The cupping result items that are in this group.
     * This can be one or more items.
     *
     * @attribute cuppingResultItems
     * @type {Model.QualityCuppingItem[]}
     * @public
     */
    cuppingResultItems: null,

    /**
     * The action to call to recalculate the total score.
     *
     * @event recalculateTotalScore
     * @public
     */
    recalculateTotalScore: null,

    /**
     * The action to create a new sensorial descriptor.
     *
     * @event createSensorialDescriptor
     * @param {String} sensorialDescriptorName
     * @public
     */
    createSensorialDescriptor: null,

    /**
     * The action to remove a sensorial descriptor from a sensorial result item.
     *
     * @event removeAssignedSensorialDescriptor
     * @param {Model.AssignedSensorialDescriptor} assignedSensorialDescriptor
     * @param {Model.QualityCuppingItem} cuppingResultItem
     * @public
     */
    removeAssignedSensorialDescriptor: null,

    /**
     * The action to add a sensorial descriptor from a sensorial result item.
     *
     * @event addAssignedSensorialDescriptor
     * @param {Model.SensorialDescriptor} sensorialDescriptor
     * @param {Model.QualityCuppingItem} cuppingResultItem
     * @public
     */
    addSensorialDescriptor: null,

    /**
     * The action to update a sensorial descriptor on a sensorial result item.
     *
     * @event updateAssignedSensorialDescriptor
     * @param {Model.AssignedSensorialDescriptor} assignedSensorialDescriptor
     * @param {Model.QualityCuppingItem} cuppingResultItem
     * @public
     */
    updateAssignedSensorialDescriptor: null
  });

  _exports.default = _default;
});