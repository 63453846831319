define("cropster-app/components/evaluation-form/sample/sample-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4g57vaAf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"data-test-evaluation-form-sample-label\",\"\"],[9],[0,\"\\n  \"],[1,[23,[\"analysisResult\",\"label\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"isBlind\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"sample-info__sample-label__extended\"],[11,\"data-test-evaluation-form-sample-label-extended\",\"\"],[12,\"title\",[23,[\"analysisResult\",\"lotName\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"sample-info__sample-label__extended__text\"],[9],[0,\"\\n      \"],[1,[23,[\"analysisResult\",\"lotName\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/sample/sample-label/template.hbs"
    }
  });

  _exports.default = _default;
});