define("cropster-app/cupping-sheet/serializer", ["exports", "cropster-app/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    normalizeCSAR: function normalizeCSAR(model, hash, prop) {
      hash = this._super(model, hash, prop);
      hash.product = Ember.get(hash, 'product.name') || 'coffee';
      hash = this._normalizeBasic(model, hash);
      var sheetItems = hash.data.attributes.sheetItems;
      delete hash.data.attributes.sheetItems;
      var included = [];
      var relationshipsItems = [];
      var sheetSerializer = this.store.serializerFor('cupping-sheet-item');
      sheetItems.forEach(function (item) {
        var itemJsonApi = sheetSerializer.deserializeFromSheet(hash.data.id, item);
        included.push(itemJsonApi);
        relationshipsItems.push({
          type: itemJsonApi.data.type,
          id: itemJsonApi.data.id
        });
      });
      hash.data.relationships = {};
      hash.data.relationships.sheetItems = {
        data: relationshipsItems
      };
      hash.included = included.map(function (item) {
        return Ember.assign(item.data, {
          relationships: item.relationships
        });
      }); // hash.included = included;

      return hash;
    }
  });

  _exports.default = _default;
});