define("cropster-app/serializers/jsonapi-serializer", ["exports", "ember-data/serializers/json-api", "ember-inflector"], function (_exports, _jsonApi, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.camelize(attr);
    },
    keyForRelationship: function keyForRelationship(name) {
      return Ember.String.camelize(name);
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.camelize(modelName));
    },
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var data = Ember.get(payload, 'data');

      if (data && Ember.typeOf(data) === 'array') {
        if (Ember.get(data, 'length')) {
          Ember.set(payload, 'data', data.firstObject);
        } else {
          Ember.set(payload, 'data', null);
        }
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    extractRelationship: function extractRelationship() {
      var json = this._super.apply(this, arguments);

      if (Ember.get(json, 'data.id') || Ember.typeOf(Ember.get(json, 'data')) === 'array') {
        delete json.links;
      }

      return json;
    },
    extractErrors: function extractErrors(store, typeClass, payload, id) {
      // Manually add source if it isn't present
      var errors = Ember.get(payload, 'errors');

      if (errors) {
        errors.forEach(function (error) {
          if (!Ember.get(error, 'source')) {
            Ember.set(error, 'source', {
              pointer: 'data'
            });
          }
        });
      }

      return this._super(store, typeClass, payload, id);
    }
  });

  _exports.default = _default;
});