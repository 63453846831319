define("cropster-app/components/evaluation-form/inputs/slider/component", ["exports", "cropster-app/components/evaluation-form/inputs/base-input/component", "cropster-app/utils/math"], function (_exports, _component, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An input which shows as a range slider.
   * Note that this input will exceed the default styling width, and therefore lead to a slightly different rendering.
   *
   * @namespace Component.EvaluationForm.Inputs
   * @class Slider
   * @extends Component.EvaluationForm.Inputs.BaseInput
   * @public
   */
  var _default = _component.default.extend({
    /**
     * The current temp value of the slider.
     * This is used to display the current slider position while sliding, without actually updating the value all the time.
     *
     * @property tempValue
     * @type {Number}
     * @protected
     */
    sliderValue: null,

    /**
     * If the slider should be shown.
     *
     * @property showSlider
     * @type {Boolean}
     * @default false
     * @protected
     */
    showSlider: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._initSliderValue();
    },
    actions: {
      slideStart: function slideStart() {
        // When touching the slider, automatically remove focus from any open element
        var focusedElement = document.querySelector(':focus');

        if (focusedElement) {
          focusedElement.blur();
        }
      },
      updateValue: function updateValue(val) {
        Ember.set(this, 'sliderValue', this._roundVal(val));
      },
      slideEnd: function slideEnd() {
        var _this = this;

        // Somehow, this is often called multiple times
        // So we debounce it by 100ms to avoid calling the action too often
        Ember.run.debounce(this, this._sendAction, 100);
        return new Ember.RSVP.Promise(function (resolve) {
          Ember.run.debounce(_this, resolve, 100);
        });
      },
      forceShowSlider: function forceShowSlider() {
        Ember.set(this, 'showSlider', true);
        Ember.set(this, 'sliderValue', Ember.get(this, 'defaultValue') || Ember.get(this, 'min'));

        this._sendAction();
      }
    },

    /**
     * Round a value to a reasonable value.
     *
     * @method _roundVal
     * @param {Number} val
     * @return {Number}
     * @private
     */
    _roundVal: function _roundVal(val) {
      return (0, _math.roundWithMargin)(val);
    },

    /**
     * Init the slider value.
     * The slider always needs a value, it cannot work with null/undefined.
     *
     * @method _initSliderValue
     * @private
     */
    _initSliderValue: function _initSliderValue() {
      var value = Ember.get(this, 'value');

      if (Ember.isNone(value)) {
        var defaultValue = Ember.get(this, 'defaultValue');

        if (Ember.isNone(defaultValue)) {
          defaultValue = Ember.get(this, 'min') || 0;
        }

        value = defaultValue;
      } else {
        Ember.set(this, 'showSlider', true);
      }

      Ember.set(this, 'sliderValue', value);
    },

    /**
     * Actually send the update action.
     *
     * @method _sendAction
     * @private
     */
    _sendAction: function _sendAction() {
      var action = Ember.get(this, 'update');
      var value = Ember.get(this, 'sliderValue');
      action(value);
    }
  });

  _exports.default = _default;
});