define("cropster-app/components/evaluation-form/sample/sample-label/component", ["exports", "cropster-app/mixins/outside-click-listener"], function (_exports, _outsideClickListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display the sample label.
   *
   * @namespace Component.EvaluationForm.Sample
   * @class SampleLabel
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend(_outsideClickListener.default, {
    tagName: 'h3',
    classNames: ['sample-info__sample-label'],
    classNameBindings: ['isBlind::sample-info__sample-label--non-blind', 'showFullName:sample-info__sample-label--show'],

    /**
     * The analysis result to show the label for.
     *
     * @attribute analysisResult
     * @type {Model.AnalysisResult}
     * @public
     */
    analysisResult: null,

    /**
     * If the full lot name should be shown.
     *
     * @property showFullName
     * @type {Boolean}
     * @default false
     * @protected
     */
    showFullName: false,

    /**
     * If the session is blind.
     * This affects the label.
     *
     * @property isBlind
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isBlind: Ember.computed.alias('analysisResult.session.isBlind'),
    click: function click() {
      this.toggleProperty('showFullName');

      if (Ember.get(this, 'showFullName')) {
        this._attachOutsideClickListener();
      }

      return false;
    },

    /**
     * Attach a listener to close the full name when clicking outside.
     *
     * @method _attachOutsideClickListener
     * @private
     */
    _attachOutsideClickListener: function _attachOutsideClickListener() {
      var _this = this;

      this.addOutsideClickListener(function () {
        return Ember.set(_this, 'showFullName', false);
      }, {
        removeOnClick: true
      });
    }
  });

  _exports.default = _default;
});