define("cropster-app/permission/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A single permission.
   * A permission belongs to a groupMembership, via which it belongs to a group & a user.
   *
   * @namespace Model
   * @class Permission
   * @extends DS.Model
   * @public
   */
  var _default = _model.default.extend({
    /**
     * The name of the permission.
     *
     * @attribute name
     * @type {String}
     * @public
     */
    name: (0, _attr.default)('string'),

    /**
     * The groupMembership this permission belongs to.
     * The corresponding user/group can be get via the membership.
     *
     * @attribute groupMembership
     * @type {Model.GroupMembership}
     * @public
     */
    groupMembership: (0, _relationships.belongsTo)('group-membership')
  });

  _exports.default = _default;
});