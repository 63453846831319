define("cropster-app/sensorial-session/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cropster-common/utils/promise-queue", "ember-cropster-common/utils/promise"], function (_exports, _model, _attr, _relationships, _promiseQueue, _promise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A sensorial cupping session.
   *
   * @namespace Model
   * @class CQualitySession
   * @extends DS.Model
   * @public
   */
  var _default = _model.default.extend({
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * The name of the session.
     *
     * @attribute name
     * @type {String}
     * @public
     */
    name: (0, _attr.default)('string'),

    /**
     * A more readable ID of the session, e.g. "QS-123".
     *
     * @attribute idTag
     * @type {String}
     * @public
     */
    idTag: (0, _attr.default)('string'),

    /**
     * The state of the session. One of 'DRAFT', 'OPEN', 'CLOSED'
     *
     * @attribute state
     * @type {String}
     * @public
     */
    state: (0, _attr.default)('enum-session-state'),

    /**
     * DateTime when the session is scheduled.
     *
     * @attribute scheduleDate
     * @type {Date}
     * @public
     */
    scheduleDate: (0, _attr.default)('timestamp'),

    /**
     * The code type to use for this sessions' qcs.
     *
     * @attribute codeType
     * @type {'ALPHABETICAL'|'NUMERICAL'|'THREE_LETTER'}
     * @public
     */
    codeType: (0, _attr.default)('string', {
      defaultValue: 'ALPHABETICAL'
    }),

    /**
     * The lab this session belongs to.
     *
     * @attribute lab
     * @type {String}
     * @public
     */
    lab: (0, _attr.default)('string'),

    /**
     * An array of evaluator names in this session.
     *
     * @attribute evaluators
     * @type {String[]}
     * @public
     */
    evaluators: (0, _attr.default)('array'),

    /**
     * If the session is blind.
     * In this case, the lot names etc. may never be shown.
     *
     * @attribute isBlind
     * @type {Boolean}
     * @default true
     * @public
     */
    isBlind: (0, _attr.default)('boolean', {
      defaultValue: true
    }),

    /**
     * An array of QC ids to enforce the correct order on the server.
     *
     * @attribute sensorialQcOrder
     * @type {String[]}
     * @public
     */
    sensorialQcOrder: (0, _attr.default)('array'),

    /**
     * The group to which this session belongs.
     *
     * @attribute group
     * @type {Model.Group}
     * @public
     */
    group: (0, _relationships.belongsTo)('group'),

    /**
     * Id of the cupping sheet which is used for this session.
     *
     * @attribute sensorialSheet
     * @type {Model.SensorialSheet}
     * @public
     */
    sensorialSheet: (0, _relationships.belongsTo)('sensorial-sheet'),

    /**
     * List of AnalysisResults. For each sample there is one AnalysisResult.
     *
     * @attribute sensorialQcs
     * @type {Model.SensorialQc[]}
     * @public
     */
    sensorialQcs: (0, _relationships.hasMany)('sensorial-qc'),
    // ---------------------------------------------------------------------------------------------------------
    // Properties

    /**
     * Set the sample codes.
     * Note that this won't work for THREE_LETTERS
     *
     * @method initQcCodes
     * @public
     */
    initQcCodes: function initQcCodes() {
      var sensorialQcs = Ember.get(this, 'sensorialQcs');
      var codeType = Ember.get(this, 'codeType'); // We cannot know this code because it will be generated randomly on the server
      // So just use --- for all of them

      if (codeType === 'THREE_LETTER') {
        sensorialQcs.forEach(function (sensorialQc) {
          Ember.set(sensorialQc, 'sampleCode', '---');
        });
        return;
      } // Numericals are just incrementing numbers


      if (codeType === 'NUMERICAL') {
        sensorialQcs.forEach(function (sensorialQc, i) {
          Ember.set(sensorialQc, 'sampleCode', i + 1);
        });
        return;
      } // Alphabetical is A, B, ... Z, AA, ...


      var list = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      sensorialQcs.forEach(function (sensorialQc, i) {
        // If it is to big, add A in front of it
        var listLength = list.length;
        var offset = Math.floor(i / listLength);
        var code = '';

        if (offset) {
          code = list[offset - 1];
        }

        code += list[i - offset * listLength];
        Ember.set(sensorialQc, 'sampleCode', code);
      });
    },

    /**
     * Initialise the Order of QCs.
     * This sets the sensorialQcOrder proeprty according to the sensorialQcs.
     *
     * @method initQcOrder
     * @public
     */
    initQcOrder: function initQcOrder(sensorialQcs) {
      if (Ember.typeOf(sensorialQcs) === 'undefined') {
        sensorialQcs = Ember.get(this, 'sensorialQcs');
      }

      var sensorialQcOrder = sensorialQcs.mapBy('id').compact();
      Ember.set(this, 'sensorialQcOrder', sensorialQcOrder);
    },

    /**
     * Save this session and all associated QCs.
     *
     * @method saveWithSensorialQcs
     * @return {RSVP.Promise}
     * @public
     */
    saveWithSensorialQcs: function saveWithSensorialQcs() {
      var _this = this;

      var sensorialSession = this; // Re-set this

      Ember.set(sensorialSession, '_sensorialQcCategory', undefined);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        sensorialSession.save().then(function () {
          return _this._saveSensorialQcs();
        }).then(function (sensorialQcs) {
          Ember.set(_this, 'sensorialQcs', sensorialQcs);
          return _this._initSessionSamples();
        }).then(function () {
          return _this._reloadSensorialQcs();
        }).then(function () {
          return resolve(_this);
        }).catch(reject);
      });
    },

    /**
     * Save all sensorial qcs of this session.
     *
     * @method _saveSensorialQcs
     * @private
     */
    _saveSensorialQcs: function _saveSensorialQcs() {
      var sensorialSession = this;
      var sensorialSheet = Ember.get(sensorialSession, 'sensorialSheet');
      var scheduleDate = Ember.get(sensorialSession, 'scheduleDate');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _promise.resolveIfPromise)(Ember.get(sensorialSession, 'sensorialQcs')).then(function (sensorialQcs) {
          // The following fields always need to be in sync with the session:
          // lab, evaluationDate, sensorialSheet
          // All QCs must have the same Lab & sheet as the Session
          sensorialQcs.forEach(function (sensorialQc) {
            Ember.set(sensorialQc, 'lab', Ember.get(sensorialSession, 'lab'));
            Ember.set(sensorialQc, 'sensorialSheet', sensorialSheet);
            Ember.set(sensorialQc, 'evaluationDate', scheduleDate);
          });
          return (0, _promiseQueue.default)(sensorialQcs, function (sensorialQc) {
            return sensorialQc.save();
          });
        }).then(resolve).catch(reject);
      });
    },

    /**
     * Initialise the order & codes on all qcs of this session.
     *
     * @method _initSessionSamples
     * @private
     */
    _initSessionSamples: function _initSessionSamples() {
      var sensorialSession = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _promise.resolveIfPromise)(Ember.get(sensorialSession, 'sensorialQcs')).then(function (sensorialQcs) {
          if (Ember.get(sensorialSession, 'codeType') !== 'THREE_LETTER') {
            sensorialSession.initQcCodes();
          }

          sensorialSession.initQcOrder(sensorialQcs);
          return sensorialSession.save();
        }).then(resolve).catch(reject);
      });
    },

    /**
     * Reload all sensorial qcs of this session.
     * @method _reloadSensorialQcs
     * @private
     */
    _reloadSensorialQcs: function _reloadSensorialQcs() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _promise.resolveIfPromise)(Ember.get(_this2, 'sensorialQcs')).then(function (sensorialQcs) {
          return (0, _promiseQueue.default)(sensorialQcs, function (sensorialQc) {
            return sensorialQc.reload();
          });
        }).then(resolve).catch(reject);
      });
    }
  });

  _exports.default = _default;
});