define("cropster-app/components/create-sensorial-session/session-lots/table-lots/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The server-paginated table of lots.
   *
   * @namespace CropsterCsarQuality.Component
   * @class TableLots
   * @extends Ember.Component
   * @uses EmberCsarCore.Mixin.TableColumns
   * @uses EmberCsarCore.Mixin.ServerPaginatedTable
   * @public
   */
  var _default = Ember.Component.extend({
    classNames: ['session-lots__table'],
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * The lots that have been selected.
     *
     * @attribute selectedLots
     * @type {Model.Lot[]}
     * @public
     */
    selectedLots: null,

    /**
     * The action to call to toggle a lot.
     *
     * @event toggleRecord
     * @param {Model.Lot} lot
     * @public
     */
    toggleRecord: null,

    /**
     * The actual data to display.
     *
     * @attribute data
     * @type {Model.Lot[]}
     * @public
     */
    data: Ember.computed('searchTerm', function () {
      var store = Ember.get(this, 'store');
      var groupCode = Ember.get(this, 'userSession.activeGroup.code');
      var searchTerm = Ember.get(this, 'searchTerm');
      var query = {
        group: groupCode,
        page: 1,
        pageSize: 25
      };

      if (searchTerm) {
        query.searchTerm = searchTerm;
      }

      return store.query('lot', query);
    }),

    /**
     * The table ID to use.
     *
     * @property tableId
     * @type {String}
     * @protected
     */
    tableId: 'create-session-table-lots',

    /**
     * The search term from the input gets saved here, and after a few ms, it is put copied over into
     * `searchTerm` by the `_updateSearchTerm` method.
     * This ensures that the `data` property is not recalculated all the time.
     *
     * @property tmpSearchTerm
     * @type {String}
     * @protected
     */
    tmpSearchTerm: null,

    /**
     * The actual search term to use.
     *
     * @property searchTerm
     * @type {String}
     * @protected
     */
    searchTerm: null,

    /**
     * The columns for this table.
     *
     * @property columns
     * @type {Object[]}
     * @readOnly
     * @private
     */
    columns: Ember.computed(function () {
      var l10n = Ember.get(this, 'l10n');
      return [{
        propertyName: 'idTag',
        title: l10n.t('ID-tag'),
        className: 'no-wrap',
        mayBeHidden: false
      }, {
        propertyName: 'name',
        title: l10n.t('Name')
      }, {
        propertyName: 'sampleType',
        title: l10n.t('Sample type'),
        component: 'create-sensorial-session/session-lots/table-lots/columns/sample-type'
      }];
    }),
    actions: {
      updateSearch: function updateSearch(val) {
        Ember.set(this, 'tmpSearchTerm', val);
        Ember.run.debounce(this, this._updateSearchTerm, 500);
      }
    },

    /**
     * Copy the tmpSearchTerm into the searchTerm.
     *
     * @method _updateSearchTerm
     * @private
     */
    _updateSearchTerm: function _updateSearchTerm() {
      var searchTerm = Ember.get(this, 'tmpSearchTerm');
      Ember.set(this, 'searchTerm', searchTerm);
    }
  });

  _exports.default = _default;
});