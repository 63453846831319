define("cropster-app/application/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  var _default = JSONAPISerializer.extend({
    isNewSerializerAPI: true,
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.camelize(attr);
    },
    keyForRelationship: function keyForRelationship(key) {
      return Ember.String.camelize(key);
    },
    normalizeCSAR: function normalizeCSAR(model, hash) {
      if (this.get('normalizeCallback')) {
        this.get('normalizeCallback')(hash);
      }

      return hash;
    },
    _normalizeBasic: function _normalizeBasic(model, hash) {
      return {
        data: {
          id: "".concat(hash.id),
          type: model.modelName,
          attributes: hash
        }
      };
    },
    normalizeCSARArrayPayload: function normalizeCSARArrayPayload(normalizeCallback, store, primaryModelClass, payload) {
      var _this = this;

      this.set('normalizeCallback', normalizeCallback);
      var documentHash = {
        data: null,
        included: []
      };
      documentHash.data = payload.map(function (item) {
        var _normalize2 = _this.normalizeCSAR(primaryModelClass, item);

        var data = _normalize2.data,
            included = _normalize2.included;

        if (included) {
          var _documentHash$included;

          (_documentHash$included = documentHash.included).push.apply(_documentHash$included, included);
        }

        return data;
      });
      return documentHash;
    }
  });

  _exports.default = _default;
});