define("cropster-app/components/evaluation-form/sample-pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9MtitwiP",
    "block": "{\"symbols\":[\"page\",\"label\",\"@gotoPage\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pagination__inner\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"pages\",\"length\"]],1],null]],null,{\"statements\":[[4,\"each\",[[23,[\"pages\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[12,\"data-test-sample-pagination-item\",[22,1,[\"pageNumber\"]]],[12,\"class\",[28,[\"button pagination-button\\n          \",[27,\"if\",[[27,\"not-eq\",[[22,1,[\"pageNumber\"]],[23,[\"currentPage\"]]],null],\"button--light\"],null],\"\\n          \",[27,\"if\",[[27,\"and\",[[23,[\"isCompact\"]],[27,\"gt\",[[22,1,[\"labels\",\"length\"]],4],null]],null],\"is-very-compact\"],null],\"\\n          \",[27,\"if\",[[27,\"and\",[[23,[\"isCompact\"]],[27,\"lte\",[[22,1,[\"labels\",\"length\"]],4],null]],null],\"is-compact\"],null]]]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"labels\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"sample-label\"],[9],[0,\"\\n            \"],[1,[22,2,[]],false],[4,\"if\",[[27,\"not-eq\",[[22,2,[]],[22,1,[\"labels\",\"lastObject\"]]],null]],null,{\"statements\":[[0,\",\"]],\"parameters\":[]},null],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],[22,3,[]],[22,1,[\"pageNumber\"]]],null]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/sample-pagination/template.hbs"
    }
  });

  _exports.default = _default;
});