define("cropster-app/components/evaluation-form/inputs/special/descriptor-select-compact/component", ["exports", "cropster-app/components/evaluation-form/inputs/special/descriptor-select/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a compact descriptor select, which shows the selection process in a modal.
   *
   * @namespace Component.EvaluationForm.Inputs.Special
   * @class DescriptorSelectCompact
   * @extends Component.EvaluationForm.Inputs.Special.DescriptorSelect
   * @public
   */
  var _default = _component.default.extend({});

  _exports.default = _default;
});