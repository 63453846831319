define("cropster-app/analysis-result/serializer", ["exports", "cropster-app/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    deserializeFromSession: function deserializeFromSession(sessionId, resultPayload) {
      var data = {
        type: 'analysis-result',
        id: "".concat(resultPayload.id)
      }; // var modelClass = this.store.modelFor('analysis-result');
      // let hash = this._normalizeBasic(modelClass, resultPayload);

      data.attributes = resultPayload;
      data.attributes.lotName = resultPayload.lot.name || '';
      data.attributes.lotIdTag = resultPayload.lot.idTag || '';
      data.attributes.lot = resultPayload.lot.id;

      if (resultPayload.schedule) {
        data.attributes.cuppingDate = resultPayload.schedule.dateTime;
        delete resultPayload.schedule;
      }

      data.relationships = {};
      data.relationships['cupping-session'] = {
        data: {
          type: 'cupping-session',
          id: sessionId
        }
      };
      return data;
    }
  });

  _exports.default = _default;
});