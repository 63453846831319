define("cropster-app/utils/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundWithMargin = roundWithMargin;

  function roundWithMargin(val) {
    var positions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;

    if (!val) {
      return 0;
    }

    val = val.toFixed(positions) * 1;

    if (Math.abs(val - Math.round(val)) < 0.01) {
      val = Math.round(val);
    }

    return val;
  }
});