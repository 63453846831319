define("cropster-app/dashboard/index/route", ["exports", "cropster-app/mixins/reset-scroll", "moment"], function (_exports, _resetScroll, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The route handler for the cupping dashboard
   *
   * @class Route.Dashboard
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_resetScroll.default, {
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    networkState: Ember.inject.service(),
    model: function model() {
      var model = this.modelFor('dashboard');
      var sessions = model.sessions; // Filter out old/closed ones

      sessions = this._filterSessions(sessions);
      return sessions;
    },
    _filterSessions: function _filterSessions(sessions) {
      var currentState = 'OPEN';
      var today = (0, _moment.default)().startOf('day'); // today at 00:00 in user's timezone
      // filter sessions without results

      sessions = sessions.filterBy('analysisResults.length'); // filter submitted & synced sessions

      sessions = sessions.filterBy('isSynced', false); // filter out old

      return sessions.filter(function (session) {
        var date = Ember.get(session, 'date');

        if (Ember.typeOf(date) === 'number') {
          date = (0, _moment.default)(date);
        }

        var dateDiff = date.clone().startOf('day').diff(today, 'days');
        return Ember.get(session, 'sessionState') === currentState && dateDiff >= -30;
      });
    }
  });

  _exports.default = _default;
});