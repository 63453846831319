define("cropster-app/components/create-sensorial-session/setup-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3YYxFTe3",
    "block": "{\"symbols\":[\"sensorialQc\"],\"statements\":[[7,\"table\"],[11,\"class\",\"table--plain table--plain--spacious\"],[9],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"sensorialQcs\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[11,\"data-test-session-setup-table-sample\",\"\"],[9],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"bold\"],[11,\"data-test-session-setup-table-sample-lot-id-tag\",\"\"],[9],[0,\"\\n          \"],[1,[22,1,[\"lot\",\"idTag\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"bold\"],[11,\"data-test-session-setup-table-sample-lot-name\",\"\"],[9],[0,\"\\n          \"],[1,[22,1,[\"lot\",\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[1,[27,\"svg-icon\",[\"arrow-thin-down\"],[[\"class\"],[\"transform-rotate-270 padding-left-small padding-right-small\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"label-preview\"],[11,\"data-test-session-setup-table-sample-label\",\"\"],[9],[0,\"\\n            \"],[1,[22,1,[\"sampleCode\"]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/create-sensorial-session/setup-table/template.hbs"
    }
  });

  _exports.default = _default;
});