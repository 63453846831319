define("cropster-app/components/evaluation-form/inputs/popup-slider/background/component", ["exports", "cropster-app/components/evaluation-form/inputs/popup-slider/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display the background for the popup-slider, which is basically a scale of the input values.
   *
   * @namespace Component.EvaluationForm.Inputs.PopupSlider
   * @class Background
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'svg',
    classNameBindings: ['hasManySteps:has-many-steps'],
    attributeBindings: ['width', 'height', 'viewBox'],

    /**
     * The array of step items to display.
     *
     * @attribute steps
     * @type {Number[]}
     * @public
     */
    steps: null,

    /**
     * The width for the slider.
     * This is overridden by the `_calculateWidth` method, which is called on `didInsertElement`
     * in order to make it extend to the full width - SVG needs a concrete width for some things to work.
     *
     * @property width
     * @type {Number}
     * @protected
     */
    width: 96,

    /**
     * The height to use for the SVG.
     *
     * @property height
     * @type {Number}
     * @default 30
     * @protected
     */
    height: 30,

    /**
     * The view box attribtue to use for the SVG.
     *
     * @property viewBox
     * @type {String}
     * @readOnly
     * @protected
     */
    viewBox: Ember.computed('width', 'height', function () {
      var width = Ember.get(this, 'width');
      var height = Ember.get(this, 'height');
      return "0 0 ".concat(width, " ").concat(height);
    }),

    /**
     * If this has many steps or not.
     * If it has, add a class - which is used to make the pins thinner.
     *
     * @property hasManySteps
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasManySteps: Ember.computed.gt('steps.length', 30),

    /**
     * The actual parsed steps to display.
     * Each step is an object like this:
     *
     * ```
     * {
     *   step: 1,
     *   size: 0,
     *   offsetPercentage: '12.25%'
     * }
     * ```
     *
     * Where `step` is the actual value, `size` describes how big the pin should be (0=big, 1=medium, 2=small),
     * and `offsetPercentage` is used to position the pin accordingly on the x-axis.
     *
     * @property parsedSteps
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    parsedSteps: Ember.computed('steps.[]', 'width', function () {
      var steps = Ember.get(this, 'steps') || [];
      var stepsCount = Ember.get(steps, 'length');
      return steps.map(function (step, i) {
        var stepOffset = i % 4; // The size of the pin.
        // 0 is the biggest size, the higher the number the smaller
        // Currently, we have 0, 1 & 2

        var size = 0;

        switch (stepOffset) {
          case 2:
            size = 1;
            break;

          case 1:
          case 3:
            size = 2;
            break;
        }

        var offsetPercentage = (0, _component.calculateStepOffset)(i, stepsCount);
        var offsetPercentageString = "".concat(offsetPercentage, "%");
        return {
          step: step,
          size: size,
          offsetPercentage: Ember.String.htmlSafe(offsetPercentageString)
        };
      });
    }),
    didInsertElement: function didInsertElement() {
      this._calculateWidth();
    },

    /**
     * Calculate the width of the container element, and set this element's `width` accordingly.
     *
     * @method _calculateWidth
     * @private
     */
    _calculateWidth: function _calculateWidth() {
      var el = this.element;
      var parent = el.parentElement;
      var width = parent.offsetWidth;
      Ember.set(this, 'width', width);
    }
  });

  _exports.default = _default;
});