define("cropster-app/components/session-review/item/category/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ohQWwnRW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"category-list__score\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"is-not-none\",[[23,[\"intensity\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text--secondary text--light\"],[11,\"data-test-review-sample-intensity\",\"\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"cuppingSheetItem\",\"hasQuality\"]]],null,{\"statements\":[[0,\"        (\"],[1,[21,\"intensity\"],false],[0,\")\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[21,\"intensity\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"is-not-none\",[[23,[\"quality\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"margin-left-xsmall text--bold\"],[11,\"data-test-review-sample-quality\",\"\"],[9],[0,\"\\n      \"],[1,[27,\"format-number\",[[23,[\"quality\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"span\"],[11,\"data-test-review-category-title\",\"\"],[9],[0,\"\\n  \"],[1,[27,\"t-var\",[[23,[\"cuppingResultItem\",\"cuppingSheetItem\",\"name\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"assignedSensorialDescriptors\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"descriptors-list\",null,[[\"data-test-review-sample-descriptors\",\"assignedSensorialDescriptors\",\"allowActions\",\"class\"],[true,[23,[\"assignedSensorialDescriptors\"]],false,\"margin-top-xsmall\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/session-review/item/category/template.hbs"
    }
  });

  _exports.default = _default;
});