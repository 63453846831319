define("cropster-app/components/evaluation-form/inputs/buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wBW0LpVF",
    "block": "{\"symbols\":[\"val\",\"@update\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"title title--centered \",[21,\"titleClass\"]]]],[11,\"data-test-evaluation-input-title\",\"\"],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[4,\"each\",[[23,[\"range\"]]],null,{\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"button \",[27,\"if\",[[27,\"eq\",[[22,1,[]],[23,[\"value\"]]],null],\"button--grey\",\"button--light\"],null]]]],[11,\"data-test-evaluation-input-button\",\"\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[22,1,[]],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[22,2,[]],[22,1,[]]]],[10]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/buttons/template.hbs"
    }
  });

  _exports.default = _default;
});