define("cropster-app/components/session-review/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays an overview of a session
   *
   * @class Component.SessionOverview
   */
  var _default = Ember.Component.extend({
    cuppingResults: null
  });

  _exports.default = _default;
});