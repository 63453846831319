define("cropster-app/sensorial-sheet/model", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A specific cupping sheet for a session / cupping.
   *
   * @namespace Model
   * @class SensorialSheet
   * @extends DS.Model
   * @public
   */
  var _default = _model.default.extend({
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * The name of the sheet.
     *
     * @attribute name
     * @type {String}
     * @public
     */
    name: (0, _attr.default)('string'),

    /**
     * If an evaluator may override the final score.
     *
     * @attribute isEvaluatorScoreAllowed
     * @type {Boolean}
     * @public
     */
    isEvaluatorScoreAllowed: (0, _attr.default)('boolean'),

    /**
     * What the maximum possible score is.
     *
     * @attribute maxPossibleScore
     * @type {Number}
     * @public
     */
    maxPossibleScore: (0, _attr.default)('number', {
      defaultValue: 100
    }),

    /**
     * The minimum possible score.
     * For now, this is equal to the deltaScore, or 0.
     *
     * @attribute minPossibleScore
     * @type {Number}
     * @public
     */
    minPossibleScore: (0, _attr.default)('number', {
      defaultValue: 0
    }),

    /**
     * If the sheet has roast levels.
     *
     * @attribute hasRoastLevels
     * @type {Boolean}
     * @public
     */
    hasRoastLevels: (0, _attr.default)('boolean'),

    /**
     *  Score that will be added to the result.
     *
     *  @attribute deltaScore
     *  @type {Number}
     *  @public
     */
    deltaScore: (0, _attr.default)('number'),

    /**
     * The step of the roast levels.
     *
     * @attribute roastLevelsStep
     * @type {Number}
     * @public
     */
    roastLevelsStep: (0, _attr.default)('number'),

    /**
     * The minimum roast level.
     *
     * @attribute roastLevelsMin
     * @type {Number}
     * @public
     */
    roastLevelsMin: (0, _attr.default)('number'),

    /**
     * The maximum roast level.
     *
     * @attribute roastLevelsMax
     * @type {Number}
     * @public
     */
    roastLevelsMax: (0, _attr.default)('number'),

    /**
     * For which product (coffee, cocoa, ...) this sheet is.
     *
     * @attribute product
     * @type {String}
     * @public
     */
    product: (0, _attr.default)('string', {
      defaultValue: 'coffee'
    }),
    // ---------------------------------------------------------------------------------------------------------
    // Properties

    /**
     * If this is the default sheet.
     * Currently, this is the SCAA 2015 (Cup Ready) sheet.
     * It should be on top in lists.
     *
     * @property isDefaultSheet
     * @type {Boolean}
     * @readOnly
     * @public
     */
    isDefaultSheet: Ember.computed.equal('id', '0zM')
  });

  _exports.default = _default;
});