define("cropster-app/components/evaluation-form/inputs/buttons/component", ["exports", "cropster-app/components/evaluation-form/inputs/base-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Radio buttons to select a value.
   *
   * @namespace Component.EvaluationForm.Inputs
   * @class Buttons
   * @extends Component.EvaluationForm.Inputs.BaseInput
   * @public
   */
  var _default = _component.default.extend({});

  _exports.default = _default;
});