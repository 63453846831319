define("cropster-app/components/app-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The main app footer.
   * This is the main pagination when outside of a session.
   *
   * @namespace Component
   * @class AppFooter
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['footer-bar'],
    userSession: Ember.inject.service(),

    /**
     * If the link to the sessions list should be displayed.
     *
     * @attribute showSessions
     * @type {Boolean}
     * @default true
     * @public
     */
    showSessions: true,

    /**
     * If the link to the groups list should be displayed.
     *
     * @attribute showGroups
     * @type {Boolean}
     * @default true
     * @public
     */
    showGroups: true,

    /**
     * If the link to the preferences should be displayed.
     *
     * @attribute showPreferences
     * @type {Boolean}
     * @default true
     * @public
     */
    showPreferences: true,
    init: function init() {
      this._super.apply(this, arguments);

      this._checkHasMultipleGroups();
    },

    /**
     * Check if the user has multiple groups.
     * If not, do not show the "groups" menu point.
     *
     * @method _checkHasMultipleGroups
     * @private
     */
    _checkHasMultipleGroups: function _checkHasMultipleGroups() {
      var hasOnlySingleGroup = Ember.get(this, 'userSession.hasOnlySingleGroup');

      if (hasOnlySingleGroup) {
        Ember.set(this, 'showGroups', false);
      }
    }
  });

  _exports.default = _default;
});