define("cropster-app/groups/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    queryParams: ['redirectIfActiveGroup', 'loadError', 'reload'],
    redirectIfActiveGroup: false,
    loadError: false,
    reload: false,
    actions: {
      onEnterGroup: function onEnterGroup() {
        this.send.apply(this, ['enterGroup'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});