define("cropster-app/user/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A user, which belongs to one or multiple groups.
   *
   * @namespace Model
   * @class User
   * @extends DS.Model
   * @public
   */
  var _default = _model.default.extend({
    /**
     * The username of the user.
     * This is what they use to login.
     * It is normally an email, but not always!
     *
     * @attribute username
     * @type {String}
     * @public
     */
    username: (0, _attr.default)('string'),

    /**
     * The name of the user.
     * This is the full name, e.g. John Doe.
     *
     * @attribute name
     * @type {String}
     * @public
     */
    name: (0, _attr.default)('string'),

    /**
     * The primary email address of the user.
     * This might match the username, or not.
     *
     * @attribute email
     * @type {String}
     * @public
     */
    email: (0, _attr.default)('string'),

    /**
     * The timezone of the user, e.g. America/Los_Angeles.
     *
     * @attribute timezone
     * @type {String}
     * @public
     */
    timezone: (0, _attr.default)('string'),

    /**
     * The locale of the user, e.g. de-AT.
     *
     * @attribute locale
     * @type {String}
     * @public
     */
    locale: (0, _attr.default)('string'),

    /**
     * If the user is active or not.
     *
     * @attribute active
     * @type {Boolean}
     * @public
     */
    active: (0, _attr.default)('boolean'),

    /**
     * The role of this user.
     *
     * @attribute role
     * @type {'REGISTERED'|'ADMINISTRATOR'}
     * @public
     */
    role: (0, _attr.default)('string', {
      defaultValue: 'REGISTERED'
    }),

    /**
     * The groupMemberships of the user.
     *
     * @attribute groupMemberships
     * @type {Model.GroupMembership[]}
     * @public
     */
    groupMemberships: (0, _relationships.hasMany)('group-membership'),

    /**
     * If the user is an administrator.
     *
     * @property isAdministrator
     * @type {Boolean}
     * @readOnly
     * @public
     */
    isAdministrator: Ember.computed.equal('role', 'ADMINISTRATOR')
  });

  _exports.default = _default;
});