define("cropster-app/group/serializer", ["exports", "cropster-app/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    normalizeCSAR: function normalizeCSAR(model, hash, prop) {
      hash = this._super(model, hash, prop);
      hash.id = hash.code;
      hash.type = 'group';
      return this._normalizeBasic(model, hash);
    }
  });

  _exports.default = _default;
});