define("cropster-app/components/evaluation-form/inputs/popup-slider/background/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gCK5UCp9",
    "block": "{\"symbols\":[\"step\",\"step\"],\"statements\":[[7,\"g\"],[11,\"class\",\"scale-pin__container\"],[9],[0,\"\\n\\n  \"],[7,\"g\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"parsedSteps\"]]],null,{\"statements\":[[0,\"      \"],[7,\"line\"],[12,\"x1\",[22,2,[\"offsetPercentage\"]]],[12,\"x2\",[22,2,[\"offsetPercentage\"]]],[11,\"y1\",\"0\"],[11,\"y2\",\"18\"],[12,\"class\",[28,[\"scale-pin scale-pin--\",[22,2,[\"size\"]]]]],[12,\"data-step-value\",[22,2,[\"step\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"g\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"parsedSteps\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[\"size\"]],0],null]],null,{\"statements\":[[0,\"        \"],[7,\"g\"],[11,\"class\",\"scale-pin__label\"],[9],[0,\"\\n          \"],[7,\"text\"],[12,\"x\",[22,1,[\"offsetPercentage\"]]],[12,\"y\",[21,\"height\"]],[11,\"class\",\"scale-pin__label__text\"],[9],[0,\"\\n            \"],[1,[22,1,[\"step\"]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/popup-slider/background/template.hbs"
    }
  });

  _exports.default = _default;
});