define("cropster-app/property/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A proprty (=setting).
   * A property can be either general (e.g. Maintenance mode) - no group & no user.
   * Or belonging to a group.
   * Or belonging to a user.
   * Or belonging to a group & a user. (--> settings per group & user)
   *
   * @namespace Model
   * @class Property
   * @extends DS.Model
   * @public
   */
  var _default = _model.default.extend({
    /**
     * The name of the property.
     *
     * @attribute name
     * @type {String}
     * @public
     */
    name: (0, _attr.default)('string'),

    /**
     * The value of the property.
     * This can be a string, number or array.
     *
     * @attribute value
     * @type {Mixed}
     * @public
     */
    value: (0, _attr.default)(),
    // eslint-disable-line ember/no-empty-attrs

    /**
     * The user this property belongs to.
     *
     * @attribute user
     * @type {Model.User}
     * @public
     */
    user: (0, _relationships.belongsTo)('user'),

    /**
     * The group this property belongs to.
     *
     * @attribute group
     * @type {Model.Group}
     * @public
     */
    group: (0, _relationships.belongsTo)('group')
  });

  _exports.default = _default;
});