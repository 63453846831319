define("cropster-app/create-session/setup-table/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(sensorialSession) {
      if (Ember.get(sensorialSession, 'isNew')) {
        this.transitionTo('create-session');
      }
    }
  });

  _exports.default = _default;
});