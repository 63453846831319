define("cropster-app/dashboard/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    createSession: Ember.inject.service(),
    networkState: Ember.inject.service(),
    screen: Ember.inject.service(),
    queryParams: {
      reloadFromServer: 'reload',
      openSessionIdTag: 'openSession'
    },
    reloadFromServer: false,
    openSessionIdTag: null,
    activeGroup: Ember.computed.alias('model.activeGroup'),
    showOpenSessions: Ember.computed.equal('currentState', 'OPEN'),
    isOnline: Ember.computed.alias('networkState.isOnline'),
    isOffline: Ember.computed.alias('networkState.isOffline'),
    canCreateSessions: Ember.computed.alias('createSession.canCreateSessions'),
    hasOnlySingleGroup: Ember.computed.alias('userSession.hasOnlySingleGroup')
  });

  _exports.default = _default;
});