define("cropster-app/dashboard/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "cropster-app/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The route handler for the cupping dashboard
   *
   * @class Route.Dashbaord
   * @extends Ember.Route
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    log: Ember.inject.service(),
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    networkState: Ember.inject.service(),
    dataSync: Ember.inject.service(),
    createSession: Ember.inject.service(),
    flavorManager: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);

      var activeGroup = Ember.get(this, 'userSession.activeGroup');

      if (Ember.isEmpty(activeGroup)) {
        var error = this.get('l10n').t('Please select a group');
        this.toast.error(error);
        this.transitionTo('groups', {
          queryParams: {
            loadError: false,
            redirectIfActiveGroup: true
          }
        });
        return;
      }

      var groupCode = Ember.get(activeGroup, 'id');
      var dataSync = Ember.get(this, 'dataSync');
      var shouldReload = Ember.get(transition, 'to.queryParams.reload');

      if (shouldReload === 'true' || shouldReload === true) {
        shouldReload = true;
      } else {
        shouldReload = false;
      } // First, try to sync the currently open sessions
      // Then, try to load sessions from the API
      // Resolve even if one of the requests fails, also with a min. 100ms wait time to force loading spinner


      return new Ember.RSVP.Promise(function (resolve) {
        return Ember.run.later(_this, function () {
          var loadGroupData = function loadGroupData() {
            // Do not reload data every time
            if (!shouldReload) {
              resolve();
              return;
            }

            var promise = dataSync.loadGroupData(groupCode);
            promise.then(function () {
              // Also enforce refresh of descriptors
              Ember.get(_this, 'flavorManager.allSensorialDescriptors').then(resolve, resolve);
            }, resolve);
          };

          dataSync.syncAllSessions().then(loadGroupData, loadGroupData);
        }, 100);
      });
    },
    model: function model() {
      var store = Ember.get(this, 'store');
      var activeGroup = this.get('userSession').get('activeGroup');
      var groupCode = Ember.get(activeGroup, 'id');
      var model = Ember.RSVP.hash({
        sessions: store.query('cupping-session', {
          groupCode: groupCode,
          sessionState: 'OPEN'
        }),
        activeGroup: activeGroup
      });
      return model;
    },
    afterModel: function afterModel(model, transition) {
      var dashboard = this.dashboard; // If openSessionIdTag is set, try to open the session with this id tag right away

      var openSessionIdTag = Ember.get(transition, 'to.queryParams.openSession');

      if (openSessionIdTag) {
        var sessions = model.sessions;
        var session = sessions.findBy('idTag', openSessionIdTag);

        if (session) {
          dashboard.openSessionTask.perform(session);
        } else {
          var l10n = Ember.get(this, 'l10n');
          var message = l10n.t('You cannot enter the session. This could be because there are already to many open sessions. Please close some sessions.');

          if (Ember.get(this, 'networkState.isOffline')) {
            message = l10n.t('The sessions could not be entered. It appears like you are offline, so it may not have been downloaded yet.');
          }

          this.get('toast').warning(message);
        }
      } // Load permissions etc., but don't wait on it


      var createSession = Ember.get(this, 'createSession');
      createSession.setup().catch(function () {// Do nothing
      });
    },
    resetController: function resetController(controller) {
      controller.set('reloadFromServer', false);
      controller.set('openSessionIdTag', null);
    },
    actions: {
      showOfflineNotice: function showOfflineNotice() {
        var toast = Ember.get(this, 'toast');
        var l10n = Ember.get(this, 'l10n');
        toast.warning(l10n.t('You need to be online to create a session.'));
      },
      refresh: function refresh() {
        var _this2 = this;

        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        controller.set('reloadFromServer', true);
        Ember.run.next(function () {
          _this2.refresh();
        });
      }
    }
  });

  _exports.default = _default;
});