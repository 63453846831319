define("cropster-app/services/user-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service that manages information related to the user's current app session (as opposed to cupping session)
   *
   * Optionally, clients can subscribe to events to be notified when certain properties change
   * @namespace Service
   * @class Service.UserSession
   * @extends Ember.Service
   * @fires Service.UserSession#group-changed
   * @fires Service.UserSession#user-changed
   */
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    raven: Ember.inject.service(),
    dataSync: Ember.inject.service(),
    userSettings: Ember.inject.service(),

    /**
     * The active group. Is null if no active group.
     *
     * @property activeGroup
     * @type Model.Group|null
     * @read-only
     */
    activeGroup: null,
    isForcedInvalidation: false,
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),

    /**
     * The current user auth data. Is null if not logged in.
     *
     * @property currentUser
     * @type Object|null
     * @readOnly
     */
    currentUser: Ember.computed.alias('session.data.authenticated'),
    username: Ember.computed.alias('session.data.authenticated.username'),
    name: Ember.computed.alias('session.data.authenticated.name'),
    nameEmail: Ember.computed.alias('session.data.authenticated.nameEmail'),
    timezone: Ember.computed.alias('session.data.authenticated.timezone'),
    locale: Ember.computed.alias('session.data.authenticated.locale'),
    groupCode: Ember.computed.alias('activeGroup.code'),
    hasOnlySingleGroup: Ember.computed(function () {
      var userSettings = Ember.get(this, 'userSettings');
      var groupsCount = userSettings.getItem('groups-count');
      return groupsCount && groupsCount === 1;
    }),

    /**
     * Sets the active group. Returns a promise that resolves with the group after it is set to active.
     * @param group
     * @returns {Ember.RSVP.Promise}
     */
    setActiveGroup: function setActiveGroup(group) {
      var _this = this;

      var shouldReloadAll = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (!this.get('isAuthenticated')) {
        return Ember.RSVP.reject(new Error('Cannot set active group. User is not authenticated.'));
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var store = Ember.get(_this, 'store');
        var userSettings = Ember.get(_this, 'userSettings');
        var dataSync = Ember.get(_this, 'dataSync');
        var groupId = group ? Ember.get(group, 'id') : null;
        var groupCode = group ? Ember.get(group, 'code') : null; // If the group hasn't change, do nothing

        var previousGroupCode = Ember.get(_this, 'activeGroup.code');

        if (previousGroupCode && previousGroupCode === groupCode) {
          // Re-fetch data from API
          dataSync.loadAdditionalGroupData(groupCode, shouldReloadAll).finally(function () {
            resolve(group);
          });
          return;
        } // Clear the store


        store.unloadAll('user');
        store.unloadAll('sensorial-session');
        store.unloadAll('sensorial-qc');
        store.unloadAll('lot');
        store.unloadAll('sensorial-sheet');
        store.unloadAll('property');
        store.unloadAll('permission');
        store.unloadAll('group-membership'); // Save the active group in LS

        userSettings.setItem('active-group', groupId); // Fetch the active group and return it

        var promise = groupId ? store.findRecord('group', groupId) : Ember.RSVP.Promise.resolve(null);
        return promise.then(function () {
          if (Ember.isEmpty(group)) {
            Ember.set(_this, 'activeGroup', null);
          } else {
            Ember.set(_this, 'activeGroup', group);
          }

          var newGroup = _this.get('activeGroup');

          var raven = Ember.get(_this, 'raven');
          raven.setupUserContext(); // Try to fetch group data from API

          dataSync.loadAdditionalGroupData(groupCode, shouldReloadAll).finally(function () {
            resolve(newGroup);
          });
        }, function (reason) {
          /* eslint-disable no-console */
          console.log('services/user-session/setActiveGroup: failed to set active group for group ', groupCode);
          console.log(reason);
          /* eslint-enable no-console */

          reject(reason);
        });
      });
    },
    setActiveGroupFromStore: function setActiveGroupFromStore() {
      var _this2 = this;

      var store = Ember.get(this, 'store');
      var userSettings = Ember.get(this, 'userSettings');
      var raven = Ember.get(this, 'raven'); // Save the active group in LS

      var groupId = userSettings.getItem('active-group');
      return new Ember.RSVP.Promise(function (resolve) {
        if (!groupId) {
          Ember.set(_this2, 'activeGroup', null);
          raven.setupUserContext();
          return resolve(null);
        }

        store.findRecord('group', groupId).then(function (group) {
          if (!group) {
            Ember.set(_this2, 'activeGroup', null);
            raven.setupUserContext();
            return resolve(null);
          }

          Ember.set(_this2, 'activeGroup', group);
          raven.setupUserContext();
          resolve(group);
        }, function () {
          Ember.set(_this2, 'activeGroup', null);
          raven.setupUserContext();
          resolve(null);
        });
      });
    },

    /**
     * Force a logout, discarding saved credentials
     * @returns {*}
     */
    forceInvalidateSession: function forceInvalidateSession() {
      Ember.set(this, 'isForcedInvalidation', true);
      return this.invalidateSession();
    },

    /**
     * Invalidate the user's session.
     * @returns {*|Ember.RSVP.Promise}
     */
    invalidateSession: function invalidateSession() {
      return this.get('session').invalidate();
    }
  });

  _exports.default = _default;
});