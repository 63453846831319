define("cropster-app/components/groups-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a list of groups, which can be entered.
   *
   * @namespace Component
   * @class GroupsList
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['item-list'],

    /**
     * The groups to display.
     *
     * @attribute groups
     * @type {Model.Group[]}
     * @public
     */
    groups: null,

    /**
     * The action to call to enter the group.
     *
     * @event enterGroup
     * @param {Model.Group} group
     * @public
     */
    enterGroup: null,

    /**
     * The groups, sorted by their name.
     *
     * @property sortedGroups
     * @type {Model.Group[]}
     * @readOnly
     * @protected
     */
    groupsSort: ['name:asc'],
    // eslint-disable-line
    sortedGroups: Ember.computed.sort('groups', 'groupsSort')
  });

  _exports.default = _default;
});