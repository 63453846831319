define("cropster-app/utils/serialize-query-params-traditional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serializeQueryParamsTraditional = serializeQueryParamsTraditional;
  _exports.default = void 0;

  // This should ONLY transform an array property into the legacy format from $.params(obj, true)
  // E.g.: arr=1&arr=2&arr=3
  // Instead of the new/better format: arr[]=1&arr[]=2&arr[]=3
  // No deep nesting or other further options are supported
  function serializeQueryParamsTraditional(obj) {
    return Object.keys(obj).map(function (propertyName) {
      var values = obj[propertyName];
      return values.map(function (value) {
        return "".concat(propertyName, "=").concat(value);
      }).join('&');
    }).join('&');
  }

  var _default = serializeQueryParamsTraditional;
  _exports.default = _default;
});