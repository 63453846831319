define("cropster-app/group/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  /**
   * A CSAR Group.
   *
   * @namespace Model
   * @class Group
   * @extends DS.Model
   */

  var _default = Model.extend({
    userSession: Ember.inject.service(),

    /**
     * The code of the group, also the unique id.
     * @type {String}
     */
    code: attr('string'),

    /**
     * The name of the group
     * @type {String}
     */
    name: attr('string'),

    /**
     * The group memberships of this group.
     * Each membership stands for a set of user+permissions.
     *
     * @attribute groupMemberships
     * @type {Model.GroupMembership[]}
     * @public
     */
    groupMemberships: hasMany('group-membership'),

    /**
     * Whether the group is active or not
     * @type {Boolean}
     */
    active: Ember.computed('id', 'userSession.activeGroup.id', function () {
      return this.get('id') === this.get('userSession.activeGroup.id');
    })
  });

  _exports.default = _default;
});