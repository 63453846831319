define("cropster-app/components/system-notifications-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a list of notications.
   *
   * @namespace Component
   * @class SystemNotificationList
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    userSettings: Ember.inject.service(),

    /**
     * The IDs of manually hidden system notifications.
     *
     * @property hiddenNotificationIds
     * @type {String[]}
     * @readOnly
     * @protected
     */
    hiddenNotificationIds: Ember.computed(function () {
      var userSettings = Ember.get(this, 'userSettings');
      return userSettings.getItem('hidden-system-notifications') || [];
    }),

    /**
     * The system notifications to display.
     * This filters out the manually hidden ones.
     *
     * @property systemNotifications
     * @type {Model.SystemNotification[]}
     * @readOnly
     * @protected
     */
    systemNotifications: Ember.computed('hiddenNotificationIds.[]', function () {
      var store = Ember.get(this, 'store');
      var hiddenNotificationIds = Ember.get(this, 'hiddenNotificationIds');
      var systemNotifications = store.peekAll('system-notification');
      return systemNotifications.filter(function (systemNotification) {
        return !hiddenNotificationIds.includes(Ember.get(systemNotification, 'id'));
      });
    }),
    actions: {
      dismissNotification: function dismissNotification(systemNotification) {
        var hiddenNotificationIds = Ember.get(this, 'hiddenNotificationIds');
        hiddenNotificationIds.pushObject(Ember.get(systemNotification, 'id'));
        var userSettings = Ember.get(this, 'userSettings');
        userSettings.setItem('hidden-system-notifications', hiddenNotificationIds);
      }
    }
  });

  _exports.default = _default;
});