define("cropster-app/components/evaluation-form/inputs/popup-slider/component", ["exports", "cropster-app/components/evaluation-form/inputs/base-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateStepOffset = calculateStepOffset;
  _exports.default = void 0;

  function calculateStepOffset(pos, stepsCount) {
    var offset = pos / (stepsCount - 1);
    return offset * 100;
  }
  /**
   * An input which shows the range as a background, and pops up a slider when clicked on.
   *
   * @namespace Component.EvaluationForm.Inputs
   * @class PopupSlider
   * @extends Component.EvaluationForm.Inputs.BaseInput
   * @public
   */


  var _default = _component.default.extend({
    /**
     * The current slider position.
     * This is a string which ensures that the indicator for the current slider position is correct.
     * It is an html safe string like `left: 20.5%;`, which can be applied as a style to an element.
     *
     * @property currentSliderPosition
     * @type {String}
     * @readOnly
     * @protected
     */
    currentSliderPosition: Ember.computed('value', 'min', 'max', function () {
      var val = Ember.get(this, 'value');

      if (Ember.isNone(val)) {
        return null;
      }

      var range = Ember.get(this, 'range');
      var stepsCount = Ember.get(range, 'length');
      var pos = range.indexOf(val);
      var offsetPercentage = calculateStepOffset(pos, stepsCount);
      var offsetPercentageString = "".concat(offsetPercentage, "%");
      return Ember.String.htmlSafe("left: ".concat(offsetPercentageString, ";"));
    })
  });

  _exports.default = _default;
});