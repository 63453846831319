define("cropster-app/update-required/route", ["exports", "cropster-app/utils/is-testing"], function (_exports, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.get(this, 'store').findRecord('system-notification', params.id, {
        backgroundReload: false
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        transition.abort();
      },
      reload: function reload() {
        this._reloadPage();
      }
    },
    _reloadPage: function _reloadPage() {
      this.transitionTo('loading');

      if ((0, _isTesting.isTesting)()) {
        this.transitionTo('index');
        return;
      }
      /* istanbul ignore next */


      Ember.run.next(function () {
        window.location.href = 'index.html';
      });
    }
  });

  _exports.default = _default;
});