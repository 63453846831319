define("cropster-app/mixins/outside-click-listener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This is a global count across all elements
  var outsideClickListenerCount = 0;
  /**
   * Add a method to easily trigger some function on outside click.
   *
   * Usage:
   *
   * ```js
   * this.addOutsideClickListener(() => this._close(), { ignoreInsideOfElement: this.element });
   * ```
   *
   * This will automatically be torn down when the component is destroyed or the user clicks outside.
   *
   * @namespace Mixin
   * @class OutsideClickListener
   * @extends Ember.Mixin
   * @abstract
   */

  var _default = Ember.Mixin.create({
    // A reference to the listener function itself, for being able to remove it again
    _outsideClickListener: null,

    /**
     * Add an outside click listener.
     * This will call the given function when the user clicks on the body.
     * It also takes optional configuration:
     *
     * * `removeOnClick` (default true): Remove the event listener when the given function is called
     * * `ignoreInsideOfElement` (default null): If set to a DOM element, ignore clicks inside of this element
     *
     * @param {Function} func
     * @param {Boolean} removeOnClick
     * @param {DOMElement} ignoreInsideOfElement
     * @return {Function} The listener function
     * @protected
     */
    addOutsideClickListener: function addOutsideClickListener(func) {
      var _this = this;

      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$removeOnClick = _ref.removeOnClick,
          removeOnClick = _ref$removeOnClick === void 0 ? true : _ref$removeOnClick,
          _ref$ignoreInsideOfEl = _ref.ignoreInsideOfElement,
          ignoreInsideOfElement = _ref$ignoreInsideOfEl === void 0 ? null : _ref$ignoreInsideOfEl;

      var body = document.querySelector('body'); // Ensure the counts are correct, if this is called twice

      if (Ember.get(this, '_outsideClickListener')) {
        this.removeOutsideClickListener();
      } // Make the body clickable, for iOS


      body.classList.add('clickable');

      var listener = function listener(e) {
        var target = e.target; // If this is set, we do not call the function when clicking inside of this element

        if (ignoreInsideOfElement && ignoreInsideOfElement.contains(target)) {
          return;
        }

        func(e);

        if (removeOnClick) {
          _this.removeOutsideClickListener();
        }
      };

      body.addEventListener('click', listener);
      outsideClickListenerCount++;
      Ember.set(this, '_outsideClickListener', listener);
      return listener;
    },

    /**
     * Remove the outside listener, if one was set up.
     * It is safe to call this, as it will just abort if nothing is set up.
     *
     * @method removeOutsideClickListener
     * @protected
     */
    removeOutsideClickListener: function removeOutsideClickListener() {
      var body = document.querySelector('body'); // If there is no listener saved, do nothing

      var listener = Ember.get(this, '_outsideClickListener');

      if (!listener) {
        return;
      }

      body.removeEventListener('click', listener);
      Ember.set(this, '_outsideClickListener', null);
      outsideClickListenerCount--; // If there are no listeners left, make the body un-clickable again

      if (!outsideClickListenerCount) {
        body.classList.remove('clickable');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeOutsideClickListener();
    }
  });

  _exports.default = _default;
});