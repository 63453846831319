define("cropster-app/sensorial-qc/model", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An analysis for one lot. Can contain multiple sensorialResults.
   *
   * @namespace Model
   * @class SensorialQc
   * @extends DS.Model
   * @public
   */
  var _default = _model.default.extend({
    // ---------------------------------------------------------------------------------------------------------
    // Attributes

    /**
     * The id tag of the analysis, e.g. QC-123.
     *
     * @attribute sampleCode
     * @type {String}
     * @public
     */
    idTag: (0, _attr.default)('string'),

    /**
     * The sample to analyse will be referred to by a code like "QXR".
     *
     * @attribute sampleCode
     * @type {String}
     * @public
     */
    sampleCode: (0, _attr.default)('string'),

    /**
     * Sums up the individual results of all cuppers.
     *
     * @attribute resultSummary
     * @type {String}
     * @public
     */
    resultSummary: (0, _attr.default)(),
    // eslint-disable-line ember/no-empty-attrs

    /**
     * The date this QC was scheduled/created.
     *
     * @attribute cuppingDate
     * @type {Date}
     * @public
     */
    evaluationDate: (0, _attr.default)('timestamp'),

    /**
     * The lab to which this QC belongs.
     *
     * @attribute lab
     * @type {String}
     * @public
     */
    lab: (0, _attr.default)('string'),

    /**
     * The category is basically a random string which can be added to a QC.
     *
     * @property category
     * @type {String}
     * @optional
     * @public
     */
    category: (0, _attr.default)('string'),

    /**
     * The lot this analysis is about.
     *
     * @attribute lot
     * @type {Model.Lot}
     * @public
     */
    lot: (0, _relationships.belongsTo)('lot', {
      asnyc: true,
      inverse: null
    }),

    /**
     * The sensorial session this analysis belongs to.
     *
     * @attribute sensorialSession
     * @type {Model.SensorialSession}
     * @public
     */
    sensorialSession: (0, _relationships.belongsTo)('sensorial-session'),

    /**
     * The sensorial sheet used for this qc.
     *
     * @attribute sensorialSheet
     * @type {Model.SensorialSheet}
     * @readOnly
     * @public
     */
    sensorialSheet: (0, _relationships.belongsTo)('sensorial-sheet'),

    /**
     * The group to which this qc belongs.
     *
     * @attribute group
     * @type {Model.Group}
     * @public
     */
    group: (0, _relationships.belongsTo)('group')
  });

  _exports.default = _default;
});