define("cropster-app/initializers/cordova-device-ready", ["exports", "cropster-app/utils/is-on-cordova"], function (_exports, _isOnCordova) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (!(0, _isOnCordova.default)()) {
      // Prevent context menu to pop up on chrome
      window.oncontextmenu = function (event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      };

      return;
    }

    application.deferReadiness();
    document.addEventListener('deviceready', function () {
      application.advanceReadiness();
    }, false);
  }

  var _default = {
    name: 'cordova-device-ready',
    before: ['ember-data'],
    initialize: initialize
  };
  _exports.default = _default;
});