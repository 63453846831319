define("cropster-app/components/sessions-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A button item that shows information about a session.
   *
   * @namespace Component.SessionsList
   * @class Item
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['button', 'button--plain'],

    /**
     * The session to show
     *
     * @attribute session
     * @type {Model.CuppingSession}
     * @public
     */
    session: null,

    /**
     * The action called when the button is pressed
     *
     * @event clicked
     * @param {Model.CuppingSession} session
     * @public
     */
    clicked: null,
    isClosedOrSynced: Ember.computed.or('session.isStateClosed', 'session.isSynced'),

    /**
     * The class to use for the status badge.
     *
     * @property statusClass
     * @type {String}
     * @readOnly
     * @protected
     */
    statusClass: Ember.computed('session.{isStarted,isSynced,isCupperSubmitted,isSyncing,isStateClosed}', function () {
      var session = Ember.get(this, 'session');

      var _EmberGetProperties = Ember.getProperties(session, 'isStateClosed', 'isSynced', 'isSyncing', 'isCupperSubmitted'),
          isSynced = _EmberGetProperties.isSynced,
          isCupperSubmitted = _EmberGetProperties.isCupperSubmitted,
          isSyncing = _EmberGetProperties.isSyncing,
          isStateClosed = _EmberGetProperties.isStateClosed;

      if (isStateClosed) {
        return '';
      }

      if (isCupperSubmitted && isSynced) {
        return 'status--synced';
      }

      if (isCupperSubmitted && isSyncing) {
        return 'status--syncing';
      }

      if (isCupperSubmitted) {
        return 'status--warning';
      }

      return '';
    }),
    click: function click(e) {
      // Prevent this from firing if clicking on a link inside the element
      if (e.target.tagName === 'A') {
        return;
      }

      var session = Ember.get(this, 'session');

      if (Ember.get(session, 'isStateClosed') && !Ember.get(session, 'isCupperSubmitted')) {
        return;
      }

      var action = Ember.get(this, 'attrs.clicked');

      if (action) {
        action(this.get('session'));
      }
    }
  });

  _exports.default = _default;
});