define("cropster-app/components/evaluation-form/sample/roast-levels/component", ["exports", "cropster-app/utils/range", "cropster-app/utils/math"], function (_exports, _range, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Input a roast level for a sample.
   *
   * @namespace Component.EvaluationForm.Sample
   * @class RoastLevels
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    screen: Ember.inject.service(),

    /**
     * The cupping result to input the roast level for.
     *
     * @attribute cuppingResult
     * @type {Model.CuppingResult}
     * @public
     */
    cuppingResult: null,

    /**
     * If we are in a compact view.
     *
     * @attribute isCompact
     * @type {Boolean}
     * @default false
     * @public
     */
    isCompact: false,

    /**
     * The action to call to update the roast level.
     *
     * @event update
     * @param {Number} roastLevel
     * @public
     */
    update: null,

    /**
     * The actual selected roast value.
     *
     * @property value
     * @type {Number}
     * @readOnly
     * @protected
     */
    value: Ember.computed.alias('cuppingResult.roastLevel'),

    /**
     * This is updated while sliding, to change the color of the checkmark accordingly.
     *
     * @property sliderValue
     * @type {Number}
     * @protected
     */
    sliderValue: null,

    /**
     * The actual value to use.
     * This is required, because the slider breaks if you pass null/undefined to it.
     *
     * @property actualValue
     * @type {Number}
     * @readOnly
     * @protected
     */
    actualValue: Ember.computed('value', 'sliderMiddle', function () {
      var value = Ember.get(this, 'value');
      return typeof value === 'number' ? value : Ember.get(this, 'sliderMiddle');
    }),

    /**
     * The roast levels to select from.
     *
     * @property roastLevels
     * @type {Number[]}
     * @readOnly
     * @protected
     */
    roastLevels: Ember.computed.alias('cuppingResult.cuppingSheet.roastLevels'),

    /**
     * If a vertical slider should be used.
     *
     * @property isVertical
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isVertical: Ember.computed('isCompact', 'screen.width', function () {
      return !Ember.get(this, 'isCompact') && Ember.get(this, 'screen.width') > 700;
    }),

    /**
     * The middle value of the slider.
     *
     * @property sliderMiddle
     * @type {Number}
     * @readOnly\
     * @protected
     */
    sliderMiddle: Ember.computed('roastLevels.[]', function () {
      return (0, _range.rangeMiddle)(Ember.get(this, 'roastLevels'));
    }),

    /**
     * If the slider value is rather high or not.
     * This is used for styling the checkmark.
     *
     * @property sliderValueHigh
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    sliderValueHigh: Ember.computed('sliderValue', 'sliderMiddle', function () {
      return Ember.get(this, 'sliderValue') >= Ember.get(this, 'sliderMiddle');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.set(this, 'sliderValue', Ember.get(this, 'value'));
    },
    actions: {
      onSlide: function onSlide(val) {
        Ember.set(this, 'sliderValue', val);
      },
      onChange: function onChange(val) {
        Ember.set(this, 'sliderValue', val);
        return this._sendAction();
      }
    },
    _sendAction: function _sendAction() {
      var val = (0, _math.roundWithMargin)(Ember.get(this, 'sliderValue'));
      var action = Ember.get(this, 'attrs.update'); // Do nothing is value hasn't changed

      if (val === Ember.get(this, 'value')) {
        return;
      }

      return action(val);
    }
  });

  _exports.default = _default;
});