define("cropster-app/create-session/setup-table/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      enterSession: function enterSession(sensorialSession) {
        this.transitionToRoute('dashboard', {
          queryParams: {
            reload: true,
            openSessionIdTag: Ember.get(sensorialSession, 'idTag')
          }
        });
      }
    }
  });

  _exports.default = _default;
});