define("cropster-app/components/evaluation-form/sample/item/component", ["exports", "cropster-app/utils/input-types-to-components-map", "cropster-app/utils/math", "cropster-app/utils/calculate-cupping-result-item-score"], function (_exports, _inputTypesToComponentsMap, _math, _calculateCuppingResultItemScore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Input data for one cupping result item.
   * This item can have a quality, intensity, and/or descriptors.
   *
   * @namespace Component.EvaluationForm.Sample
   * @class Item
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    /**
     * The cupping result item to display data for.
     *
     * @attribute cuppingResultItem
     * @type {Model.QualityCuppingItem}
     * @public
     */
    cuppingResultItem: null,

    /**
     * The action to call to recalculate the total score.
     *
     * @event recalculateTotalScore
     * @public
     */
    recalculateTotalScore: null,

    /**
     * The action to create a new sensorial descriptor.
     *
     * @event createSensorialDescriptor
     * @param {String} sensorialDescriptorName
     * @public
     */
    createSensorialDescriptor: null,

    /**
     * The action to remove a sensorial descriptor from a sensorial result item.
     *
     * @event removeAssignedSensorialDescriptor
     * @param {Model.AssignedSensorialDescriptor} assignedSensorialDescriptor
     * @param {Model.QualityCuppingItem} cuppingResultItem
     * @public
     */
    removeAssignedSensorialDescriptor: null,

    /**
     * The action to add a sensorial descriptor from a sensorial result item.
     *
     * @event addAssignedSensorialDescriptor
     * @param {Model.SensorialDescriptor} sensorialDescriptor
     * @param {Model.QualityCuppingItem} cuppingResultItem
     * @public
     */
    addSensorialDescriptor: null,

    /**
     * The action to update a sensorial descriptor on a sensorial result item.
     *
     * @event updateAssignedSensorialDescriptor
     * @param {Model.AssignedSensorialDescriptor} assignedSensorialDescriptor
     * @param {Model.QualityCuppingItem} cuppingResultItem
     * @public
     */
    updateAssignedSensorialDescriptor: null,

    /**
     * The cupping sheet item to display.
     *
     * @property cuppingSheetItem
     * @type {Model.CuppingSheetItem}
     * @readOnly
     * @protected
     */
    cuppingSheetItem: Ember.computed.alias('cuppingResultItem.cuppingSheetItem'),

    /**
     * If there is an intensity to display.
     *
     * @property hasIntensity
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasIntensity: Ember.computed.alias('cuppingSheetItem.hasIntensity'),

    /**
     * If there is a quality to display.
     *
     * @property hasQuality
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasQuality: Ember.computed.alias('cuppingSheetItem.hasQuality'),

    /**
     * If there are per-category descriptors to display.
     *
     * HACKY: While the SCAA sheet has descriptors per category, we don't want to show them, to make the sheet more compact
     * So we ensure this returns false for the scaa sheet
     *
     * @property hasDescriptors
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasDescriptors: Ember.computed.and('cuppingSheetItem.allowDescriptors', 'cuppingSheetItem.cuppingSheet.isNotSCAA'),

    /**
     * If this is a defects category.
     * This is a special form, which will deactivate the regular quality/intensity rendering.
     *
     * @property isDefectCategory
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isDefectCategory: Ember.computed.alias('cuppingSheetItem.isDefectCategory'),

    /**
     * The quality component to use.
     * If this is null, don't render a quality part.
     * This is the final decider if quality should be displayed.
     *
     * For defect inputs, this will always be false.
     * If a given input type is set, use this one, else fall back to "TEXT".
     *
     * @property qualityComponent
     * @type {String|null}
     * @readOnly
     * @protected
     */
    qualityComponent: Ember.computed('cuppingSheetItem.qualityInputType', 'hasQuality', 'isDefectCategory', function () {
      if (!Ember.get(this, 'hasQuality') || Ember.get(this, 'isDefectCategory')) {
        return null;
      }

      var inputType = Ember.get(this, 'cuppingSheetItem.qualityInputType');
      var component = _inputTypesToComponentsMap.inputTypeToComponentMap[inputType];
      return component || _inputTypesToComponentsMap.inputTypeToComponentMap.TEXT;
    }),

    /**
     * The intensity component to use.
     * If this is null, don't render a intensity part.
     * This is the final decider if intensity should be displayed.
     *
     * For defect inputs, this will always be false.
     * If a given input type is set, use this one, else fall back to "TEXT".
     *
     * @property intensityComponent
     * @type {String|null}
     * @readOnly
     * @protected
     */
    intensityComponent: Ember.computed('cuppingSheetItem.intensityInputType', 'hasIntensity', 'isDefectCategory', function () {
      if (!Ember.get(this, 'hasIntensity') || Ember.get(this, 'isDefectCategory')) {
        return null;
      }

      var inputType = Ember.get(this, 'cuppingSheetItem.intensityInputType');
      var component = _inputTypesToComponentsMap.inputTypeToComponentMap[inputType];
      return component || _inputTypesToComponentsMap.inputTypeToComponentMap.TEXT;
    }),

    /**
     * If this item has only descriptors, but no quality/intensity.
     *
     * @property descriptorsOnly
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    descriptorsOnly: Ember.computed('intensityComponent', 'qualityComponent', function () {
      return !Ember.get(this, 'intensityComponent') && !Ember.get(this, 'qualityComponent');
    }),
    actions: {
      updateQuality: function updateQuality(val) {
        var cuppingResultItem = Ember.get(this, 'cuppingResultItem');
        Ember.set(cuppingResultItem, 'quality', this._parseNumericalValue(val));

        this._saveResultItem();
      },
      updateIntensity: function updateIntensity(val) {
        var cuppingResultItem = Ember.get(this, 'cuppingResultItem');
        Ember.set(cuppingResultItem, 'intensity', this._parseNumericalValue(val));

        this._saveResultItem();
      },
      updateDescriptors: function updateDescriptors() {
        this._saveResultItem();
      }
    },

    /**
     * Convert a numerical value.
     * If it is undefined/null/'', return 'undefined'.
     *
     * @method _parseNumericalValue
     * @param {Mixed} val
     * @return {Number|undefiend}
     * @private
     */
    _parseNumericalValue: function _parseNumericalValue(val) {
      return Ember.isBlank(val) ? undefined : (0, _math.roundWithMargin)(val * 1);
    },

    /**
     * Save the result item itself.
     *
     * @method _saveResultItem
     * @private
     */
    _saveResultItem: function _saveResultItem() {
      var cuppingResultItem = Ember.get(this, 'cuppingResultItem');
      var cuppingSheetItem = Ember.get(cuppingResultItem, 'cuppingSheetItem');
      var calculatedScore = (0, _calculateCuppingResultItemScore.calculateCuppingResultItemScore)(cuppingSheetItem, cuppingResultItem);
      Ember.set(cuppingResultItem, 'calculatedQuality', calculatedScore);
      cuppingResultItem.saveBulk();
      var action = Ember.get(this, 'attrs.recalculateTotalScore');
      action(cuppingResultItem);
    }
  });

  _exports.default = _default;
});