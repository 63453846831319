define("cropster-app/components/session-review/item/category/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Display a single category in the session review screen.
   *
   * @namespace Component.SessionsReview.Item
   * @class Category
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'li',

    /**
     * The cupping result item to display.
     *
     * @attribute cuppingResultItem
     * @type {Model.QualityCuppingItem}
     * @public
     */
    cuppingResultItem: null,

    /**
     * The cupping sheet item of this category.
     *
     * @property cuppingSheetItem
     * @type {Model.CuppingSheetItem}
     * @readOnly
     * @protected
     */
    cuppingSheetItem: Ember.computed.alias('cuppingResultItem.cuppingSheetItem'),

    /**
     * The descriptors of this category.
     *
     * @property assignedSensorialDescriptors
     * @type {Model.AssignedSensorialDescriptor[]}
     * @readOnly
     * @protected
     */
    assignedSensorialDescriptors: Ember.computed.alias('cuppingResultItem.assignedSensorialDescriptors'),

    /**
     * The intensity of this category.
     * If null, don't display anything.
     *
     * @property intensity
     * @type {Number}
     * @readOnly
     * @protected
     */
    intensity: Ember.computed('cuppingResultItem.intensity', 'cuppingSheetItem.isDefectCategory', function () {
      var intensity = Ember.get(this, 'cuppingResultItem.intensity');
      return !Ember.isNone(intensity) && !Ember.get(this, 'cuppingSheetItem.isDefectCategory') ? intensity : null;
    }),

    /**
     * The quality of this category.
     * If null, don't display anything.
     *
     * @property quality
     * @type {Number}
     * @readOnly
     * @protected
     */
    quality: Ember.computed('cuppingResultItem.{quality,calculatedQuality}', 'cuppingSheetItem.isQualityZero', function () {
      return Ember.get(this, 'cuppingSheetItem.isQualityZero') ? Ember.get(this, 'cuppingResultItem.quality') : Ember.get(this, 'cuppingResultItem.calculatedQuality');
    })
  });

  _exports.default = _default;
});