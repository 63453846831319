define("cropster-app/groups/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "cropster-app/mixins/reset-scroll"], function (_exports, _authenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _resetScroll.default, {
    toast: Ember.inject.service(),
    l10n: Ember.inject.service(),
    userSession: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    dataSync: Ember.inject.service(),
    networkState: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: {
      reload: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments); // Handle abortions & errors


      if (transition.isAborted) {
        return;
      }

      var queryParams = Ember.get(transition, 'to.queryParams') || {};
      var wasLoadingError = queryParams.loadError === 'true';

      if (wasLoadingError) {
        this.get('userSession').setActiveGroup(null);
        var error = this.get('l10n').t('Group could not be loaded.');
        this.toast.error(error);
      }

      var shouldReload = queryParams.reload;

      if (shouldReload === 'true' || shouldReload === true) {
        shouldReload = true;
      } else {
        shouldReload = false;
      }

      var promise;

      if (shouldReload) {
        var dataSync = Ember.get(this, 'dataSync');
        promise = dataSync.loadGroups();
      } else {
        promise = Ember.RSVP.Promise.resolve();
      } // Resolve even if one of the requests fails, also with a min. 100ms wait time to force loading spinner


      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(_this, function () {
          return promise.then(resolve, resolve);
        }, 100);
      });
    },
    model: function model() {
      var store = Ember.get(this, 'store');
      var groups = store.peekAll('group');

      if (!Ember.get(groups, 'length') || Ember.get(this, 'networkState.isOffline')) {
        groups = null;
      }

      return groups || store.findAll('group');
    },
    afterModel: function afterModel(groups, transition) {
      var userSettings = Ember.get(this, 'userSettings'); // If there are no groups, error

      if (!Ember.get(groups, 'length')) {
        this.transitionTo('groups-error');
        return;
      }

      var redirectIfActiveGroup = Ember.get(transition, 'to.queryParams.redirectIfActiveGroup');
      redirectIfActiveGroup = redirectIfActiveGroup === true || redirectIfActiveGroup === 'true'; // Auto-login if there is an active group, or if there is only one group
      // Only if redirectIfActiveGroup is true!

      var activeGroup = Ember.get(this, 'userSession.activeGroup');

      if (!activeGroup && Ember.get(groups, 'length') === 1) {
        activeGroup = Ember.get(groups, 'firstObject');
      }

      if (redirectIfActiveGroup && activeGroup) {
        // If we have never done a full reload for this group, force it
        // Else, enter it without reloading all descriptors for a better performance
        var lastFullReload = userSettings.getItem('last-full-reload', true);
        this.loadAndOpenGroup(activeGroup, groups, !lastFullReload);
      }
    },
    resetController: function resetController(controller, isExiting) {
      Ember.set(controller, 'redirectIfActiveGroup', false);
      Ember.set(controller, 'reload', false);

      if (isExiting) {
        Ember.set(controller, 'loadError', null);
      }
    },
    loadAndOpenGroup: function loadAndOpenGroup(group, groups) {
      var _this2 = this;

      var shouldReloadAll = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var controller = this.controllerFor('application');
      controller.showLoadingSpinner();
      this.get('userSession').setActiveGroup(group, shouldReloadAll).then(function () {
        controller.hideLoadingSpinner();

        _this2.transitionTo('dashboard.index', {
          queryParams: {
            reloadFromServer: true
          }
        });
      }, function (err) {
        controller.hideLoadingSpinner();

        _this2.transitionTo('groups');

        _this2.get('toast').error('An error occurred when opening the group.');

        throw new Error(err);
      });
    },
    actions: {
      refreshGroups: function refreshGroups() {
        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        Ember.set(controller, 'reload', true);
        this.refresh();
      },
      enterGroup: function enterGroup(group) {
        this.loadAndOpenGroup(group);
      }
    }
  });

  _exports.default = _default;
});