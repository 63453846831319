define("cropster-app/cupping-session/serializer", ["exports", "cropster-app/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The session serializer
   * @namespace DS
   * @class Serializer.Serializer
   */
  var _default = _serializer.default.extend({
    attrs: {
      analysisResults: {
        serialize: true
      }
    },
    normalizeCSAR: function normalizeCSAR(model, hash, prop) {
      hash = this._super(model, hash, prop);
      hash.type = 'cupping-session';

      if (hash.schedule) {
        // TODO: why do some sessions have no schedule attribute?
        hash.date = hash.schedule.dateTime;
        delete hash.schedule;
      }

      var analysisResults = hash.analysisResults || [];
      delete hash.analysisResults;
      var relationshipsOfAnalysisResults = [];
      var included = [];
      var analysisSerializer = this.store.serializerFor('analysis-result');
      analysisResults.compact().forEach(function (result) {
        var data = analysisSerializer.deserializeFromSession(hash.id, result);
        relationshipsOfAnalysisResults.push({
          type: data.type,
          id: data.id
        });
        included.push(data);
      });
      var relationshipsOfCuppingSheet = {
        type: 'cupping-sheet',
        id: hash.cuppingSkinId
      };
      var newHash = {
        included: included,
        data: {
          id: hash.id,
          type: 'cupping-session',
          attributes: hash,
          relationships: {
            analysisResults: {
              data: relationshipsOfAnalysisResults
            },
            cuppingSheet: {
              data: relationshipsOfCuppingSheet
            }
          }
        }
      };
      return newHash;
    }
  });

  _exports.default = _default;
});