define("cropster-app/components/create-sensorial-session/session-lots/table-lots/columns/sample-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xg0z2+fL",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"t-var\",[[27,\"translate-sample-type\",[[27,\"get\",[[23,[\"record\"]],[23,[\"column\",\"propertyName\"]]],null]],null]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/create-sensorial-session/session-lots/table-lots/columns/sample-type/template.hbs"
    }
  });

  _exports.default = _default;
});