define("cropster-app/dashboard/completed/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    networkState: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    isOffline: Ember.computed.alias('networkState.isOffline'),
    openSessionTask: Ember.computed.alias('dashboard.openSessionTask'),
    serverCompletedSessions: Ember.computed('completedSessionsFromDevice.[]', 'completedSessionsFromOtherDevice.[]', 'model.allCompletedSensorialSessions.[]', function () {
      var completedSessionsFromDevice = this.completedSessionsFromDevice;
      var allCompletedSessions = Ember.get(this, 'model.allCompletedSensorialSessions');
      var localCompletedSessionIdTags = completedSessionsFromDevice.mapBy('idTag');
      return allCompletedSessions.filter(function (sensorialSession) {
        return !localCompletedSessionIdTags.includes(Ember.get(sensorialSession, 'idTag'));
      }).map(function (sensorialSession) {
        var idTag = Ember.get(sensorialSession, 'idTag'); // Map them into a pseudo format of the "old" cupping-sessions

        return Ember.Object.create({
          idTag: idTag,
          sessionStatus: 'CLOSED',
          isStateOpen: false,
          isStateClosed: true,
          groupCode: Ember.get(sensorialSession, 'group.id'),
          date: Ember.get(sensorialSession, 'scheduleDate'),
          name: Ember.get(sensorialSession, 'name'),
          displayName: Ember.get(sensorialSession, 'name') || Ember.get(sensorialSession, 'idTag')
        });
      });
    }),
    completedSessionsFromDevice: Ember.computed.filterBy('model.localCompletedSessions', 'sessionFinishedTime'),
    sortedCompletedSessions: Ember.computed('completedSessionsFromDevice.@each.sessionFinishedTime', function () {
      return this.completedSessionsFromDevice.sort(function (a, b) {
        return Ember.compare(b.sessionFinishedTime.valueOf(), a.sessionFinishedTime.valueOf());
      });
    }),
    sessionsSortingDesc: ['date:desc'],
    // eslint-disable-line
    sortedNonCompletedSessions: Ember.computed.sort('serverCompletedSessions', 'sessionsSortingDesc')
  });

  _exports.default = _default;
});