define("cropster-app/services/l10n", ["exports", "ember-l10n/services/l10n", "cropster-app/utils/is-testing"], function (_exports, _l10n, _isTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _l10n.default.extend({
    /* eslint-disable camelcase */
    availableLocales: Ember.computed(function () {
      return {
        en: 'English',
        ko: '한국어 - Korean',
        zh_CN: '简体中文 - Chinese (simplified)',
        zh_HK: '漢語 - Chinese (traditional)'
      };
    }),

    /* eslint-enable camelcase */
    getLocalAlias: function getLocalAlias(locale) {
      // There are variations of chinese locales
      // We need to map those to either Simplified (CN) or Traditional (HK).
      switch (locale) {
        case 'zh_CN':
        case 'zh_SG':
        case 'zh_Hans':
          return 'zh_CN';

        case 'zh_HK':
        case 'zh_TW':
        case 'zh_MO':
        case 'zh_Hant':
          return 'zh_HK';
      }

      return locale;
    },
    autoInitialize: false,
    jsonPath: Ember.computed(function () {
      return (0, _isTesting.isTesting)() ? '/assets/locales' : 'assets/locales';
    })
  });

  _exports.default = _default;
});