define("cropster-app/components/touch-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bEugL3+D",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isActive\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[[23,[\"wormholeLocation\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"touch-slider/slider\",null,[[\"wormholeLocation\",\"data-test-touch-slider-input\",\"start\",\"range\",\"step\",\"defaultValue\",\"pips\",\"formatFrom\",\"onRender\",\"onChange\"],[[23,[\"wormholeLocation\"]],true,[23,[\"value\"]],[23,[\"minMax\"]],[23,[\"step\"]],[23,[\"defaultValue\"]],[23,[\"pips\"]],[23,[\"formatFrom\"]],[27,\"action\",[[22,0,[]],\"onSliderRendered\"],null],[27,\"action\",[[22,0,[]],\"onChange\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/touch-slider/template.hbs"
    }
  });

  _exports.default = _default;
});