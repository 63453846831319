define("cropster-app/helpers/app-version", ["exports", "cropster-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appVersion = appVersion;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Display a nicer, formatted app version.
   * This handles Beta/Alpha tags & commit hashes in a nicer way.
   *
   * @namespace CropsterCup.Helper
   * @class AppVersion
   * @extends Ember.helper
   * @example
   * ```hbs
   * {{app-version '18.01.1-beta.1+abc123'}}
   * ```
   * @public
   */
  function appVersion(params) {
    var version = Ember.get(_environment.default, 'APP.version'); // Allow to overwrite it via params
    // This is mainly for testing!

    if (params && params.length > 0) {
      var _params = _slicedToArray(params, 1);

      version = _params[0];
    }

    var _version$split = version.split('+'),
        _version$split2 = _slicedToArray(_version$split, 2),
        mainVersion = _version$split2[0],
        hash = _version$split2[1];

    var _mainVersion$split = mainVersion.split('-'),
        _mainVersion$split2 = _slicedToArray(_mainVersion$split, 2),
        versionStr = _mainVersion$split2[0],
        beta = _mainVersion$split2[1];

    if (beta) {
      var betaStr = Ember.String.capitalize(beta.replace(/\./g, ' '));
      versionStr += " ".concat(betaStr);
    }

    if (hash) {
      versionStr += " <span class='text--grey text--smaller'>#".concat(hash, "</span>");
    }

    return Ember.String.htmlSafe(versionStr);
  }

  var _default = Ember.Helper.helper(appVersion);

  _exports.default = _default;
});