define("cropster-app/transforms/integer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return Ember.isEmpty(serialized) || isNaN(serialized) ? null : parseInt(serialized);
    },
    serialize: function serialize(deserialized) {
      return Ember.isEmpty(deserialized) || isNaN(deserialized) ? null : parseInt(deserialized);
    }
  });

  _exports.default = _default;
});