define("cropster-app/helpers/format-nl2br", ["exports", "ember-cropster-common/helpers/format-nl2br"], function (_exports, _formatNl2br) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formatNl2br.default;
    }
  });
  Object.defineProperty(_exports, "formatNl2br", {
    enumerable: true,
    get: function get() {
      return _formatNl2br.formatNl2br;
    }
  });
});