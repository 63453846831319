define("cropster-app/components/session-review/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A single item in the review screen.
   *
   * @namespace Component.SessionReview
   * @class Item
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['card'],

    /**
     * The cupping result to display the summary for.
     *
     * @attribute cuppingResult
     * @type {Model.CuppingResult}
     * @public
     */
    cuppingResult: null,

    /**
     * If there is a calculated score for this result.
     * If there is no score at all, this should always be true, to display 0.
     *
     * @property hasCalculatedScore
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    hasCalculatedScore: Ember.computed('cuppingResult.{score,cupperScore}', function () {
      return Ember.get(this, 'cuppingResult.score') || !Ember.get(this, 'cuppingResult.cupperScore');
    })
  });

  _exports.default = _default;
});