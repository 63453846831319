define("cropster-app/evaluation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Nso8E+V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-center\"]],{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"nav-bar-center__title\"],[9],[0,\"\\n    \"],[1,[23,[\"model\",\"displayName\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/evaluation/template.hbs"
    }
  });

  _exports.default = _default;
});