define("cropster-app/components/groups-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RItD0C4q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"item-list__item-wrapper\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"data-test-group-name\",\"\"],[9],[0,\"\\n    \"],[1,[23,[\"group\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"group\",\"active\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"button button--primary group-action-button\"],[11,\"data-test-group-is-current\",\"\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Go to sessions\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"button button--secondary group-action-button\"],[11,\"data-test-group-enter-button\",\"\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Enter group\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/groups-list/item/template.hbs"
    }
  });

  _exports.default = _default;
});