define("cropster-app/components/groups-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4SFrJuPO",
    "block": "{\"symbols\":[\"group\",\"@enterGroup\"],\"statements\":[[4,\"each\",[[23,[\"sortedGroups\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[11,\"data-test-group-item\",\"\"],[9],[0,\"\\n    \"],[1,[27,\"groups-list/item\",null,[[\"group\",\"enterGroup\",\"data-test-group-button\"],[[22,1,[]],[22,2,[]],true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/groups-list/template.hbs"
    }
  });

  _exports.default = _default;
});