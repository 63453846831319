define("cropster-app/components/evaluation-form/sample-delayed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n251KXJL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"cuppingResult\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,[\"cuppingResult\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"loading__container\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"loading__text\"],[11,\"data-test-sample-is-loading\",\"\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Loading...\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/sample-delayed/template.hbs"
    }
  });

  _exports.default = _default;
});