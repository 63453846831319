define("cropster-app/components/evaluation-form/inputs/buttons-toggle/component", ["exports", "cropster-app/components/evaluation-form/inputs/base-input/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Toggle buttons input.
   *
   * This basically acts as a "selected cups" input.
   * E.g. if the total value is 5, with steps of 1, there are 5 'cups' that can all be on or off.
   * A full score means all "cups" are on. An empty score means all "cups" are off.
   * In the above example, 4 would mean: [on, on, on, on, off] - or [on, off, on, on, on].
   * The order of on/off "cups" is only relevant for the UI, but results in the same score.
   *
   * This is a somewhat special input rendering.
   *
   * @namespace Component.EvaluationForm.Inputs
   * @class ButtonsToggle
   * @extends Component.EvaluationForm.Inputs.BaseInput
   * @public
   */
  var _default = _component.default.extend({
    /**
     * The selected items (="cups") - these are the DEACTIVATED items!.
     * This is an array of indices.
     * An empty array means no item is selected. (=max score)
     * An example for 3/5 points would be: [1,2] or [1,4]
     *
     * @property selectedItems
     * @type {Number[]}
     * @protected
     */
    selectedItems: null,

    /**
     * The parsed steps to display.
     * This will return one step per range, where the 'isSelected' property is set based on the selectedItems.
     *
     * @property parsedSteps
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    parsedSteps: Ember.computed('selectedItems.[]', 'range.[]', function () {
      var selected = Ember.get(this, 'selectedItems') || [];
      var steps = Ember.get(this, 'range').toArray().slice(1);
      return steps.map(function (val, pos) {
        var isSelected = false; // This is 1-based

        var selectedPos = pos + 1;

        if (selected.includes(selectedPos)) {
          isSelected = true;
        }

        return {
          isSelected: isSelected,
          position: selectedPos,
          classes: isSelected ? 'button--light button-toggle--off' : 'button--grey button-toggle--on'
        };
      });
    }),

    /**
     * The calculated quality.
     * This is then being displayed to the user.
     *
     * @property calculatedQuality
     * @type {Number}
     * @readOnly
     * @protected
     *
     */
    calculatedQuality: Ember.computed.alias('cuppingResultItem.calculatedQuality'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._initSelectedItems();
    },
    actions: {
      toggleButton: function toggleButton(item) {
        var action = Ember.get(this, 'attrs.update');
        var position = item.position;
        var cuppingResultItem = Ember.get(this, 'cuppingResultItem');
        var selectedItems = Ember.get(this, 'selectedItems') || [];

        if (selectedItems.includes(position)) {
          selectedItems.removeObject(position);
        } else {
          selectedItems.addObject(position);
        }

        Ember.set(cuppingResultItem, 'cupsSelected', selectedItems);

        var value = this._getValueForSelectedItems(selectedItems);

        action(value);
      }
    },

    /**
     * Get the corresponding value for the given array of selected items.
     *
     * @method _getValueForSelectedItems
     * @param {Number[]} selectedItems
     * @return {Number}
     * @private
     */
    _getValueForSelectedItems: function _getValueForSelectedItems(selectedItems) {
      var max = Ember.get(this, 'max');
      var step = Ember.get(this, 'step');
      var deactivatedItems = Ember.get(selectedItems, 'length');
      return max - step * deactivatedItems;
    },

    /**
     * Initialise the selected items.
     * This takes the special `cupsSelected` property on the cuppingResultItem.
     * It also checks if that is in sync with the actual given value. If not, it will re-set the `cupsSelected`
     * to fit the value.
     *
     * @method _initSelectedItems
     * @private
     */
    _initSelectedItems: function _initSelectedItems() {
      var selected = Ember.get(this, 'cuppingResultItem.cupsSelected') || []; // Ensure the selected items are not out of sync...

      var max = Ember.get(this, 'max');
      var step = Ember.get(this, 'step');
      var value = Ember.get(this, 'value');

      if (!step) {
        throw Error('no step is set');
      }

      var calculatedValue = this._getValueForSelectedItems(selected);

      if (calculatedValue !== value) {
        // Re-initialise the selected items...
        var selectedItems = (max - value) / step;
        selected = [];

        for (var i = 1; i <= selectedItems; i++) {
          selected.push(i);
        }
      }

      Ember.set(this, 'selectedItems', selected);
    }
  });

  _exports.default = _default;
});