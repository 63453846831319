define("cropster-app/create-session-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2AcApvQP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-center\"]],{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Setup session\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"margin-top-medium align-center\"],[9],[0,\"\\n\\n  \"],[7,\"img\"],[11,\"src\",\"images/cropster-cup-image.png\"],[12,\"alt\",[27,\"t\",[\"Cropster Cup\"],null]],[11,\"class\",\"content-image\"],[9],[10],[0,\"\\n\\n  \"],[7,\"h3\"],[11,\"class\",\"content-title\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"You don't have the required permissions to create sessions, or you are offline.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"app-footer\"]],{\"statements\":[[0,\"  \"],[1,[21,\"app-footer\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/create-session-error/template.hbs"
    }
  });

  _exports.default = _default;
});