define("cropster-app/components/descriptors-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S2uBn09G",
    "block": "{\"symbols\":[\"assignedSensorialDescriptor\"],\"statements\":[[4,\"each\",[[23,[\"assignedSensorialDescriptors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[27,\"descriptors-list/descriptor-item\",null,[[\"assignedSensorialDescriptor\",\"allowActions\",\"data-test-descriptor-list-item\",\"outerButtonClass\",\"updateAssignedSensorialDescriptor\",\"removeAssignedSensorialDescriptor\"],[[22,1,[]],[23,[\"allowActions\"]],true,\"descriptor-select__flavor__name\",[27,\"action\",[[22,0,[]],\"updateAssignedSensorialDescriptor\"],null],[27,\"action\",[[22,0,[]],\"removeAssignedSensorialDescriptor\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/descriptors-list/template.hbs"
    }
  });

  _exports.default = _default;
});