define("cropster-app/permission/serializer", ["exports", "cropster-app/serializers/jsonapi-serializer"], function (_exports, _jsonapiSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonapiSerializer.default.extend({});

  _exports.default = _default;
});