define("cropster-app/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "cropster-app/mixins/reset-scroll"], function (_exports, _unauthenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _resetScroll.default, {
    routerAfterAuthentication: 'groups-reload',
    routeIfAlreadyAuthenticated: 'groups-reload',
    // clear a potentially stale error message from previous login attempts
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'errorMessage', null);
    },
    actions: {
      error: function error(_error) {
        /* eslint-disable no-console */
        console.log('LOGIN error: ', _error);
        /* eslint-enable no-console */
      }
    }
  });

  _exports.default = _default;
});