define("cropster-app/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WifXQNeP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"modal-wormhole\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"touch-slider-wormhole\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"app-header\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"scroll-container \",[27,\"unless\",[[23,[\"session\",\"isAuthenticated\"]],\"scroll-container--white\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n      \"],[7,\"main\"],[9],[0,\"\\n        \"],[1,[21,\"outlet\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container container--login\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"toast-container\"],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"app-footer\"],[9],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/application/template.hbs"
    }
  });

  _exports.default = _default;
});