define("cropster-app/utils/guid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateGuid;

  function gen(count) {
    var out = '';

    for (var i = 0; i < count; i++) {
      out += ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    }

    return out;
  }

  function generateGuid() {
    return [gen(2), gen(1), gen(1), gen(1), gen(3)].join('-');
  }
});