define("cropster-app/components/app-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/Ghdnww",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"header-bar-inner\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header-bar__left\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"nav-bar-left\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header-bar__center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"nav-bar-center\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header-bar__right\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"nav-bar-right\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/app-header/template.hbs"
    }
  });

  _exports.default = _default;
});