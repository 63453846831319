define("cropster-app/services/ember-cordova/keyboard", ["exports", "ember-cordova-keyboard/services/ember-cordova/keyboard"], function (_exports, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _keyboard.default.extend({
    screen: Ember.inject.service(),
    platform: Ember.inject.service('device/platform'),
    adjustBodyHeight: false,
    shouldDisableScroll: false,
    keyboardIsShown: false,
    keyboard: function keyboard() {
      if (Ember.get(this, 'platform.isCordova')) {
        return this._super.apply(this, arguments);
      } // This mocks the keyboard object from ionic-plugin-keyboard


      var mockKb = {
        isVisible: false,
        _isMocked: true,
        disableScroll: function disableScroll() {// noop
        }
      };
      return Ember.RSVP.Promise.resolve(mockKb);
    },
    willDestroy: function willDestroy() {
      this.teardownKeyboardListeners();

      this._super.apply(this, arguments);
    },
    setup: function setup(keyboard) {
      this._super.apply(this, arguments); // Ensure this is not deactivated for iOS, to make e.g. select boxes work on iOS


      if (Ember.get(this, 'platform.isIos')) {
        // Both of those syntaxes are possible, depending on plugin versions
        if (keyboard && keyboard.hideKeyboardAccessoryBar) {
          keyboard.hideKeyboardAccessoryBar(false);
        }

        if (keyboard && keyboard.hideFormAccessoryBar) {
          keyboard.hideFormAccessoryBar(false);
        }
      }
    },

    /**
     * This function fixes an issue in Android:
     * When you focus an input, the keyboard will pop up and slide over the input.
     * This fix leads to auto-scrolling the input into view.
     *
     * @method _setupKeyboardListeners
     * @private
     */
    setupKeyboardListeners: function setupKeyboardListeners() {
      if (Ember.get(this, 'platform.isAndroid')) {
        this.on('keyboardDidShow', this._keyboardDidShow);
        this.on('keyboardDidHide', this._keyboardDidHide);
      }
    },
    teardownKeyboardListeners: function teardownKeyboardListeners() {
      if (Ember.get(this, 'platform.isAndroid')) {
        this.off('keyboardDidShow', this._keyboardDidShow);
        this.off('keyboardDidHide', this._keyboardDidHide);
      }
    },
    _keyboardDidShow: function _keyboardDidShow() {
      var container = Ember.get(this, 'screen.rootElement');
      var element = document.activeElement;
      var modal = document.querySelector('#modal-wormhole'); // Scroll the active element into view - only if the input is not in a modal
      // This is used to fix weird behavior on Android with the overflowing scroll container

      if (element && container && !modal.contains(element)) {
        element.scrollIntoView();
        var scrollTop = container.scrollTop - 100; // scroll down a but further, because of the header etc.

        container.scrollTop = Math.max(scrollTop, 0); // scroll to a minimum of 0
      }

      Ember.set(this, 'keyboardIsShown', true);
    },
    _keyboardDidHide: function _keyboardDidHide() {
      Ember.set(this, 'keyboardIsShown', false);
    }
  });

  _exports.default = _default;
});