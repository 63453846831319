define("cropster-app/services/secure-storage", ["exports", "cropster-app/utils/is-on-cordova"], function (_exports, _isOnCordova) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A service that wraps the SecureStorage cordova plugin
   * for securely storing credentials on iPhone and Android
   *
   * @namespace Service
   * @class Service.SecureStorage
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    log: Ember.inject.service(),

    /**
     * The secure storage container
     */
    ss: null,

    /**
     * Whether secure storage is available
     * (basically, whether we are on Cordova or not)
     */
    isValid: false,

    /**
     * Registers the SecureStorage with the underlying provider
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!(0, _isOnCordova.default)()) {
        var log = Ember.get(this, 'log');
        Ember.set(this, 'isValid', false);
        log.writeLog('services/secure-storage/onInit: not on cordova');
        return;
      }

      var ss = new window.cordova.plugins.SecureStorage( // success handler
      function () {
        _this.set('isValid', true);
      }, // error handler
      function (error) {
        _this.error('Cordova SecureStorage Failure: ', error);
      }, 'cropster-cup');
      this.set('ss', ss);
    },
    save: function save(key, value) {
      var _this2 = this;

      if (!this.get('isValid')) {
        return Ember.RSVP.reject(new Error('services/secure-storage/save: SecureStorage not available'));
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('ss').set(function () {
          resolve();
        }, function (error) {
          reject(new Error(error));
        }, key, value);
      });
    },
    fetch: function fetch(key) {
      var _this3 = this;

      if (!this.get('isValid')) {
        return Ember.RSVP.reject(new Error('services/secure-storage/fetch: SecureStorage not available'));
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.get('ss').get(function (value) {
          resolve(value);
        }, function (error) {
          reject(new Error(error));
        }, key);
      });
    }
  });

  _exports.default = _default;
});