define("cropster-app/components/app-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The header of the app.
   * The actual contents for the header are moved into this via ember-wormhole.
   *
   * There are three parts available to move into:
   *
   * #nav-bar-left
   * #nav-bar-center
   * #nav-bar-right
   *
   * @namespace Component
   * @class AppHeader
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    classNames: 'header-bar',
    tagName: 'header'
  });

  _exports.default = _default;
});