define("cropster-app/cupping-sheet/model", ["exports", "ember-data", "cropster-app/utils/range"], function (_exports, _emberData, _range) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  /**
   * A specific cupping sheet for a session / cupping.
   *
   * @namespace Model
   * @class CuppingSheet
   * @extends DS.Model
   */

  var _default = Model.extend({
    /**
     * The name of the sheet.
     *
     * @attribute name
     * @type {String}
     */
    name: attr('string'),

    /**
     * If a cupper may override the final score.
     *
     * @attribute cupperScoreAllowed
     * @type {Boolean}
     */
    cupperScoreAllowed: attr('boolean'),

    /**
     * What the maximum possible score is.
     *
     * @attribute maxPossibleScore
     * @type {Number}
     */
    maxPossibleScore: attr('number'),

    /**
     *  Score that will be added to the result.
     *
     *  @attribute deltaScore
     *  @type {Number}
     */
    deltaScore: attr('number'),

    /**
     * The product type of this sheet.
     * Can be: coffee, cocoa, olive, tea
     *
     * @attribute product
     * @type {String}
     */
    product: attr('string', {
      defaultValue: 'coffee'
    }),

    /**
     * Range of numbers to represent the roast level, from very light roast to very dark roast ,
     *
     * @attribute roastLevels
     * @type {Number[]}
     */
    roastLevels: attr('array'),

    /**
     * The sheet items of this sheet.
     *
     * @attribute sheetItems
     * @type {Model.CuppingSheetItem[]}
     */
    sheetItems: hasMany('cupping-sheet-item'),
    // CUP ONLY the group this sheet belongs to
    groupCode: attr('string'),
    // ---------------------------------------------------------------------------------------------------------
    // Properties
    isSCAA: Ember.computed.equal('id', '100'),
    isNotSCAA: Ember.computed.not('isSCAA'),
    isBranchB: Ember.computed.not('isSCAA'),
    isCoffee: Ember.computed.equal('product', 'coffee'),
    isCocoa: Ember.computed.equal('product', 'cocoa'),
    isOlive: Ember.computed.equal('product', 'oliveOil'),
    hasScore: Ember.computed('maxPossibleScore', 'deltaScore', function () {
      return !!(Ember.get(this, 'maxPossibleScore') || Ember.get(this, 'deltaScore'));
    }),

    /**
     * If this is true, a warning will be displayed if a cupping with null values is submitted.
     *
     * @property requireFilledValues
     * @type {Boolean}
     * @readOnly
     * @public
     */
    requireFilledValues: Ember.computed.alias('isOlive'),

    /**
     * Does this cupping sheet have a roast slider?
     *
     * @property hasRoastLevels
     * @type {Boolean}
     * @readOnly
     */
    hasRoastLevels: Ember.computed.notEmpty('roastLevels'),
    roastLevelsMid: Ember.computed('roastLevels.[]', function () {
      return Ember.get(this, 'hasRoastLevels') ? (0, _range.rangeMiddle)(this.get('roastLevels')) : 0;
    })
  });

  _exports.default = _default;
});