define("cropster-app/transforms/array", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isNone(serialized)) {
        return [];
      }

      return Ember.typeOf(serialized) === 'array' ? serialized : [serialized];
    },
    serialize: function serialize(deserialized) {
      var type = Ember.typeOf(deserialized);

      if (type === 'array') {
        return deserialized;
      } else if (type === 'string') {
        return deserialized.split(',').map(function (item) {
          return item.trim();
        });
      } else {
        return [];
      }
    }
  });

  _exports.default = _default;
});