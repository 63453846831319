define("cropster-app/evaluation/overview/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var session = this.modelFor('evaluation');
      var loader = Ember.get(session, 'loader');
      return loader.getAllCuppingResultsTask.perform();
    }
  });

  _exports.default = _default;
});