define("cropster-app/components/evaluation-form/sample-pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The sample pagination for this evaluation.
   *
   * @namespace Component.EvaluationForm
   * @class SamplePagination
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    /**
     * The currently visible page.
     * This is 1-based.
     *
     * @attribute currentPage
     * @type {Number}
     * @public
     */
    currentPage: 1,

    /**
     * If the pagination should be compact or not.
     *
     * @attribute isCompact
     * @type {Boolean}
     * @default true
     * @public
     */
    isCompact: true,

    /**
     * The paginated items.
     * This is an array of arrays, where each item contains all the analysis results of a given page.
     *
     * E.g.: [ [1,2,3], [4,5,6], [7] ]
     *
     * @attribute paginatedItems
     * @type {Array[]}
     * @public
     */
    paginatedItems: null,

    /**
     * The actual pages to display.
     *
     * @property pages
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    pages: Ember.computed('paginatedItems.[]', function () {
      var grouped = Ember.get(this, 'paginatedItems') || [];
      return grouped.map(function (paginatedItems, i) {
        var labels = paginatedItems.mapBy('label');
        return {
          labels: labels,
          paginatedItems: paginatedItems,
          pageNumber: i + 1
        };
      });
    })
  });

  _exports.default = _default;
});