define("cropster-app/utils/input-types-to-components-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.inputTypeToComponentMap = void 0;
  var inputTypeToComponentMap = {
    SLIDER: 'evaluation-form/inputs/slider',
    TEXT: 'evaluation-form/inputs/text',
    DROPDOWN: 'evaluation-form/inputs/dropdown',
    SLIDER_BLIND: 'evaluation-form/inputs/slider-blind',
    POPUP_SLIDER: 'evaluation-form/inputs/popup-slider',
    POPUP_SLIDER_SMALL: 'evaluation-form/inputs/popup-slider-small',
    BUTTONS: 'evaluation-form/inputs/buttons',
    TOGGLE_BUTTONS: 'evaluation-form/inputs/buttons-toggle'
  };
  _exports.inputTypeToComponentMap = inputTypeToComponentMap;
  var _default = inputTypeToComponentMap;
  _exports.default = _default;
});