define("cropster-app/session-sync-details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').find('cupping-session', params.id);
    },
    afterModel: function afterModel(session) {
      var loader = session.loader;
      return loader.setupTask.perform(session).then(function () {
        return loader.getAllCuppingResultsTask.perform();
      });
    }
  });

  _exports.default = _default;
});