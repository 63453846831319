define("cropster-app/components/evaluation-form/inputs/special/descriptor-select-compact/buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Select from a list of given sensorial descriptor names, as buttons.
   *
   * @namespace Component.EvaluationForm.Inputs.Special.DescriptorSelectCompact
   * @class Buttons
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    /**
     * A list of descriptor names to select from.
     *
     * @attribute sensorialDescriptorNames
     * @type {String[]}
     * @public
     */
    sensorialDescriptorNames: null,

    /**
     * The currently assigned sensorial descriptors.
     * These will not be in the selectable list.
     *
     * @attribute assignedSensorialDescriptors
     * @type {Model.AssignedSensorialDescriptor[]}
     * @public
     */
    assignedSensorialDescriptors: null,

    /**
     * The action to call to add or create a sensorial descriptor, by name.
     *
     * @event addOrCreateSensorialDescriptor
     * @param {String} sensorialDescriptorName
     * @public
     */
    addOrCreateSensorialDescriptor: null,

    /**
     * The actual options to select from.
     *
     * @property options
     * @type {String[]}
     * @readOnly
     * @protected
     */
    options: Ember.computed('assignedSensorialDescriptors.[]', 'sensorialDescriptorNames.[]', function () {
      var sensorialDescriptorNames = Ember.get(this, 'sensorialDescriptorNames') || [];
      var assignedSensorialDescriptors = Ember.get(this, 'assignedSensorialDescriptors') || [];
      var assignedSensorialDescriptorNames = assignedSensorialDescriptors.mapBy('sensorialDescriptor.name');
      return sensorialDescriptorNames.sort().filter(function (name) {
        return !assignedSensorialDescriptorNames.includes(name);
      });
    })
  });

  _exports.default = _default;
});