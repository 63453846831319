define("cropster-app/system-notification/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  /**
   * A system notification.
   * These are fetched by product and version.
   *
   * @namespace Model
   * @class SystemNotification
   * @extends DS.Model
   * @public
   */

  var _default = Model.extend({
    /**
     * The title of the system message.
     *
     * @attribute title
     * @type {String}
     * @public
     */
    title: attr('string'),

    /**
     * The actual message. Can contain some HTML.
     *
     * @attribute message
     * @type {String}
     * @public
     */
    message: attr('string'),

    /**
     * The product that this message is for.
     * One of: CROPSTER_CUP, CROPSTER_CORE
     *
     * @attribute affectedProduct
     * @type {String}
     * @public
     */
    affectedProduct: attr('string'),

    /**
     * The min. affected version.
     * Only versions after this one will get the message.
     * This should be a semver string like 16.09.1
     *
     * @attribute affectedVersionFrom
     * @type {String}
     * @public
     */
    affectedVersionFrom: attr('string'),

    /**
     * The max. affected version.
     * Only versions before this one will get the message.
     * This should be a semver string like 16.09.1
     *
     * @attribute affectedVersionTo
     * @type {String}
     * @public
     */
    affectedVersionTo: attr('string'),

    /**
     * The type of the message.
     * One of: URGENT, WARNING, INFO
     *
     * @attribute type
     * @type {String}
     * @public
     */
    type: attr('string'),

    /**
     * If this is set, the app should be locked until it has been updated.
     *
     * @attribute isUpdateRequired
     * @type {Boolean}
     * @public
     */
    isUpdateRequired: attr('boolean', {
      defaultValue: false
    }),

    /**
     * If the type is urgent.
     *
     * @property isTypeUrgent
     * @type {Boolean}
     * @readOnly
     */
    isTypeUrgent: Ember.computed.equal('type', 'URGENT'),

    /**
     * If the type is warning.
     *
     * @property isTypeWarning
     * @type {Boolean}
     * @readOnly
     */
    isTypeWarning: Ember.computed.equal('type', 'WARNING'),

    /**
     * If the type is info.
     *
     * @property isTypeInfo
     * @type {Boolean}
     * @readOnly
     */
    isTypeInfo: Ember.computed.equal('type', 'INFO')
  });

  _exports.default = _default;
});